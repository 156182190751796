import React from 'react'
import { Helmet } from 'react-helmet';

export default function News() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="School News - CapoBrain" />
        <meta property="og:description" content="The News submodule allows you to add and manage school news for everyone in the community." />
        <meta property="og:image" content="https://capobrain.com/img/Add-News.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/news" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="School News - CapoBrain" />
        <meta name="twitter:description" content="The News submodule allows you to add and manage school news for everyone in the community." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-News.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/news",
            "name": "CapoBrain",
            "description": "The News submodule allows you to add and manage school news for everyone in the community.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/news" />
        <meta name="description" content="The News submodule allows you to add and manage school news for everyone in the community." />
      </Helmet>

      <h2 className='manual-h1'>School News</h2>
      <p>The News submodule is part of the announcement module, allowing you to share important updates and information from the school with everyone in the community. You can easily add news items by entering the title, link, date, added by, and description. All news items will be displayed on user portals, and you have the flexibility to view, edit, or remove any news at any time.</p>
      <img src="/img/Add-News.avif" className='img-fluid' alt="Add News" />
    </div>
  )
}

