import React from 'react'
import { Helmet } from 'react-helmet';

export default function Classtimetable() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Class Time Table Management - CapoBrain" />
        <meta property="og:description" content="Effortlessly manage your school's class schedules with this module. It allows you to assign instructors to classes and view detailed timetables." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Class-Time.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/class-time-table" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Class Time Table Management - CapoBrain" />
        <meta name="twitter:description" content="Manage your school's class schedules seamlessly. Assign instructors to classes and check detailed timetables for effective planning." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Class-Time.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/class-time-table",
            "name": "CapoBrain",
            "description": "Manage your school's class schedules with ease. Assign instructors to each class and view the timetable to optimize learning outcomes.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/class-time-table" />
        <meta name="description" content="Manage your school's class schedules effectively by assigning instructors and viewing detailed timetables in this module." />
      </Helmet>

      <h2 className='manual-h1'>Class Time Table</h2>
      <p>This module allows you to create and manage class schedules effortlessly. Assign instructors to classes, select subjects, and specify timings. This way, teachers can easily determine their lecture schedules for each class, ensuring smooth educational operations.</p>
      <img src="/img/Add-Class-Time.avif" className='img-fluid' alt="Class Time Table Management" />
    </div>
  )
}

