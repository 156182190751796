import React from 'react'
import { Helmet } from 'react-helmet';

export default function Purchaseinv() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Purchase Inventory - CapoBrain" />
        <meta property="og:description" content="Easily manage your inventory purchases from suppliers. This module allows you to add suppliers, select inventory, and automatically load relevant details such as quantity, cost price, and sale price." />
        <meta property="og:image" content="https://capobrain.com/img/Purchase-Inventory.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/purchase-inventory" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Purchase Inventory - CapoBrain" />
        <meta name="twitter:description" content="Manage your inventory purchases seamlessly by adding suppliers and automatically loading inventory details. Checkout to view the purchase invoice." />
        <meta name="twitter:image" content="https://capobrain.com/img/Purchase-Inventory.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/purchase-inventory",
            "name": "CapoBrain",
            "description": "This module allows you to purchase inventory from suppliers, add supplier details, and view loaded inventory quantities, cost prices, and sale prices.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/purchase-inventory" />
        <meta name="description" content="Easily purchase inventory from suppliers, manage supplier details, and view relevant inventory information in this comprehensive module." />
      </Helmet>

      <h2 className='manual-h1'>Purchase Inventory</h2>
      <p>This module allows you to manage your inventory purchases from suppliers. Start by adding suppliers from the supplier page. Upon selecting the inventory, the system will automatically load essential details such as quantity, cost price, and sale price. Once you click the submit button, the selected product will be added to the grid. Finally, clicking the checkout button will generate the purchase invoice and display all purchase amount data in the supplier account.</p>
      <img src="/img/Purchase-Inventory.avif" className='img-fluid mt-5' alt="Purchase Inventory - CapoBrain" />
    </div>
  )
}

