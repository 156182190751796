import React from 'react'
import { Helmet } from 'react-helmet';

export default function Phonecall() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="School Call Log Management" />
        <meta property="og:description" content="Manage incoming and outgoing school calls efficiently. Enter details such as the caller's name, phone number, call duration, and status for comprehensive records." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Postal-Dispatch.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/phone-call-log" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="School Call Log Management" />
        <meta name="twitter:description" content="Efficiently log all incoming and outgoing calls at your school. Input essential details like name, phone number, duration, and call status." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Postal-Dispatch.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/phone-call-log",
            "name": "Capobrain",
            "description": "Manage incoming and outgoing school calls with the call log feature. Enter caller details including name, phone number, duration, and call status for effective record-keeping.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/phone-call-log" />
        <meta name="description" content="Efficiently manage incoming and outgoing school calls by logging essential details such as the caller's name, phone number, duration, and status." />
      </Helmet>

      <h2 className='manual-h1'>Phone Call Log</h2>
      <p>This module allows you to log all incoming and outgoing calls related to the school. You must enter the caller's name, phone number, call duration, and status to complete the call details. All fields are mandatory, and you can view a comprehensive list of all call logs.</p>
      <img src="/img/Call-log.avif" className='img-fluid' alt="Call Log" />
    </div>
  )
}

