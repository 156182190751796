import React from "react";
import { Helmet } from 'react-helmet';
export default function Admilist() {
  return (
    <div className="container">
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Comprehensive Student List - School Management System" />
        <meta property="og:description" content="View, manage, and edit student details in the comprehensive student list. Access documents, login information, and modify or delete entries easily." />
        <meta property="og:image" content="https://capobrain.com/img/Student-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/students-list" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Comprehensive Student List" />
        <meta name="twitter:description" content="Manage student information effectively with access to documents, login details, and the ability to modify or delete records." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/students-list",
            "name": "Comprehensive Student List",
            "description": "View and manage all student details, access documents, modify information, or delete records in the student list.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/students-list" />
        <meta name="description" content="Manage student information effectively with access to documents, login details, and the ability to modify or delete records." />
      </Helmet>

      <h2 className='manual-h1'>Comprehensive Student List</h2>
      <p>
        The student list provides a comprehensive view of all students, allowing you to access documents, view login details, and modify or delete any entries as needed.
      </p>
      <ul>
        <li>Image Format: Must be JPG, JPEG, or PNG.</li>
        <li>Image size should be less than or equal to 100 KB.</li>
        <li>Document Format: Must be JPG, JPEG, PDF, or PNG.</li>
        <li>The file size required in the instructor document area is less than or equal to 300 KB.</li>
      </ul>
      <img src="/img/Student-List.avif" className="img-fluid" alt="Student-List" />
    </div>
  );
}

