import React from 'react'
import { Helmet } from 'react-helmet';

export default function Expencerep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Expense Report" />
        <meta property="og:description" content="An expense report summarizes incurred expenses over a specified period, detailing categories like travel, meals, and inventory supplies." />
        <meta property="og:image" content="https://capobrain.com/img/Expence-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/expense-report" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Expense Report" />
        <meta name="twitter:description" content="An expense report summarizes incurred expenses over a specified period, detailing categories like travel, meals, and inventory supplies." />
        <meta name="twitter:image" content="https://capobrain.com/img/Expence-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/expense-report",
            "name": "Capobrain",
            "description": "An expense report summarizes incurred expenses over a specified period, detailing categories like travel, meals, and inventory supplies.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/expense-report" />
        <meta name="description" content="An expense report summarizes incurred expenses over a specified period, detailing categories like travel, meals, and inventory supplies." />
      </Helmet>

      <h2 className='manual-h1'>Expense Report</h2>
      <p>
        An expense report provides a comprehensive summary of incurred expenses within a specified period. It typically includes details such as:
      </p>
      <ul>
        <li>Expense category (e.g., travel, meals, inventory supplies)</li>
        <li>Name or description of the expense</li>
        <li>Amount spent</li>
        <li>Date of the expense</li>
      </ul>
      <p>
        These reports are crucial for tracking and managing expenditures, budgeting, and ensuring financial transparency in both personal and organizational finances. They enable individuals or organizations to review and analyze spending patterns, make informed financial decisions, and maintain accurate records for accounting purposes.
      </p>
      <img src="/img/Expence-Report.avif" className='img-fluid' alt="Expense Report" />
    </div>
  );
}

