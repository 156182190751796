import React from 'react'
import { Helmet } from 'react-helmet';

export default function Familywise() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Family Wise Account - CapoBrain" />
        <meta property="og:description" content="Every student provides family details in their admission form. Once accepted, a family wise account is created to display children's account details like ID, name, class, section, and overall balance." />
        <meta property="og:image" content="https://capobrain.com/img/Family-Account-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/family-wise-account" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Family Wise Account - CapoBrain" />
        <meta name="twitter:description" content="A family wise account reveals children's account details such as ID, name, class, section, and balance, accessible to parents through their portal." />
        <meta name="twitter:image" content="https://capobrain.com/img/Family-Account-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/family-wise-account",
            "name": "CapoBrain",
            "description": "Every student provides family details in their admission form. Once accepted, a family wise account is created to display children's account details like ID, name, class, section, and overall balance.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/family-wise-account" />
        <meta name="description" content="Every student provides family details in their admission form. Once accepted, a family wise account is created to display children's account details like ID, name, class, section, and overall balance." />
      </Helmet>

      <h2 className='manual-h1'>Family Wise Account</h2>
      <p>Every student adds family details in their admission form, leading to the creation of a family wise account once the student is accepted. This account reveals details such as the child's ID, name, class, section, and overall balance. Parents can access this information through their portal, ensuring they stay informed about their child's educational journey.</p>
      <img src="/img/Family-Account-Detail.avif" className='img-fluid' alt="Family Account Detail" />
    </div>
  )
}

