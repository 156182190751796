import React from 'react'
import { Helmet } from 'react-helmet';

export default function Viewsite() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Visit Website" />
        <meta property="og:description" content="Select this option to view the front page of your website, created using the information provided in your account. You can explore details about the school, file complaints, apply for admission, and access news, events, and course information." />
        <meta property="og:image" content="https://capobrain.com/img/Visit-Website.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/visit-website" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Visit Website" />
        <meta name="twitter:description" content="Select this option to view the front page of your website, created using the information provided in your account. You can explore details about the school, file complaints, apply for admission, and access news, events, and course information." />
        <meta name="twitter:image" content="https://capobrain.com/img/Visit-Website.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/visit-website",
            "name": "Capobrain",
            "description": "Select this option to view the front page of your website, created using the information provided in your account. You can explore details about the school, file complaints, apply for admission, and access news, events, and course information.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/visit-website" />
        <meta name="description" content="Select this option to view the front page of your website, created using the information provided in your account. You can explore details about the school, file complaints, apply for admission, and access news, events, and course information." />
      </Helmet>

      <h2 className='manual-h1'>Visit Website</h2>
      <p>Select this option to view the front page of your website, created using the information provided in your account. You can explore details about the school, file complaints, apply for admission online, and access news, a photo gallery, upcoming events, and course information. Any changes you make in the backend will automatically update the front end.</p>
      <img src="/img/Visit-Website.avif" className='img-fluid' alt="Visit Website" />
    </div>
  );
}

