import React from 'react'
import { Helmet } from 'react-helmet';
export default function Courses() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Courses - E-Learning Management" />
        <meta property="og:description" content="To add a course in our e-learning platform, you must first create a course category. Enter the title, outcomes, and description of the course for effective management." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Course.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/courses" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Courses - Best E-Learning Management Software" />
        <meta name="twitter:description" content="Add and manage courses in our e-learning platform. Start by creating a course category and enter the course details, including title, outcomes, and description." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Course.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/courses",
            "name": "Capobrain",
            "description": "In our e-learning platform, create courses after adding a course category. Enter the title, outcomes, and description to manage your courses effectively." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/courses" />
        <meta name="description" content="Create and manage courses in our e-learning management system. Start by adding a course category, then enter course details like title, outcomes, and description." />
      </Helmet>

      <h2 className='manual-h1'>Courses</h2>
      <p>To add a course in our e-learning platform, you must first create a course category. After entering the title, outcomes, and description of the course, you will select the branch, class, and teacher responsible for teaching the course, along with specifying if the course will be paid or free. Once saved, a list of all courses will be displayed for you to view, edit, and delete.</p>
      <img src="/img/Add-Course.avif" className='img-fluid' alt="Add Course" />
    </div>
  )
}
