import React from 'react'
import { Helmet } from 'react-helmet';

export default function Purchase() {
  return (
    <div className='container'>
<Helmet>
        {/* open grapgh tag */}
        <meta property="og:title" content="Purchase List - CapoBrain" />
        <meta property="og:description" content="View your purchase history with ease. The Purchase List displays all purchases, along with an action column for quick access to invoices related to each transaction." />
        <meta property="og:image" content="https://capobrain.com/img/Purchase-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/purchase-list" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Purchase List - CapoBrain" />
        <meta name="twitter:description" content="Access a comprehensive list of all purchases made. Click the invoice icon to view detailed information about each transaction." />
        <meta name="twitter:image" content="https://capobrain.com/img/Purchase-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/purchase-list",
            "name": "Capobrain",
            "description": "Access and manage your purchase history with ease. The Purchase List displays a comprehensive list of all purchases, along with quick access to invoices." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/purchase-list" />
        <meta name="description" content="IThe Purchase List displays all purchases made, providing easy access to detailed invoices for each transaction." />
      </Helmet>

      <h2 className='manual-h1'>Purchase List</h2>
      <p>The Purchase List provides a comprehensive view of all purchases made within the system. Each entry includes an action column with an invoice icon, allowing you to quickly access the corresponding invoice details for each transaction. This feature streamlines your purchase management process and ensures you have all the necessary information at your fingertips.</p>
      <img src="/img/Purchase-List.avif" className='img-fluid mt-5' alt="Purchase-List" />
    </div>
  )
}
