import React from 'react'
import { Helmet } from 'react-helmet';
export default function Studentcerti() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Student Certificate Management in CapoBrain" />
        <meta property="og:description" content="The Student Certificate module provides a comprehensive list of all students, showcasing essential information including Branch, Student ID, Name, Gender, Class, Section, Father's Name, and B-form number." />
        <meta property="og:image" content="https://capobrain.com/img/Student-Certificate.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/student-certificate" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Certificate Management in CapoBrain" />
        <meta name="twitter:description" content="The Student Certificate module provides a comprehensive list of all students, showcasing essential information including Branch, Student ID, Name, Gender, Class, Section, Father's Name, and B-form number." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Certificate.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/student-certificate",
            "name": "CapoBrain",
            "description": "The Student Certificate module provides a comprehensive list of all students, showcasing essential information including Branch, Student ID, Name, Gender, Class, Section, Father's Name, and B-form number.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/student-certificate" />
        <meta name="description" content="The Student Certificate module provides a comprehensive list of all students, showcasing essential information including Branch, Student ID, Name, Gender, Class, Section, Father's Name, and B-form number." />
      </Helmet>

      <h2 className='manual-h1'>Student Certificate</h2>
      <p>The Student Certificate module displays a comprehensive list of all students, featuring key information such as Branch, Student ID, Name, Gender, Class, Section, Father's Name, and B-form number. Please note that the action field indicates a status of “Not Available” until the certificate is configured properly.</p>
      <img src="/img/Student-Certificate.avif" className='img-fluid' alt="Student Certificate" />
    </div>
  )
}

