import React from 'react'
import { Helmet } from 'react-helmet';

export default function Assetstype() { 
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Asset Types Management in CapoBrain" />
        <meta property="og:description" content="Discover various asset types in CapoBrain, essential for managing business assets. These can include physical items like vehicles and computers, as well as intangible assets such as intellectual property." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Asset-Type.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/asset-type" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Asset Types Management in CapoBrain" />
        <meta name="twitter:description" content="Discover various asset types in CapoBrain, essential for managing business assets. These can include physical items like vehicles and computers, as well as intangible assets such as intellectual property." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Asset-Type.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/asset-type",
            "name": "CapoBrain Asset Types",
            "description": "Discover various asset types in CapoBrain, essential for managing business assets. These can include physical items like vehicles and computers, as well as intangible assets such as intellectual property.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/asset-type" />
        <meta name="description" content="Discover various asset types in CapoBrain, essential for managing business assets. These can include physical items like vehicles and computers, as well as intangible assets such as intellectual property." />
      </Helmet>

      <h2 className='manual-h1'>Asset Types Management</h2>
      <p>In CapoBrain, a business asset refers to an item of value owned by a company. Assets can span many categories, including physical, tangible goods such as vehicles, real estate, computers, office furniture, and fixtures, or intangible items like intellectual property. This module allows you to select the branch name and asset type. You can also view and manage a comprehensive list of all asset types, which you may browse, alter, and delete as needed.</p>
      <img src="/img/Add-Asset-Type.avif" className='img-fluid' alt="Add-Asset-Type" />
    </div>
  )
}

