import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffreceive() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Staff Receivables - CapoBrain" />
        <meta property="og:description" content="The Staff Accounts Receivable department in our School Management System (SMS) plays a pivotal role in managing salary and expenses, assessing and collecting payments accurately." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Recieveable.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-recieveables" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Receivables - CapoBrain" />
        <meta name="twitter:description" content="The Staff Accounts Receivable department in our School Management System (SMS) plays a pivotal role in managing salary and expenses, assessing and collecting payments accurately." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Recieveable.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-recieveables",
            "name": "CapoBrain",
            "description": "The Staff Accounts Receivable department in our School Management System (SMS) plays a pivotal role in managing salary and expenses, assessing and collecting payments accurately.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-recieveables" />
        <meta name="description" content="The Staff Accounts Receivable department in our School Management System (SMS) plays a pivotal role in managing salary and expenses, assessing and collecting payments accurately." />
      </Helmet>

      <h2 className='manual-h1'>Staff Receivables</h2>
      <p>The <strong>Staff Accounts Receivable</strong> department within our <strong>School Management System (SMS)</strong> plays a crucial role in managing salaries and expenses. Their responsibilities encompass assessing and collecting payments accurately. This report provides a comprehensive overview of staff accounts, detailing essential information such as staff names, Staff ID, contact details, CNIC, email addresses, and outstanding balances. This information streamlines financial tracking and aids in maintaining accurate and efficient financial records for the institution.</p>
      <img src="/img/Staff-Recieveable.avif" className='img-fluid' alt="Staff Receivables" />
    </div>
  );
}

