import React from "react";
import { Helmet } from 'react-helmet';

export default function Complains() {
  return (
    <div className="container">
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Online Complaints Management - E-Learning Platform" />
        <meta property="og:description" content="The online complaints management submodule receives all complaints submitted via the website. Each school can develop its own site with a dedicated complaints section." />
        <meta property="og:image" content="https://capobrain.com/img/Complains-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/online-complains-list" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Online Complaints Management - Best E-Learning Solution" />
        <meta name="twitter:description" content="Receive and manage all online complaints efficiently. Each school can create a dedicated website section for complaints." />
        <meta name="twitter:image" content="https://capobrain.com/img/Complains-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/online-complains-list",
            "name": "Capobrain",
            "description": "The online complaints management submodule collects all complaints submitted via the website, allowing schools to manage them efficiently." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/online-complains-list" />
        <meta name="description" content="Manage all complaints received via the website in a dedicated section. Each school can build its site with options for complaints and other content." />
      </Helmet>

      <h2 className='manual-h1'>Online Complaints Management</h2>
      <p>The online complaints management submodule allows schools to receive and manage all complaints submitted through their websites. Each school can create a dedicated section for complaints, enabling users to submit their issues easily. You can view the name, email, contact information, and description of each complaint in the online complaints list.</p>
      <img src="/img/Complains-Detail.avif" className='img-fluid' alt="Complaints Management Detail" />
    </div>
  );
}

