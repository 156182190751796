import React from 'react'
import { Helmet } from 'react-helmet';

export default function Refundpay() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Refund Payment" />
        <meta property="og:description" content="To refund a payment to a student, search for the student by ID, and their data will load automatically, showing the payable amount and remaining balance." />
        <meta property="og:image" content="https://capobrain.com/img/Refund-Payment.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/refund-payment" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Refund Payment" />
        <meta name="twitter:description" content="To refund a payment to a student, search for the student by ID, and their data will load automatically, showing the payable amount and remaining balance." />
        <meta name="twitter:image" content="https://capobrain.com/img/Refund-Payment.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/refund-payment",
            "name": "Capobrain",
            "description": "To refund a payment to a student, search for the student by ID, and their data will load automatically, showing the payable amount and remaining balance.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/refund-payment" />
        <meta name="description" content="To refund a payment to a student, search for the student by ID, and their data will load automatically, showing the payable amount and remaining balance." />
      </Helmet>

      <h2 className='manual-h1'>Refund Payment</h2>
      <p>To <strong>refund a payment</strong> to a student, start by searching for the student using their ID. The system will automatically load the student's data, including their <strong>payable amount</strong> and <strong>remaining balance</strong>. After reviewing this information, select the payment type and proceed to process the refund. The transaction will be recorded in the student's account for future reference.</p>
      <img src="/img/Refund-Payment.avif" className='img-fluid' alt="Refund Payment" />
    </div>
  )
}

