import React from 'react'
import { Helmet } from 'react-helmet';
export default function Assignveh() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Assign Vehicles" />
        <meta property="og:description" content="In the School Management System (SMS), this module determines which vehicle is assigned to each student and the routes taken. First, search for the student by ID to load their details." />
        <meta property="og:image" content="https://capobrain.com/img/Assign-Vehicle.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/assign-vehicles" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Assign Vehicles" />
        <meta name="twitter:description" content="In the School Management System (SMS), determine vehicle assignments and routes for students by searching their IDs." />
        <meta name="twitter:image" content="https://capobrain.com/img/Assign-Vehicle.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/assign-vehicles",
            "name": "Capobrain",
            "description": "This module allows schools to assign vehicles to students and manage transportation routes." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/assign-vehicles" />
        <meta name="description" content="This module allows schools to assign vehicles to students and manage their transportation routes." />
      </Helmet>

      <h2 className='manual-h1'>Assign Vehicle</h2>
      <p>This module allows schools to efficiently assign vehicles to students and determine their transportation routes. First, search for the student by ID to load their details. Then, select the vehicle to assign, choose the route, and importantly, set the pickup and drop-off times. Lastly, input the transport charges for that student with an effective date; charges will be applied according to this date.</p>
      <img src="/img/Assign-Vehicle.avif" className='img-fluid' alt="Assign Vehicle" />
    </div>
  );
}

