import React from "react";
import { Helmet } from 'react-helmet';

export default function Teacherfeedback() {
  return (
    <div className="container">
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Teacher Feedback - Best School Management System (SMS)" />
        <meta property="og:description" content="Teacher Feedback is an essential tool in CapoBrain's School Management System (SMS) that helps assess staff performance, job satisfaction, and workplace environment." />
        <meta property="og:image" content="https://capobrain.com/img/Teacher-Feedback.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/teacher-feedback" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Teacher Feedback - School ERP Solutions" />
        <meta name="twitter:description" content="Use CapoBrain's School ERP to collect valuable staff feedback and improve educational outcomes. Teacher Feedback is an integral part of our School Management Software (SMS)." />
        <meta name="twitter:image" content="https://capobrain.com/img/Teacher-Feedback.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/teacher-feedback",
            "name": "CapoBrain",
            "description": "Teacher Feedback in CapoBrain's School Management System helps educational institutions assess staff performance and overall workplace satisfaction. Part of the Best School Management Software in Pakistan 2024." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/teacher-feedback" />
        <meta name="description" content="Teacher Feedback in CapoBrain's School ERP Software Solutions helps schools manage staff feedback, ensuring a quality educational experience." />
      </Helmet>

      <h2 className='manual-h1'>Teacher Feedback - School Management Software (SMS)</h2>
      <p>
        The <strong>Teacher Feedback</strong> module in CapoBrain’s <strong>School Management System (SMS)</strong> allows schools to gather insights from staff members about their workplace environment, job roles, and satisfaction. This feature is crucial for improving educational outcomes and fostering a supportive work culture.
      </p>
      <p>
        Feedback can be filtered by branch, class, section, and teacher's name, making it easier for management to track staff performance. Additionally, the system offers a <strong>rating system</strong>, enabling the institution to evaluate staff efficiency and provide actionable insights.
      </p>
      <ul>
        <li>Part of CapoBrain’s <strong>School ERP Software Solutions</strong></li>
        <li>Supports integration with <strong>Parent-Teacher Communication Software</strong></li>
        <li>Helps enhance overall <strong>School Management Software</strong> efficiency</li>
        <li>Used for <strong>School Data Analytics Software</strong> in staff evaluation</li>
      </ul>
      <img src="/img/Teacher-Feedback.avif" className="img-fluid" alt="Teacher Feedback - CapoBrain School Software" />
    </div>
  );
}

