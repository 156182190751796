import React from 'react'
import { Helmet } from 'react-helmet';

export default function Salarydeduct() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Salary Deduction - CapoBrain" />
        <meta property="og:description" content="This module allows administrators to deduct staff salaries for various reasons. Search for staff by ID, input the deduction amount, and provide a reason for the deduction." />
        <meta property="og:image" content="https://capobrain.com/img/Deduct-Salary.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/salary-deduction" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Salary Deduction - CapoBrain" />
        <meta name="twitter:description" content="Administrators can deduct staff salaries for various reasons. Search by staff ID, enter the deduction amount, and provide a reason." />
        <meta name="twitter:image" content="https://capobrain.com/img/Deduct-Salary.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/salary-deduction",
            "name": "CapoBrain",
            "description": "This module allows administrators to deduct staff salaries for various reasons. Search for staff by ID, input the deduction amount, and provide a reason for the deduction.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/salary-deduction" />
        <meta name="description" content="This module allows administrators to deduct staff salaries for various reasons. Search for staff by ID, input the deduction amount, and provide a reason for the deduction." />
      </Helmet>

      <h2 className='manual-h1'>Salary Deduction</h2>
      <p>This module enables administrators to manage salary deductions for staff members. Simply search for the staff member by their ID, enter the amount to be deducted, and specify the reason for the deduction. This ensures transparency and proper record-keeping for salary adjustments.</p>
      <img src="/img/Deduct-Salary.avif" className='img-fluid' alt="Deduct Salary" />
    </div>
  )
}
