import React from 'react'
import { Helmet } from 'react-helmet';

export default function Vehicle() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Manage School Vehicles" />
        <meta property="og:description" content="This module allows you to manage information about school vehicles, including car number, model, year of manufacture, and registration details." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Vehicles.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/vehicles" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Manage School Vehicles" />
        <meta name="twitter:description" content="Manage information about school vehicles, including car number, model, year of manufacture, and registration details." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Vehicles.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/vehicles",
            "name": "Capobrain",
            "description": "This module allows you to manage school vehicle information, including car number, model, year of manufacture, and registration details." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/vehicles" />
        <meta name="description" content="This module allows you to manage information about school vehicles, including car number, model, year of manufacture, and registration details." />
      </Helmet>

      <h2 className='manual-h1'>Manage School Vehicles</h2>
      <p>This module allows you to input and manage information about all vehicles owned by the school. You can enter the car number, model, year of manufacture, registration number, maximum seating capacity, driver's name, license details, contact information, image, and description. The system makes it easy to view, edit, or delete vehicle information as needed.</p>
      <img src="/img/Add-Vehicles.avif" className='img-fluid' alt="Manage Vehicles" />
    </div>
  );
}

