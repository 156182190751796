import React from 'react'
import { Helmet } from 'react-helmet';

export default function GenerateVoucher() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Generate Voucher - CapoBrain" />
        <meta property="og:description" content="Easily generate vouchers by selecting the month and various charges like monthly fees, transportation, events, and inventory. Customize the options to fit your needs!" />
        <meta property="og:image" content="https://capobrain.com/img/Generate-Voucher.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/generate-vouchers" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Generate Voucher - CapoBrain" />
        <meta name="twitter:description" content="Easily generate vouchers by selecting the month and various charges like monthly fees, transportation, events, and inventory. Customize the options to fit your needs!" />
        <meta name="twitter:image" content="https://capobrain.com/img/Generate-Voucher.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/generate-vouchers",
            "name": "CapoBrain",
            "description": "Easily generate vouchers by selecting the month and various charges like monthly fees, transportation, events, and inventory. Customize the options to fit your needs!",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/generate-vouchers" />
        <meta name="description" content="Easily generate vouchers by selecting the month and various charges like monthly fees, transportation, events, and inventory. Customize the options to fit your needs!" />
      </Helmet>

      <h2 className='manual-h1'>Generate Voucher</h2>
      <p>To generate a voucher, first select the month you need. After that, you can choose from various options like monthly fees, transportation charges, event charges, and inventory costs. You have complete control over the options that appear on the vouchers. Once you make your selections and click on "Generate Vouchers," all chosen details will be displayed, allowing you to print the vouchers whenever necessary.</p>
      <img src="/img/Generate-Voucher.avif" className='img-fluid' alt="Generate Voucher" />
    </div>
  )
}

