import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffcerti() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Staff Certificate Management in CapoBrain" />
        <meta property="og:description" content="View and manage the list of all staff in CapoBrain with detailed information including Branch, Teacher ID, Name, Gender, Class, Section, and Father Name. Certificates are available once configured." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Certificate.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-certificate" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Certificate Management in CapoBrain" />
        <meta name="twitter:description" content="View and manage the list of all staff in CapoBrain with detailed information including Branch, Teacher ID, Name, Gender, Class, Section, and Father Name. Certificates are available once configured." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Certificate.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-certificate",
            "name": "CapoBrain",
            "description": "View and manage the list of all staff in CapoBrain with detailed information including Branch, Teacher ID, Name, Gender, Class, Section, and Father Name. Certificates are available once configured.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-certificate" />
        <meta name="description" content="View and manage the list of all staff in CapoBrain with detailed information including Branch, Teacher ID, Name, Gender, Class, Section, and Father Name. Certificates are available once configured." />
      </Helmet>

      <h2 className='manual-h1'>Staff Certificate</h2>
      <p>The Staff Certificate module in CapoBrain provides a comprehensive list of all staff members, displaying essential information such as Branch, Teacher ID, Name, Gender, Class, Section, and Father Name. Please note that the action field will show “Not Available” for certificates until they are configured in the system.</p>
      <img src="/img/Staff-Certificate.avif" className='img-fluid' alt="Staff Certificate" />
    </div>
  )
}

