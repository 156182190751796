import React from 'react'
import { Helmet } from 'react-helmet';

export default function Supplier() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Supplier Management - CapoBrain" />
        <meta property="og:description" content="Easily manage your school suppliers in this module. Add suppliers from whom you purchase inventory by entering their name, cell number, CNIC, and address." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Supplier.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/supplier" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Supplier Management - CapoBrain" />
        <meta name="twitter:description" content="Manage school suppliers effortlessly. Add and update supplier information, including name, cell number, CNIC, and address." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Supplier.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/supplier",
            "name": "CapoBrain",
            "description": "Manage your school suppliers effectively by adding their details including name, cell number, CNIC, and address in this comprehensive module.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/supplier" />
        <meta name="description" content="Effortlessly manage school suppliers by adding their details and updating information as needed in this module." />
      </Helmet>

      <h2 className='manual-h1'>Supplier Management</h2>
      <p>This module allows you to manage all your school suppliers from whom inventory is purchased. Use the form to enter supplier details, including name, cell number, CNIC, and address. A comprehensive list of suppliers will be displayed, allowing you to view and update their information as needed using the update button.</p>
      <img src="/img/Add-Supplier.avif" className='img-fluid mt-5' alt="Add Supplier - CapoBrain" />
    </div>
  )
}

