import React from 'react'
import { Helmet } from 'react-helmet';

export default function Homework() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Homework Assignment Management - CapoBrain" />
        <meta property="og:description" content="Easily assign homework for any subject to your class. Select the branch, class, section, subject, and due date, and upload the relevant homework details." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Home-Work.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/home-work" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Homework Assignment Management - CapoBrain" />
        <meta name="twitter:description" content="Assign homework effortlessly across various subjects. Specify branch, class, section, subject, and due date, and manage homework details efficiently." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Home-Work.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/home-work",
            "name": "CapoBrain",
            "description": "Manage homework assignments for your school effortlessly. Assign tasks by selecting branch, class, section, and due date, along with relevant details.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/home-work" />
        <meta name="description" content="Manage homework assignments effectively. Assign tasks by selecting branch, class, section, and due date, along with uploading relevant homework details." />
      </Helmet>

      <h2 className='manual-h1'>Homework Management</h2>
      <p>This module allows school users to easily assign homework for any subject to their classes. You can select the branch, class, section, subject, and due date, then upload the relevant homework details. It also provides a comprehensive list of all assigned homework, allowing you to search by branch name, class, or date to filter the data effectively.</p>
      <img src="/img/Add-Home-Work.avif" className='img-fluid' alt="Homework Assignment Management" />
    </div>
  )
}

