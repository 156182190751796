import React from 'react'
import { Helmet } from 'react-helmet';

export default function Management() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Fine Management" />
        <meta property="og:description" content="Learn how to apply fines to specific students in the school management system. Search for the student, enter the fine amount or reason, and save it to update their account." />
        <meta property="og:image" content="https://capobrain.com/img/Fine-Management.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/fine-management" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Fine Management" />
        <meta name="twitter:description" content="Learn how to apply fines to specific students in the school management system. Search for the student, enter the fine amount or reason, and save it to update their account." />
        <meta name="twitter:image" content="https://capobrain.com/img/Fine-Management.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/fine-management",
            "name": "Capobrain",
            "description": "Learn how to apply fines to specific students in the school management system. Search for the student, enter the fine amount or reason, and save it to update their account.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/fine-management" />
        <meta name="description" content="Learn how to apply fines to specific students in the school management system. Search for the student, enter the fine amount or reason, and save it to update their account." />
      </Helmet>

      <h2 className='manual-h1'>Fine Management</h2>
      <p>In the school management system, applying a fine to a specific student is straightforward. First, search for the student using their ID or name. Next, enter the <strong>fine amount</strong> or <strong>reason for the fine</strong> and save it. The fine will be reflected in the student's account, indicating that the school has applied the fine.</p>
      <img src="/img/Fine-Management.avif" className='img-fluid' alt="Fine Management" />
    </div>
  )
}

