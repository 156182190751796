import React from 'react'
import { Helmet } from 'react-helmet';

export default function Studentfed() {
  return (
    <div className='container'>
<Helmet>
        {/* open grapgh tag */}
        <meta property="og:title" content="Student Feedback" />
        <meta property="og:description" content="A student feedback report is a summary that combines student feedback on their educational experience. This input can come from a variety of sources, including surveys," />
        <meta property="og:image" content="https://capobrain.com/img/Student-Feedback.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/student-feedback" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Feedback" />
        <meta name="twitter:description" content="A student feedback report is a summary that combines student feedback on their educational experience. This input can come from a variety of sources, including surveys," />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Feedback.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/student-feedback",
            "name": "Capobrain",
            "description": "A student feedback report is a summary that combines student feedback on their educational experience. This input can come from a variety of sources, including surveys," ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/student-feedback" />
        <meta name="description" content="A student feedback report is a summary that combines student feedback on their educational experience. This input can come from a variety of sources, including surveys," />
      </Helmet>

      <h2 className='manual-h1'>Student Feedback</h2>
      <p>A <strong>student feedback report</strong> combines insights from students about their educational experience. This feedback can originate from various sources, including surveys, evaluations, questionnaires, and direct interactions. The primary aim of the report is to assess the quality of education, teaching methods, and overall learning experiences. The report includes essential student information along with the name of the person who provided the feedback. You can search for feedback by class and section for easy navigation.</p>
      <img src="/img/Student-Feedback.avif" className='img-fluid' alt="Student-Feedback" />
    </div>
  )
}
