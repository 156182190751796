import React from 'react'
import { Helmet } from 'react-helmet';

export default function Libraryrep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Library Report" />
        <meta property="og:description" content="A comprehensive library report detailing book information, the quantity of books issued to students, and their return dates. This tool aids librarians and administrators in tracking the library's inventory." />
        <meta property="og:image" content="https://capobrain.com/img/Library-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/library-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Library Report" />
        <meta name="twitter:description" content="A comprehensive library report detailing book information, the quantity of books issued to students, and their return dates. This tool aids librarians and administrators in tracking the library's inventory." />
        <meta name="twitter:image" content="https://capobrain.com/img/Library-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/library-report",
            "name": "Capobrain",
            "description": "A comprehensive library report detailing book information, the quantity of books issued to students, and their return dates. This tool aids librarians and administrators in tracking the library's inventory.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/library-report" />
        <meta name="description" content="A comprehensive library report detailing book information, the quantity of books issued to students, and their return dates. This tool aids librarians and administrators in tracking the library's inventory." />
      </Helmet>

      <h2 className='manual-h1'>Library Report</h2>
      <p>A library report provides a comprehensive overview of book details, including the quantity of books issued to students and their return dates. This report allows librarians and administrators to effectively track the status and availability of books, ensuring efficient management of the library's collection. With this tool, timely follow-ups can be made with students for the return of borrowed items, contributing to a well-organized and functional library system. The report displays key book details and their current status.</p>
      <img src="/img/Library-Report.avif" className='img-fluid' alt="Library Report" />
    </div>
  );
}

