import React from 'react'
import { Helmet } from 'react-helmet';

export default function Add() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Add Result - Manage Class Results Efficiently" />
        <meta property="og:description" content="In this module, easily add the results of any class by selecting the branch, exam type, class, and section. Load subjects, enter marks, and save the result for efficient student performance tracking." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Result.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/add-result" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Add Result - Manage Class Results Efficiently" />
        <meta name="twitter:description" content="Easily manage class results with this module by selecting the branch, exam type, class, and section, and entering marks for each subject." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Result.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/add-result",
            "name": "Capobrain",
            "description": "Easily manage class results by selecting the branch, exam type, class, and section, entering marks for each subject, and saving the results for performance tracking." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/add-result" />
        <meta name="description" content="Efficiently add results for any class in this module by selecting the branch, exam type, class, and section, entering marks, and saving results for performance analysis." />
      </Helmet>

      <h2 className='manual-h1'>Add Result - Efficient Class Performance Management</h2>
      <p>
        In this module, you can add the result of any class. Start by selecting the <strong>branch</strong>, <strong>exam type</strong>, <strong>class</strong>, and <strong>section</strong>, and then click the <strong>Load</strong> button. This action will load all the subjects for the selected class. After that, you can input the marks for each subject individually and save the result. The results will then be displayed in the relevant result card in the <strong>Result List</strong> for easy tracking and management of student performance.
      </p>
      <img src="/img/Add-Result.avif" className='img-fluid' alt="Add Result - Manage Class Results" />
    </div>
  )
}

