import React from 'react'
import { Helmet } from 'react-helmet';
export default function Papergen() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Paper Generator - School Management System" />
        <meta property="og:description" content="The Paper Generator module creates academic papers based on selected chapters and topics within the School Management System. Choose your branch, class, section, and subject to generate customized papers." />
        <meta property="og:image" content="https://capobrain.com/img/Show-Chapter-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/paper-generate" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Paper Generator - Best School Management Software" />
        <meta name="twitter:description" content="Create customized academic papers using the Paper Generator module by selecting chapters and topics in the School Management System." />
        <meta name="twitter:image" content="https://capobrain.com/img/Show-Chapter-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/paper-generate",
            "name": "Capobrain",
            "description": "The Paper Generator module in the School Management System enables the creation of academic papers based on selected chapters and topics. Choose your branch, class, section, and subject to generate tailored papers." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/paper-generate" />
        <meta name="description" content="The Paper Generator module in the School Management System allows users to create academic papers by selecting chapters and topics, enhancing the efficiency of paper generation." />
      </Helmet>

      <h2 className='manual-h1'>Paper Generator</h2>
      <p>The Paper Generator module is a vital component of the School Management System that allows you to create academic papers based on your selected chapters and topics. First, select the branch, class, section, and subject, followed by the chapter and topic from which questions will be picked to generate a comprehensive paper.</p>
      <img src="/img/Show-Chapter-Detail.avif" className='img-fluid' alt="Show Chapter Detail" />
    </div>
  )
}

