import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function OnlinePaperGeneration() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Online Paper Generation | CapoBrain</title>
        <meta
          name="description"
          content="Easily generate exams and assignments with CapoBrain's online paper generation feature, allowing teachers to create, customize, and distribute digital assessments efficiently."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Online Paper Generation" />
        <meta
          property="og:description"
          content="CapoBrain offers powerful tools for generating online exams and assignments, enhancing academic efficiency for teachers and students."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/online-paper-generation"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Online Paper Generation | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Generate exams and assignments effortlessly with CapoBrain's online paper generation feature."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/online-paper-generation"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Effortless Online Paper Generation
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Online Paper Generation</h1>
        <p>
          With CapoBrain's online paper generation feature, teachers can generate exams and assignments effortlessly, ensuring a smooth and efficient academic process.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Easy customization of exams and assignments</li>
          <li>Seamless digital distribution of assessments</li>
          <li>Automated generation for enhanced productivity</li>
          <li>User-friendly interface for teachers</li>
          <li>Integration with other educational tools</li>
        </ul>

        <h2>Why Choose CapoBrain for Online Paper Generation?</h2>
        <p>
          By utilizing CapoBrain's online paper generation tools, educators can streamline their assessment processes, saving time and enhancing the learning experience for students.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Transform Your Assessment Process with CapoBrain's Online Paper Generation
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
