import React from 'react'
import { Helmet } from 'react-helmet';

export default function Invoice() {
  return (
    <div className='container'>
<Helmet>
        {/* open grapgh tag */}
        <meta property="og:title" content="Invoice List - CapoBrain" />
        <meta property="og:description" content="View and manage your invoices efficiently with CapoBrain. This page displays a comprehensive list of all inventory invoices, complete with a view button for detailed information." />
        <meta property="og:image" content="https://capobrain.com/img/Invoice-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/invoice-list" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Invoice List - CapoBrain" />
        <meta name="twitter:description" content="IEfficiently manage your inventory invoices with CapoBrain. Access detailed information by clicking the view button for each invoice." />
        <meta name="twitter:image" content="https://capobrain.com/img/Invoice-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/invoice-list",
            "name": "Capobrain",
            "description": "Access and manage all your inventory invoices in one place. This page allows you to view detailed information about each invoice, including customer and payment details." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/invoice-list" />
        <meta name="description" content="View and manage your inventory invoices with CapoBrain. This page displays a comprehensive list of all invoices, allowing you to access detailed information easily." />
      </Helmet>

      <h2 className='manual-h1'>Invoices List</h2>
      <p>This page displays a comprehensive list of all invoices related to issued inventory. Each entry includes an action column with a view button, allowing you to access the corresponding invoice details. Here, you can find customer information, inventory details, and payment information. Additionally, you have the option to print invoices directly from this page, streamlining your inventory management process.</p>
      <img src="/img/Invoice-List.avif" className='img-fluid mt-5' alt="Invoice-List" />
    </div>
  )
}
