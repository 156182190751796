import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffatt2() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Staff Attendance - CapoBrain" />
        <meta property="og:description" content="The Staff Attendance reporting module in our School Management System (SMS) allows users to access a comprehensive view of staff attendance records, filtered by specific date and staff name." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Attendance-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-attendnce" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Attendance - CapoBrain" />
        <meta name="twitter:description" content="The Staff Attendance reporting module in our School Management System (SMS) allows users to access a comprehensive view of staff attendance records, filtered by specific date and staff name." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Attendance-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-attendnce",
            "name": "CapoBrain",
            "description": "The Staff Attendance reporting module allows users to access a comprehensive view of staff attendance records, filtered by specific date and staff name, as part of our School Management System (SMS).",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-attendnce" />
        <meta name="description" content="The Staff Attendance reporting module in our School Management System (SMS) allows users to access a comprehensive view of staff attendance records, filtered by specific date and staff name." />
      </Helmet>

      <h2 className='manual-h1'>Staff Attendance Report</h2>
      <p>The <strong>Staff Attendance</strong> reporting module in our <strong>School Management System (SMS)</strong> provides a comprehensive overview of staff attendance records. Users can filter the data by specific dates and staff names, making it convenient to track and monitor attendance effectively. This module allows users to generate printable reports for further analysis and record-keeping, displaying key information such as branch name, staff ID, staff name, class details, attendance status, and clock-in & clock-out times.</p>
      <img src="/img/Staff-Attendance-Report.avif" className='img-fluid' alt="Staff Attendance Report" />
    </div>
  );
}

