import React from 'react';
import { Helmet } from 'react-helmet';


export default function Notice() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="School Notice - CapoBrain" />
        <meta property="og:description" content="The Notice submodule allows you to add and manage important notices for everyone in the school community." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Notice.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/notice" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="School Notice - CapoBrain" />
        <meta name="twitter:description" content="The Notice submodule allows you to add and manage important notices for everyone in the school community." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Notice.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/notice",
            "name": "CapoBrain",
            "description": "The Notice submodule allows you to add and manage important notices for everyone in the school community.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/notice" />
        <meta name="description" content="The Notice submodule allows you to add and manage important notices for everyone in the school community." />
      </Helmet>

      <h2 className='manual-h1'>School Notice</h2>
      <p>The Notice submodule is part of the announcement module and enables you to create and manage important notices for the entire school community. You can enter the notice title, link, date, who added it, and a description before saving it. Once saved, the notice will be displayed to all users on their portal. You have the flexibility to view, update, or delete any notice at any time.</p>
      <img src="/img/Add-Notice.avif" className='img-fluid' alt="Add Notice" />
    </div>
  )
}

