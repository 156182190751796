import React from "react";
import Navigation from "./Navigation";
import { Outlet } from "react-router-dom";
import headerImg from "../img/header-img.webp";

export default function Layout() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <div>
                  <div className="">
                    <div>
                      <h1 className="animate__animated animate__zoomIn">
                        Explore Our Software User Manual
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="CapoBrain Software User Manual"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-4">
        <h2 className="text-center">
          Capobrain <span> User</span> Guide
        </h2>
        <p style={{ textAlign: "justify" }}>
          Discover how to unlock the full potential of{" "}
          <strong>CapoBrain’s School Management Software</strong> with our
          detailed <strong>software user manual</strong>. This guide provides
          step-by-step instructions on using CapoBrain’s comprehensive features,
          making it the ultimate{" "}
          <strong>online education management system</strong>.
        </p>
        <p style={{ textAlign: "justify" }}>
          The <strong>CapoBrain User Guide</strong> covers everything from
          course creation, grade tracking, and{" "}
          <strong>attendance management</strong> to real-time collaboration
          between students, teachers, and administrators. Learn how to
          efficiently manage resources, access detailed analytics, and implement
          secure access controls through our{" "}
          <strong>school management platform</strong>.
        </p>
        <p style={{ textAlign: "justify" }}>
          Whether you're an educator, student, or school administrator, this
          manual ensures you make the most of CapoBrain’s features.
          Revolutionize your experience with{" "}
          <strong>CapoBrain’s ERP software for schools</strong>, empowering
          modern education with innovative, user-friendly tools. Explore all
          functionalities by reading our complete guide today.
        </p>
        <p style={{ textAlign: "justify" }}>
          For further resources on online learning and skill development, visit{" "}
          <a
            href="https://mentorsacademia.com/"
            style={{ textDecoration: "none" }}
            target="blank"
          >
            Mentors Academia
          </a>
          , our partner platform for modern education solutions.
        </p>
      </div>
      <div className="cintainer-fluid mb-3">
        <div className="row mt-3">
          <div className=" col-md-3">
            <div style={{  background: "linear-gradient(to bottom,  #1d1ab7, #a41c85)"}}>
              <div className="pb-3 pt-2 outlet">
                <Navigation />
              </div>
            </div>
          </div>

          <div className="col-md-9 mt-3 mb-5" style={{ textAlign: "justify" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
