import React from 'react'
import { Helmet } from 'react-helmet';

export default function Student() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Student Account - CapoBrain" />
        <meta property="og:description" content="Student accounts are created at the end of the admission process, allowing users to select a fee structure and manage payments or refunds." />
        <meta property="og:image" content="https://capobrain.com/img/Student-Ledger.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/students-account" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Account - CapoBrain" />
        <meta name="twitter:description" content="Student accounts are created at the end of the admission process, allowing users to select a fee structure and manage payments or refunds." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Ledger.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/students-account",
            "name": "CapoBrain",
            "description": "Student accounts are created at the end of the admission process, allowing users to select a fee structure and manage payments or refunds.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/students-account" />
        <meta name="description" content="Student accounts are created at the end of the admission process, allowing users to select a fee structure and manage payments or refunds." />
      </Helmet>

      <h2 className='manual-h1'>Student Account</h2>
      <p>Student accounts are created at the end of the admission process, where students select their fee structure and indicate whether they wish to make an initial payment. Each account provides options to add payments or request refunds.</p>
      <p>To add a payment, administrators can search for a student by their ID, which will load essential data such as transportation fees, monthly fees, and any outstanding amounts. Payments can be submitted for the overall account or specified categories, including transportation and inventory.</p>
      <p>Refunds are also manageable within the account. All transactions are recorded, ensuring that appropriate amounts and balances are accurately displayed. Additionally, students can access their account to view all transaction history and current balances.</p>
      <img src="/img/Student-Ledger.avif" className='img-fluid' alt="Student Ledger" />
    </div>
  )
}

