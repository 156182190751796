import React from 'react'
import { Helmet } from 'react-helmet';

export default function Visitorsrep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Visitors Report - School Security and Attendance Management" />
        <meta property="og:description" content="A visitors report provides a detailed record of individuals entering school premises, including arrival and departure times, and reasons for visit." />
        <meta property="og:image" content="https://capobrain.com/img/Visitor-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/visitors-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Visitors Report - School Security and Attendance Management" />
        <meta name="twitter:description" content="A visitors report records the entry and exit of individuals on school premises, tracking visitor purpose and ensuring safety and security." />
        <meta name="twitter:image" content="https://capobrain.com/img/Visitor-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/visitors-report",
            "name": "Capobrain",
            "description": "A visitors report records the entry and exit of individuals on school premises, tracking visitor purpose and ensuring safety and security." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/visitors-report" />
        <meta name="description" content="A visitors report records the entry and exit of individuals on school premises, tracking visitor purpose and ensuring safety and security." />
      </Helmet>
      
      <h2 className='manual-h1'>Visitors Report - School Security and Attendance</h2>
      <p>
        A <strong>Visitors Report</strong> provides a comprehensive record of individuals who enter a school’s premises for various reasons. This report typically includes essential details such as the visitor’s name, time of arrival (in time), time of departure (out time), and the person they visited. This data is crucial for ensuring security and managing the flow of individuals on the school premises.
      </p>
      <p>
        Depending on the nature of the visit, the report provides specific information. For instance:
        <ul>
          <li>If the visitor is a student’s parent, it includes student-specific information.</li>
          <li>If the visitor is a staff member or external personnel, relevant details are recorded to ensure accountability and security.</li>
        </ul>
        <strong>Visitors reports</strong> help maintain safety, track attendance, and ensure all activities within the school environment are monitored effectively. It is an essential feature integrated into CapoBrain’s <strong>School Management System (SMS)</strong> for optimal security and attendance management.
      </p>
      <img src="/img/Visitor-Report.avif" className='img-fluid' alt="Visitors Report - CapoBrain School Software" />
    </div>
  );
}

