import React from 'react'
import { Helmet } from 'react-helmet';

export default function Calendar1() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Academic Calendar - CapoBrain" />
        <meta property="og:description" content="Manage your academic sessions with our calendar feature. Add academic sessions, set the starting month, and view all months of the session. Activate the current month for fee transactions." />
        <meta property="og:image" content="https://capobrain.com/img/Calendar.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/calender" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Academic Calendar - CapoBrain" />
        <meta name="twitter:description" content="Manage your academic sessions with our calendar feature. Add academic sessions, set the starting month, and view all months of the session. Activate the current month for fee transactions." />
        <meta name="twitter:image" content="https://capobrain.com/img/Calendar.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/calender",
            "name": "CapoBrain",
            "description": "Manage your academic sessions with our calendar feature. Add academic sessions, set the starting month, and view all months of the session. Activate the current month for fee transactions.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/calender" />
        <meta name="description" content="Manage your academic sessions with our calendar feature. Add academic sessions, set the starting month, and view all months of the session. Activate the current month for fee transactions." />
      </Helmet>

      <h2 className='manual-h1'>Academic Calendar</h2>
      <p>Our calendar feature allows you to manage academic sessions effectively. You can add an academic session and set the starting month, displaying a comprehensive list of all months within that session. By activating the current month, you ensure that fee transactions align with the ongoing academic month.</p>
      <img src="/img/Calendar.avif" className='img-fluid' alt="Academic Calendar" />
    </div>
  )
}

