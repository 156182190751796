import React from 'react'
import { Helmet } from 'react-helmet';

export default function Routess() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Manage Routes for Student Transportation" />
        <meta property="og:description" content="This module allows you to manage transportation routes. Enter, edit, or remove routes that will be used when allocating vehicles to students." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Routes.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/routes" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Manage Routes for Student Transportation" />
        <meta name="twitter:description" content="Manage transportation routes by entering, editing, or removing routes that will be allocated to students' vehicles." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Routes.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/routes",
            "name": "Capobrain",
            "description": "Manage transportation routes to effectively allocate vehicles to students. Enter, edit, or remove routes as necessary." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/routes" />
        <meta name="description" content="Manage transportation routes by entering, editing, or removing routes for student vehicle allocation." />
      </Helmet>

      <h1 className='manual-h1'>Manage Routes</h1>
      <p>This module is dedicated to managing transportation routes. You can enter new routes, edit existing ones, or remove them as necessary. These routes will be selected when allocating vehicles to students, so it's essential to keep the information up-to-date. Simply enter the route name in the provided field and make adjustments as needed.</p>
      <img src="/img/Add-Routes.avif" className='img-fluid' alt="Manage Routes" />
    </div>
  );
}

