import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

const clientsData = [
  {
    id: 1,
    image: "img/client0.avif",
    altText: "Best School Management Software Client Logo",
    description: "Allied School Gujranwala",
  },
  {
    id: 2,
    image: "img/client1.avif",
    altText: "Client Logo for School Management Platform",
    description: "The Educators School System",
  },
  {
    id: 3,
    image: "img/client2.avif",
    altText: "Best School and College Management Software",
    description: "Dar e Arqam Schools System",
  },
  {
    id: 4,
    image: "img/client3.avif",
    altText: "Free School Management Software Client",
    description: "Shining Star Global School",
  },
  {
    id: 5,
    image: "img/client8.avif",
    altText: "Best School Management Software Client Logo",
    description: "The AIMS Schools & Academy",
  }, 
  {
    id: 6,
    image: "img/client4.avif",
    altText: "Best Campus Management System Logo",
    description: "Tameer-i-Wattan Public School",
  },
  {
    id: 7,
    image: "img/client6.avif",
    altText: "Educational Institution Management Software Client",
    description: "Saint David Catholic School",
  },
  {
    id: 8,
    image: "img/client7.avif",
    altText: "Free School Software Client Logo",
    description: "Bright Future Schools & Colleges",
  },
  {
    id: 9,
    image: "img/client5.png",
    altText: "University Management Software Client",
    description: "Abdalian International School",
  },
  {
    id: 10,
    image: "img/client9.avif",
    altText: "School Administration Software Client",
    description: "Qurtuba Schools And Colleges",
  },
];

const ClientCard = ({ image, altText, description }) => {
  return (
    <div
      className="partner-item"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <img
        className="img-fluid client-img"
        src={image}
        alt={altText}
        width="320"
        height="150"
      />
      <div className="txt-cnt">
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
};

export default function Logo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div>
        <div>
          <div>
            <div className="container logo-container py-3">
              <div className="row d-flex align-items-center justify-content-center justify-content-between">
                <div className="col-md-12 col-12 logo-text">
                  <h2 className="mb-4 text-center">Our Trusted Clients</h2>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    style={{ textAlign: "justify" }}
                  >
                    Capobrain takes pride in serving diverse clients,
                    specializing in educational institutions and management
                    solutions. Our advanced school management system (SMS)
                    empowers schools and colleges by automating administrative
                    tasks, enhancing communication, and streamlining processes.
                    As a leader among school software providers, Capobrain’s
                    digital tools, including our Student Information System
                    (SIS), are designed to improve operational efficiency.{" "}
                    <a
                      target="blank"
                      href="https://technicmentors.com/"
                      style={{ textDecoration: "underline", color: "#00B6C7" }}
                    >
                      Technic Mentors
                    </a>{" "}
                    showcases that it is not just a school software; any
                    educational institute can benefit from the cutting-edge
                    AI-based features offered by Capobrain's all-in-one school
                    management software..
                  </p>
                </div>
              </div>
              <div className="py-4">
                <div className="client-container gap-4">
                  {clientsData.map((client) => (
                    <ClientCard
                      key={client.id}
                      image={client.image}
                      altText={client.altText}
                      description={client.description}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
}
