import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet"; 
import inventoryManagementHeaderImg from "../img/header-img.webp"; 

export default function InventoryManagement() {
  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Inventory Management | CapoBrain School ERP</title>
        <meta
          name="description"
          content="Streamline your school's resources with CapoBrain's Inventory Management feature, ensuring efficient monitoring and replenishment of supplies and equipment."
        />
        <meta property="og:title" content="Inventory Management System" />
        <meta
          property="og:description"
          content="CapoBrain's Inventory Management feature helps schools efficiently track and manage resources, ensuring that supplies are always available."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/inventory-management"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Inventory Management System for Schools"
        />
        <meta
          name="twitter:description"
          content="Keep your school well-stocked with CapoBrain's Inventory Management feature, enabling seamless tracking and replenishment of supplies."
        />
        <link
          rel="canonical"
          href="https://capobrain.com/features/inventory-management"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                   Comprehensive Features of Inventory Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={inventoryManagementHeaderImg}
                  alt="Inventory Management System"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services mb-5">
        <h1 className="feature-title">
          Streamline Your School’s Resource Management
        </h1>
        <p>
          CapoBrain’s Inventory Management feature provides a comprehensive
          solution for tracking school supplies and equipment. This system
          ensures that your institution is always well-stocked and ready for
          daily operations.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Real-time tracking of inventory levels</li>
          <li>Automated reorder notifications for low stock</li>
          <li>Detailed reporting and analytics on usage</li>
          <li>Barcode scanning for quick check-in and check-out</li>
          <li>Secure management of inventory across multiple locations</li>
        </ul>

        <h2>How It Works</h2>
        <p>
          The Inventory Management system integrates seamlessly with your
          school’s existing processes. It allows you to manage supplies and
          equipment efficiently, minimizing waste and ensuring availability when
          needed. With user-friendly interfaces and robust reporting tools, you
          can maintain optimal stock levels with ease.
        </p>
      </div>

      {/* Call-to-Action Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Optimize Your Inventory Management with CapoBrain
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">Get Started Now!</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
