import React from 'react'
import { Helmet } from 'react-helmet';

export default function Sportsdeta() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Sports Detail - Manage Sports Activities" />
        <meta property="og:description" content="In this module, you will enter and manage sports information. Select the branch to view available sports types and assign students to specific sports with class, section, and scheduling details." />
        <meta property="og:image" content="https://capobrain.com/img/Sports-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/sports-detail" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Sports Detail - Manage Sports Activities" />
        <meta name="twitter:description" content="In this module, you will enter and manage sports information. Select the branch to view available sports types and assign students to specific sports with class, section, and scheduling details." />
        <meta name="twitter:image" content="https://capobrain.com/img/Sports-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/sports-detail",
            "name": "Capobrain",
            "description": "In this module, you will manage sports activities by selecting the branch and class to view available sports types and schedule sports sessions for students.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/sports-detail" />
        <meta name="description" content="In this module, you will enter and manage sports information. Select the branch to view available sports types and assign students to specific sports with class, section, and scheduling details." />
      </Helmet>

      <h2 className='manual-h1'>Sports Detail - Manage Sports Information</h2>
      <p>In this module, you will enter the sports details for students. Begin by selecting the branch, which will allow you to view the available sports types for that branch. After selecting the class, section, and student name, you can assign a sport along with the corresponding date and time. This information will be recorded for future reference.</p>
      <img src="/img/Sports-Detail.avif" className='img-fluid' alt="Sports Detail Management" />
    </div>
  )
}

