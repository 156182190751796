import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import headerImg from "../img/header-img.webp"; 

export default function MultiCampusesManagement() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Multi Campuses Management | CapoBrain</title>
        <meta
          name="description"
          content="Effortlessly manage multiple school campuses or branches with CapoBrain's Multi Campuses Management system. Centralized administration and coordination ensure seamless governance across all locations."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Multi Campuses Management" />
        <meta
          property="og:description"
          content="CapoBrain offers Multi Campuses Management for schools, providing centralized control for all branches or campuses to streamline operations and enhance educational governance."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/multi-campuses-management"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Multi Campuses Management | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Centralized management for schools with multiple branches or campuses. CapoBrain's Multi Campuses Management system allows seamless governance and efficient control."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/multi-campuses-management"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Centralized Multi-Campus Management for Seamless School Operations
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Multi Campuses Management"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Multi Campuses Management</h1>
        <p>
          CapoBrain’s Multi Campuses Management system allows schools to manage multiple campuses or branches from a centralized platform. Whether you’re overseeing different locations within the same city or across various regions, our software enables seamless coordination and unified management.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Centralized dashboard for multi-campus management</li>
          <li>Easy monitoring and reporting across all campuses</li>
          <li>Unified student and staff management system</li>
          <li>Streamlined financial operations across locations</li>
          <li>Secure data sharing between campuses</li>
        </ul>

        <h2>Why Choose CapoBrain for Multi Campus Management?</h2>
        <p>
          With CapoBrain’s multi-campus management solution, schools can ensure consistency, efficiency, and smooth operations across all their branches. Our platform simplifies administrative tasks, helping you focus on providing quality education.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Seamless Campus Coordination with CapoBrain's Multi Branch Management
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
