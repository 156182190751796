import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function ExpenseAndAssetsManagement() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Expense & Assets Management | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain offers efficient tools for tracking expenses and managing assets, providing insights and control over financial resources for optimized school operations."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Expense & Assets Management" />
        <meta
          property="og:description"
          content="Manage your school's finances effectively with CapoBrain's comprehensive expense and assets management tools."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/expense-assets-management"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Expense & Assets Management | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Track and manage expenses and assets efficiently with CapoBrain's comprehensive management tools."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/expense-assets-management"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Expense & Asset Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Expense & Assets Management</h1>
        <p>
          CapoBrain provides powerful tools for efficiently tracking expenses and managing assets, giving schools the insights and control they need for optimized operations and budgeting.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Comprehensive tracking of all school expenses</li>
          <li>Asset management tools for effective oversight</li>
          <li>Budget planning and optimization features</li>
          <li>Detailed financial reporting for informed decision-making</li>
          <li>Integration with other financial systems for seamless management</li>
        </ul>

        <h2>Why Choose CapoBrain for Expense & Assets Management?</h2>
        <p>
          By utilizing CapoBrain's expense and assets management tools, schools can maintain better control over their financial resources, improve budgeting accuracy, and enhance operational efficiency.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Streamline Your School’s Finances with CapoBrain's Management Tools
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
