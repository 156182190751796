import React from 'react'
import { Helmet } from 'react-helmet';

export default function Ledger() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="General Ledger" />
        <meta property="og:description" content="The General Ledger Report provides a comprehensive summary of every transaction entering and exiting your accounts on an accrual basis. This report is crucial for managing invoices, tracking expenses, and monitoring payments." />
        <meta property="og:image" content="https://capobrain.com/img/General-Ledger.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/general-ledger" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="General Ledger" />
        <meta name="twitter:description" content="The General Ledger Report provides a comprehensive summary of every transaction entering and exiting your accounts on an accrual basis. This report is crucial for managing invoices, tracking expenses, and monitoring payments." />
        <meta name="twitter:image" content="https://capobrain.com/img/General-Ledger.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/general-ledger",
            "name": "Capobrain",
            "description": "The General Ledger Report provides a comprehensive summary of every transaction entering and exiting your accounts on an accrual basis. This report is crucial for managing invoices, tracking expenses, and monitoring payments.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/general-ledger" />
        <meta name="description" content="The General Ledger Report provides a comprehensive summary of every transaction entering and exiting your accounts on an accrual basis. This report is crucial for managing invoices, tracking expenses, and monitoring payments." />
      </Helmet>

      <h2 className='manual-h1'>General Ledger</h2>
      <p>The "General Ledger" is a crucial financial report that summarizes every transaction entering and exiting your accounts on an accrual basis. When you issue invoices, track expenses, or receive payments, these transactions are generated in the ledger. It serves as the master account for all your company's financial activities, enabling you to effectively monitor your financial health. Key details displayed in the general ledger report include the transaction date, party involved, transaction type, cash inflow and outflow, opening balance, and overall balance.</p>
      <img src="/img/General-Ledger.avif" className='img-fluid' alt="General Ledger" />
    </div>
  );
}

