import React from 'react'
import { Helmet } from 'react-helmet';

export default function Daybook() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Day Book" />
        <meta property="og:description" content="The Day Book is a detailed record of daily financial transactions within an organization, crucial for effective accounting and financial management." />
        <meta property="og:image" content="https://capobrain.com/img/Day-Book.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/day-book" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Day Book" />
        <meta name="twitter:description" content="The Day Book is a detailed record of daily financial transactions within an organization, crucial for effective accounting and financial management." />
        <meta name="twitter:image" content="https://capobrain.com/img/Day-Book.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/day-book",
            "name": "Capobrain",
            "description": "The Day Book is a detailed record of daily financial transactions within an organization, crucial for effective accounting and financial management.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/day-book" />
        <meta name="description" content="The Day Book is a detailed record of daily financial transactions within an organization, crucial for effective accounting and financial management." />
      </Helmet>

      <h2 className='manual-h1'>Day Book</h2>
      <p>
        A Day Book report is an essential document in accounting and financial management. It provides a comprehensive record of all daily financial transactions within an organization, including details on monetary inflows and outflows such as sales, purchases, expenses, and payments for a specific day. 
      </p>
      <p>
        This report is vital for tracking financial activities, maintaining accuracy in accounting records, and ensuring transparency in daily operations, making it a fundamental part of the bookkeeping process.
      </p>
      <img src="/img/Day-Book.avif" className='img-fluid' alt="Day Book Report" />
    </div>
  );
}

