import React from 'react'
import { Helmet } from 'react-helmet';

export default function Admission() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Student Admission Form - Enroll Now!" />
        <meta property="og:description" content="Fill out the comprehensive admission form to enroll students, including personal details, family background, and guardian information. Ensure to set the fee structure beforehand." />
        <meta property="og:image" content="https://capobrain.com/img/New-Admission.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/new-admission" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Admission Form" />
        <meta name="twitter:description" content="Complete the student admission form, including personal, family, and guardian details. Set the fee structure before proceeding with the admission process." />
        <meta name="twitter:image" content="https://capobrain.com/img/New-Admission.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/new-admission",
            "name": "Student Admission Form",
            "description": "Enroll students through our detailed admission form, entering personal and family information, and selecting the appropriate fee structure.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/new-admission" />
        <meta name="description" content="Complete the student admission form, including personal, family, and guardian details. Set the fee structure before proceeding with the admission process." />
      </Helmet>

      <h2 className='manual-h1'>New Admission</h2>
      <p>
        To enroll students, you need to complete the student admission form, which includes sections for personal details, family information, and guardian details. Before starting, ensure to add the fee structure, classes, and sections in the configuration module. You will input student details step by step, selecting the desired class for admission and the type of fee structure (monthly or annually). Upon submission, you will receive a unique student ID for portal access.
      </p>
      <ul>
        <li>First, set the Fee Structure, classes, and sections in the configuration module.</li>
        <li>Image Format: Must be JPG, JPEG, or PNG.</li>
        <li>Image size should be less than or equal to 100 KB.</li>
        <li>Document Format: Must be JPG, JPEG, PDF, or PNG.</li>
        <li>The file size for instructor documents should be less than or equal to 300 KB.</li>
      </ul>
      <img src="/img/New-Admission.avif" className='img-fluid' alt="New-Admission" />
    </div>
  );
}
