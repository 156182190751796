import React from 'react'
import { Helmet } from 'react-helmet';
export default function ClassWiseQuestion() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Class Wise Question - CapoBrain" />
        <meta property="og:description" content="Utilize this module to add class-specific questions from the question bank for school admission analysis." />
        <meta property="og:image" content="https://capobrain.com/img/Class-Wise-Question-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/class-wise-question" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Class Wise Question - CapoBrain" />
        <meta name="twitter:description" content="Utilize this module to add class-specific questions from the question bank for school admission analysis." />
        <meta name="twitter:image" content="https://capobrain.com/img/Class-Wise-Question-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/class-wise-question",
            "name": "CapoBrain",
            "description": "Utilize this module to add class-specific questions from the question bank for school admission analysis.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/class-wise-question" />
        <meta name="description" content="Utilize this module to add class-specific questions from the question bank for school admission analysis." />
      </Helmet>

      <h2 className='manual-h1'>Class Wise Question</h2>
      <p>This module allows you to add specific questions for each class, which are essential for analyzing school admission applications. You will use the question bank to select the branch name, class, section, subject, and specific questions you want to include. The number of questions is customizable based on your preferences. For example, if you select Class 1, only the questions configured for that class will be displayed to students applying for admission. This distinction helps you effectively manage class-specific questions versus general questions in the question bank.</p>
      <img src="/img/Class-Wise-Question-Detail.avif" className='img-fluid' alt="Class Wise Question Detail" />
    </div>
  )
}

