import React from 'react'
import { Helmet } from 'react-helmet';

export default function Home2() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Home" />
        <meta property="og:description" content="The Home module allows you to enter essential school information that will be displayed on the website. Fill in fields such as address, email, phone number, social links, school logo, banner, description, and footer information." />
        <meta property="og:image" content="https://capobrain.com/img/Add-School-Home-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/home" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Home" />
        <meta name="twitter:description" content="The Home module allows you to enter essential school information that will be displayed on the website. Fill in fields such as address, email, phone number, social links, school logo, banner, description, and footer information." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-School-Home-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/home",
            "name": "Capobrain",
            "description": "The Home module allows you to enter essential school information that will be displayed on the website. Fill in fields such as address, email, phone number, social links, school logo, banner, description, and footer information.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/home" />
        <meta name="description" content="The Home module allows you to enter essential school information that will be displayed on the website. Fill in fields such as address, email, phone number, social links, school logo, banner, description, and footer information." />
      </Helmet>

      <h2 className='manual-h1'>Home</h2>
      <p>The Home module allows you to enter essential school information that will be displayed on the website. You can fill in fields like address, email, phone number, social links, school logo, banner, description, and footer information. Additionally, you can view, edit, and delete this information as needed.</p>
      <img src="/img/Add-School-Home-Detail.avif" className='img-fluid' alt="Home Detail" />
    </div>
  );
}

