import React from 'react'
import { Helmet } from 'react-helmet';

export default function Leave() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Leave Request Management - CapoBrain" />
        <meta property="og:description" content="Manage leave requests submitted by students and teachers through their portals. School users can view, accept, or reject leave requests." />
        <meta property="og:image" content="https://capobrain.com/img/Update-Leave.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/leave-request" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Leave Request Management - CapoBrain" />
        <meta name="twitter:description" content="Manage leave requests submitted by students and teachers through their portals. School users can view, accept, or reject leave requests." />
        <meta name="twitter:image" content="https://capobrain.com/img/Update-Leave.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/leave-request",
            "name": "CapoBrain",
            "description": "Manage leave requests submitted by students and teachers through their portals. School users can view, accept, or reject leave requests.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/leave-request" />
        <meta name="description" content="Manage leave requests submitted by students and teachers through their portals. School users can view, accept, or reject leave requests." />
      </Helmet>

      <h2 className='manual-h1'>Leave Request Management</h2>
      <p>In this module, school users can view leave requests submitted by students and teachers through their portals. Users have the authority to accept or reject these requests, and they can monitor the status of leave applications directly from their accounts.</p>
      <img src="/img/Update-Leave.avif" className='img-fluid' alt="Update Leave Request" />
    </div>
  )
}

