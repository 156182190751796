import React from 'react'
import { Helmet } from 'react-helmet';

export default function Configexams() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Exam Configuration Module" />
        <meta property="og:description" content="Create and configure exams for any class and branch, including start and end dates, as well as exam types like first term or annual." />
        <meta property="og:image" content="https://capobrain.com/img/Configure-Exams.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-exams" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Exam Configuration Module" />
        <meta name="twitter:description" content="Create and configure exams for any class and branch, including start and end dates, as well as exam types like first term or annual." />
        <meta name="twitter:image" content="https://capobrain.com/img/Configure-Exams.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/configure-exams",
            "name": "CapoBrain",
            "description": "Create and configure exams for any class and branch, including start and end dates, as well as exam types like first term or annual.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-exams" />
        <meta name="description" content="Create and configure exams for any class and branch, including start and end dates, as well as exam types like first term or annual." />
      </Helmet>

      <h2 className='manual-h1'>Configure Exams</h2>
      <p>This module allows you to create and configure exams for any class in any branch. You can specify the start and end dates and select the exam type, such as first term or annual. Make sure to configure the class and section, as well as the students associated with the exam.</p>
      <img src="/img/Configure-Exams.avif" className='img-fluid' alt="Configure Exams" />
    </div>
  )
}

