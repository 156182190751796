import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffcard() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Staff ID Card Generation in CapoBrain" />
        <meta property="og:description" content="Easily generate and manage staff ID cards within the CapoBrain certificate module. View and print ID cards for all teachers with just a few clicks." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Id-Card.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-id-card" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff ID Card Generation in CapoBrain" />
        <meta name="twitter:description" content="Easily generate and manage staff ID cards within the CapoBrain certificate module. View and print ID cards for all teachers with just a few clicks." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Id-Card.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-id-card",
            "name": "CapoBrain",
            "description": "Easily generate and manage staff ID cards within the CapoBrain certificate module. View and print ID cards for all teachers with just a few clicks.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-id-card" />
        <meta name="description" content="Easily generate and manage staff ID cards within the CapoBrain certificate module. View and print ID cards for all teachers with just a few clicks." />
      </Helmet>

      <h2 className='manual-h1'>Staff ID Card</h2>
      <p>In CapoBrain, once you add a teacher, the staff ID card is automatically generated in the certificate module. This feature provides a comprehensive list of all teachers who possess an ID card, allowing you to view and print them effortlessly. Each ID card displays essential details, including the Teacher ID, name, and branch name, ensuring easy identification and record-keeping.</p>
      <img src="/img/Staff-ID-Card.avif" className='img-fluid' alt="Staff ID Card" />
    </div>
  )
}
