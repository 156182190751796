import React from 'react'
import { Helmet } from 'react-helmet';

export default function Innventoryrep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Inventory Management Report" />
        <meta property="og:description" content="An inventory management report provides a comprehensive summary of an organization's inventory, detailing the goods, products, or materials held in stock at a specific point in time." />
        <meta property="og:image" content="https://capobrain.com/img/Inventory-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/inventory-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Inventory Management Report" />
        <meta name="twitter:description" content="An inventory management report provides a comprehensive summary of an organization's inventory, detailing the goods, products, or materials held in stock at a specific point in time." />
        <meta name="twitter:image" content="https://capobrain.com/img/Inventory-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/inventory-report",
            "name": "Capobrain",
            "description": "An inventory management report provides a comprehensive summary of an organization's inventory, detailing the goods, products, or materials held in stock at a specific point in time." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/inventory-report" />
        <meta name="description" content="An inventory management report provides a comprehensive summary of an organization's inventory, detailing the goods, products, or materials held in stock at a specific point in time." />
      </Helmet>

      <h2 className='manual-h1'>Inventory Management Report</h2>
      <p>An inventory management report provides a detailed summary of an organization's inventory, including a comprehensive list of goods, products, or materials held in stock at a specific point in time. These inventory reports are essential tools for organizations to monitor and manage their inventory levels effectively. The report typically includes key information such as inventory name, cost price, sale price, and quantity available.</p>
      <img src="/img/Inventory-Report.avif" className='img-fluid' alt="Inventory Management Report" />
    </div>
  );
}
