import React from 'react'
import { Helmet } from 'react-helmet';

export default function Lesson() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Lesson Planning - CapoBrain" />
        <meta property="og:description" content="Easily create and manage lesson plans for various subjects. Select the branch, class, section, subject, and date to prepare effective lessons." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Lesson-Plan.jpg" />
        <meta property="og:url" content="https://capobrain.com/user-manual/lesson-planning" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Lesson Planning - CapoBrain" />
        <meta name="twitter:description" content="Create and manage lesson plans for different subjects. Easily select the branch, class, section, subject, and date for effective lesson preparation." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Lesson-Plan.jpg" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/lesson-planning",
            "name": "CapoBrain",
            "description": "Create and manage lesson plans for various subjects by selecting the branch, class, section, subject, and date. Prepare effective lessons with ease.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/lesson-planning" />
        <meta name="description" content="Create and manage lesson plans for various subjects. Easily select the branch, class, section, subject, and date for effective lesson preparation." />
      </Helmet>

      <h2 className='manual-h1'>Lesson Planning</h2>
      <p>With the lesson planning module, you can create and manage lesson plans for relevant subjects effectively. Select the branch, class, section, subject, and date, and upload the appropriate lesson details. The system provides a comprehensive list of all lesson plans for easy viewing. You can also search by branch name, class, and date, ensuring you find the right information quickly.</p>
      <img src="/img/Add-Lesson-Plan.avif" className='img-fluid' alt="Lesson Planning" />
    </div>
  )
}
