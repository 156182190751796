import React from 'react'
import { Helmet } from 'react-helmet';

export default function Nonteachingstaff() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Non-Teaching Staff - Best School Management Software" />
        <meta property="og:description" content="In our School Management System (SMS), you can enter personal information and job titles for non-teaching employees. Browse a list of all non-teaching staff and manage their details efficiently." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Non-Teachning-Staff.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/non-teaching-staff" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Non-Teaching Staff - School ERP Software" />
        <meta name="twitter:description" content="Manage non-teaching staff information, including personal details and job titles, within our comprehensive School ERP Software Solutions." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Non-Teachning-Staff.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/non-teaching-staff",
            "name": "Non-Teaching Staff",
            "description": "In our School Management System (SMS), you can enter personal information and job titles for non-teaching employees, browse the staff list, and manage their details effectively.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/non-teaching-staff" />
        <meta name="description" content="In our School Management System (SMS), you can enter personal information and job titles for non-teaching employees, and browse a list of all non-teaching staff." />
      </Helmet>

      <h2 className='manual-h1'>Non-Teaching Staff</h2>
      <p>In our School Management System (SMS), you can enter all the personnel's personal information and job titles for non-teaching employees. You can browse a list of all the non-teaching staff, and you have the ability to change any information within the form. This feature enhances your ability to manage staff efficiently using our School Software Solutions in Pakistan.</p>
      <img src="/img/Add-Non-Teachning-Staff.avif" className='img-fluid' alt="Add-Non-Teachning-Staff" />
    </div>
  );
}

