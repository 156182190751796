import React from 'react'
import { Helmet } from 'react-helmet';
export default function Typesp() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Sports Type Management" />
        <meta property="og:description" content="Select the branch and the corresponding sports types available in this module, allowing you to manage sports activities effectively." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Sport-Type.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/sports-type" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Sports Type Management" />
        <meta name="twitter:description" content="Select the branch and the corresponding sports types available in this module, allowing you to manage sports activities effectively." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Sport-Type.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/sports-type",
            "name": "Capobrain",
            "description": "Select the branch and the corresponding sports types available in this module, allowing you to manage sports activities effectively.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/sports-type" />
        <meta name="description" content="Select the branch and the corresponding sports types available in this module, allowing you to manage sports activities effectively." />
      </Helmet>

      <h2 className='manual-h1'>Sports Type Management</h2>
      <p>In this module, you will select the branch name and the corresponding sports types that will be played at that branch. This sports type is essential when adding sports details, and you can view, edit, or delete the sports types whenever needed. Selecting a sports type will also update the related sports details accordingly.</p>
      <img src="/img/Add-Sport-Type.avif" className='img-fluid' alt="Add Sport Type" />
    </div>
  )
}

