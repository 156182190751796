import React, { useEffect, useRef, useState } from "react";
import Service from "./Service";
import { Link } from "react-router-dom";
import Pricing from "./Pricing";
import Logo from "./Logo";
// import Testimonial from "./Testimonial";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import headerImg from "../img/school_management_software.png";
import aboutSection from "../img/aboutSection.png";
import Benefits from "./Benefits";
import ChooseUs from "./ChooseUs";
export default function Home() {
  const iframeRef = useRef(null);
  const [originalSrc] = useState(
    "https://www.youtube.com/embed/7k4E6ZveXkI?si=q13MoBvkdhEbuuDA"
  );

  function blockIframe() {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.src = "";
    }
  }

  function resetIframe() {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.src = originalSrc;
    }
  }

  useEffect(() => {
    AOS.init();
  }, []);
  const words = [
    "Beautifully Designed and Intelligently Developed",
    "Pakistan Ranked#1",
    "Easy to use complete all-in-one",
  ];
  const [text, setText] = useState("");
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentWord = words[wordIndex];
    const speed = isDeleting ? 10 : 50;
    const typingTimeout = setTimeout(() => {
      if (isDeleting) {
        setText(currentWord.substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
      } else {
        setText(currentWord.substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }
      if (!isDeleting && charIndex === currentWord.length) {
        setTimeout(() => setIsDeleting(true), 500);
      }
      else if (isDeleting && charIndex === 0) {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    }, speed);
    return () => clearTimeout(typingTimeout);
  }, [text, isDeleting, charIndex, wordIndex]);
  return (
    <div>
      {/* <!-- Carousel Start --> */}
      <div className="mainHeader-bg-color">
        <div className="mainHeader-overlay shapeSet">
          <div className="headerShape">
            <div className="shape1"></div>
            <div className="shape2"></div>
            <div className="shape3"></div>
            <div className="shape4"></div>
          </div>
          <div className="container">
            <div className="row d-flex py-4 align-items-center justify-content-between">
              <div className="col-md-7">
                <span className="pre-title" id="typewriter">
                  {text}
                </span>
                <br></br>
                <h1 className="head-h1 mt-3">
                  {" "}
                  <span className="sch-txt">School</span>
                  <span className="ms-txt">
                    {" "}
                    <br />
                    Management Software
                  </span>
                </h1>
                <p style={{ color: "initial" }}>
                  Familiarize yourself with innovative school management
                  software that simplifies education administration. Learn how
                  our School ERP system supports teachers, students, parents,
                  and school administrators with real-time insights and
                  efficient management tools.{" "}
                </p>
                <div className="btn-div sm-gap-4 gap-2">
                  <div className=" mr-2">
                    <button
                      type="button"
                      className="mt-2 btnFill"
                      data-bs-toggle="modal"
                      data-bs-target="#video"
                      onClick={resetIframe}
                    >
                      <i className="fa-solid fa-arrow-right me-2 moveIcon text-white"></i>
                      Watch Demo
                    </button>
                  </div>
                  {/* <div className="">
                  <Link to="https://demo.capobrain.com/dashboard">
                    <button
                      className="uw-btn uw-btn_icon uw-btn_secondary"
                      type="button"
                      style={{textWrap : "nowrap"}}
                    >
                      <span className="uw-btn__i">
                        Get a demo
                        <span className="btnFill icon">
                          <svg
                            aria-hidden="true"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                          >
                            <path
                              d="M0.266514 1.53244L3.80182 5.00447L0.266515 8.47651C-0.0888383 8.8255 -0.0888383 9.38926 0.266515 9.73826C0.621868 10.0872 1.1959 10.0872 1.55125 9.73825L5.73349 5.63087C6.08884 5.28188 6.08884 4.71812 5.73349 4.36913L1.55125 0.261745C1.1959 -0.0872479 0.621867 -0.0872479 0.266514 0.261745C-0.0797271 0.610739 -0.0888387 1.18344 0.266514 1.53244Z"
                              fill="#fff"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  className="img-fluid"
                  loading="lazy"
                  width="600"
                  height="400"
                  alt="Best School and College Management Software - CapoBrain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Carousel End --> */}

      {/* <!-- About Start --> */}
      <div className="container-fluid py-2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <img
                src={aboutSection}
                alt="Best School and College Management Software School ERP- CapoBrain"
                width="450"
                height="350"
                className="img-fluid rotate-hover object-fit-contain" 
              ></img>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              width="600"
              height="400"
            >
              <div className="about-item pt-3 pb-3">
                <h2 className="mb-2">
                  Welcome To Capobrain School Management Software
                </h2>
                <p style={{ textAlign: "justify" }}>
                  Capobrain is the ultimate school management system (SMS)
                  designed to simplify operations and boost efficiency for
                  educational institutions. Whether for schools or universities,
                  our software seamlessly integrates admissions, attendance,
                  staff management, and curriculum planning. Capobrain is the
                  ideal tool for modernizing educational administration.
                </p>
                <p style={{ textAlign: "justify" }}>
                  As a leading college management system, Capobrain offers
                  specialized features for higher education, including financial
                  management, resource allocation, and academic scheduling. Our
                  School ERP Software Solutions enable better coordination
                  between departments, helping institutions stay organized and
                  productive. Recognized as one of the best school software
                  solutions in Pakistan, Capobrain is an affordable choice for
                  both small schools and large universities.
                </p>
                {/* <div className="text-center">
                  <Link
                    type="button"
                    className="btnFill"
                    to='/about'
                    style={{display: "block" , justifyContent: "left"}}
                  >
                    <i className="fa-solid fa-arrow-right me-2 moveIcon text-white"></i>
                    Learn More
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      <Service />
      <Pricing />
      <Logo />
      <Benefits />
      <ChooseUs />
      {/* <Testimonial /> */}

      {/* video modal */}
      <div
        className="modal fade"
        id="video"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-lg" data-backdrop="static">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <button
                type="button"
                id="closeBtn"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={blockIframe}
              ></button>
            </div>
            <div className="modal-body" id="modalBody">
              <div className="ratio ratio-16x9">
                <iframe
                  ref={iframeRef}
                  width="560"
                  height="315"
                  src={originalSrc}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  loading="lazy"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
