import React from 'react'
import { Helmet } from 'react-helmet';

export default function Message() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Messaging Module" />
        <meta property="og:description" content="The Messaging module allows you to communicate with students, teachers, and parents by selecting the message subject, content, and date." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Message.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/message" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Messaging Module" />
        <meta name="twitter:description" content="The Messaging module allows you to communicate with students, teachers, and parents by selecting the message subject, content, and date." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Message.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/message",
            "name": "CapoBrain",
            "description": "The Messaging module allows you to communicate with students, teachers, and parents by selecting the message subject, content, and date.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/message" />
        <meta name="description" content="The Messaging module allows you to communicate with students, teachers, and parents by selecting the message subject, content, and date." />
      </Helmet>

      <h2 className='manual-h1'>Messages</h2>
      <p>The Messaging module allows you to send messages to students, teachers, and parents. To use this feature, first select the message subject, content, and date. You can choose to send your message to all users or to specific individuals, such as a teacher or a student in a particular class. A detailed list of sent messages is available, showing the recipients, dates, and status of each message, ensuring effective communication.</p>
      <img src="/img/Add-Message.avif" className='img-fluid' alt="Add Message" />
    </div>
  )
}

