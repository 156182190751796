import React from 'react'
import { Helmet } from 'react-helmet';
export default function Fedbackrecord() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Feedback Record" />
        <meta property="og:description" content="If a school user wants to post a review about any student, they may search by student ID. There is a feedback box for providing input, which can then be saved for future reference." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Students-Feedback.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/feedback-record" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Feedback Record" />
        <meta name="twitter:description" content="If a school user wants to post a review about any student, they can search by ID in the feedback record system. Input feedback in the box provided and save it for later." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Students-Feedback.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/feedback-record",
            "name": "Capobrain",
            "description": "If a school user wants to post a review about any student, they may search by student ID. There is a feedback box for providing input, which can then be saved for future reference." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/feedback-record" />
        <meta name="description" content="If a school user wants to post a review about any student, they may search by ID. Input feedback in the box provided and save it for later viewing." />
      </Helmet>

      <h2 className='manual-h1'>Feedback Record</h2>
      <p>If a school user wants to post a review about any student, they may search by student ID in the feedback record system. There is a feedback box where they can enter their comments, which can then be saved. This system will display a comprehensive list of all student comments for easy access and review.</p>
      <img src="/img/Add-Students-Feedback.avif" className='img-fluid' alt="Add-Students-Feedback" />
    </div>
  );
}

