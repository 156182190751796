import React from 'react'
import { Helmet } from 'react-helmet';

export default function QuestionBank() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Question Bank - CapoBrain" />
        <meta property="og:description" content="Utilize the Question Bank module to add and manage questions for school admission analysis." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Question-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/question-bank" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Question Bank - CapoBrain" />
        <meta name="twitter:description" content="Utilize the Question Bank module to add and manage questions for school admission analysis." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Question-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/question-bank",
            "name": "CapoBrain",
            "description": "Utilize the Question Bank module to add and manage questions for school admission analysis.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/question-bank" />
        <meta name="description" content="Utilize the Question Bank module to add and manage questions for school admission analysis." />
      </Helmet>

      <h2 className='manual-h1'>Question Bank</h2>
      <p>The Question Bank module is designed to help you add and manage questions that schools will use for admission analysis. You can create multiple questions tailored to specific branches, classes, sections, and subjects. During the admission process, these questions will be displayed to prospective students. You have the flexibility to decide how many questions you wish to include, ensuring a comprehensive evaluation process.</p>
      <img src="/img/Add-Question-Detail.avif" className='img-fluid' alt="Add Question Detail" />
    </div>
  )
}

