import React from 'react'
import { Helmet } from 'react-helmet';

export default function Front() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Front Office Configuration - Reception Module" />
        <meta property="og:description" content="Configure the front office reception module by adding meeting purposes and descriptions for visitor meetings. Ensure all fields are completed for accurate record-keeping." />
        <meta property="og:image" content="https://capobrain.com/img/Configure-Front-Office.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-front-office" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Front Office Configuration - Efficient Visitor Management" />
        <meta name="twitter:description" content="Set up the front office reception module by entering meeting purposes and descriptions for visitor meetings. Mandatory fields ensure accurate records." />
        <meta name="twitter:image" content="https://capobrain.com/img/Configure-Front-Office.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/configure-front-office",
            "name": "Capobrain",
            "description": "Configure the front office reception module by adding meeting purposes and descriptions for visitor meetings, ensuring all fields are mandatory for record accuracy." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-front-office" />
        <meta name="description" content="Configure the reception module settings for visitor management by adding mandatory meeting purposes and descriptions." />
      </Helmet>

      <h2 className='manual-h1'>Configure Front Office</h2>
      <p>This is the reception module settings where you can add meeting purposes and descriptions for visitor meetings. Both fields are mandatory to ensure accurate record-keeping.</p>
      <img src="/img/Configure-Front-Office.avif" className='img-fluid' alt="Configure Front Office" />
    </div>
  );
}

