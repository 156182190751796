import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffacc() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Staff Accounts - CapoBrain" />
        <meta property="og:description" content="The Staff Accounts report provides a detailed overview of each staff member's financial transactions, including payable amounts, payments made, and balances, crucial for effective school management." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Accounts.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-accounts" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Accounts - CapoBrain" />
        <meta name="twitter:description" content="The Staff Accounts report provides a detailed overview of each staff member's financial transactions, including payable amounts, payments made, and balances, crucial for effective school management." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Accounts.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-accounts",
            "name": "CapoBrain",
            "description": "The Staff Accounts report is essential for school management, offering a detailed overview of staff transactions, payable amounts, and balances.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-accounts" />
        <meta name="description" content="The Staff Accounts report provides a detailed overview of each staff member's financial transactions, including payable amounts, payments made, and balances, crucial for effective school management." />
      </Helmet>

      <h2 className='manual-h1'>Staff Accounts Report</h2>
      <p>The <strong>Staff Accounts</strong> reporting module in our <strong>School Management System (SMS)</strong> provides a comprehensive overview of all staff transactions, encompassing both inventory-related activities and other financial payments. This report is essential for effective <strong>school financial management</strong>, offering a detailed record of each staff member's account, including payable amounts, payments made, and the resulting balance. Users can efficiently search for specific records by date or staff member, facilitating the tracking and management of financial interactions within the organization.</p>
      <img src="/img/Staff-Accounts.avif" className='img-fluid' alt="Staff Accounts" />
    </div>
  );
}

