import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staff() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Staff Account - CapoBrain" />
        <meta property="og:description" content="When a teacher is added, a staff account is generated. Administrators can manage payments including deposits and withdrawals by searching for staff members by ID." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Account-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-account" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Account - CapoBrain" />
        <meta name="twitter:description" content="When a teacher is added, a staff account is generated. Administrators can manage payments including deposits and withdrawals by searching for staff members by ID." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Account-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-account",
            "name": "CapoBrain",
            "description": "When a teacher is added, a staff account is generated. Administrators can manage payments including deposits and withdrawals by searching for staff members by ID.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-account" />
        <meta name="description" content="When a teacher is added, a staff account is generated. Administrators can manage payments including deposits and withdrawals by searching for staff members by ID." />
      </Helmet>

      <h2 className='manual-h1'>Staff Account</h2>
      <p>When a teacher is added, a staff account is generated. Administrators have the option to manage payments, including deposits and withdrawals, by searching for a staff member by their ID. This process retrieves essential employee data such as salaries, inventory transactions, and outstanding balances.</p>
      <p>The staff account interface includes the following columns: Transaction Invoice, Date, Salary, Inventory, Arrears, Payable, Paid Amount, Payment Methods, and Transaction Type. The Salary column displays the total salary bill, while the Inventory column shows the corresponding inventory bill amount. The Arrears column reflects any outstanding balances.</p>
      <p>For example, if the payable amount is 1000 and the paid amount is also 1000, the balance will be zero. This balance is calculated by subtracting the paid amount from the payable amount, ensuring accurate tracking of arrears.</p>
      <p>Additionally, staff members can deposit or withdraw funds, with all transactions recorded for transparency. Both the school administration and staff members can view their accounts through their respective portals.</p>
      <img src="/img/Staff-Account-Detail.avif" className='img-fluid' alt="Staff Account Detail" />
    </div>
  )
}

