import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet"; 
import headerImg from "../img/header-img.webp"; 

export default function SchoolFrontWebsite() {
  return (
    <div>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>School/College Front Website | CapoBrain</title>
        <meta
          name="description"
          content="Create a stunning front website for your school or college with CapoBrain's customizable website feature. Present key information, showcase events, and establish a professional online presence for your institution."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="School/College Front Website" />
        <meta
          property="og:description"
          content="CapoBrain offers a customizable website solution for schools and colleges, allowing institutions to build a professional online presence to engage students, parents, and the community."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/school-front-website"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="School/College Front Website | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Build a professional front website for your school or college with CapoBrain's customizable features. Showcase important events, achievements, and news to your community."
        />
        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/school-front-website"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Customizable Front Website for Schools and Colleges
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="School Front Website"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">School/College Front Website</h1>
        <p>
          CapoBrain's Front Website feature allows schools and colleges to build a professional and customizable online presence. Showcase your institution's values, events, and achievements to engage your community and create a lasting impression.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Customizable website templates for schools and colleges</li>
          <li>Responsive design for mobile and desktop users</li>
          <li>Event management and announcements section</li>
          <li>Integration with student management systems</li>
          <li>SEO-friendly structure to improve visibility</li>
          <li>Easy-to-update content management system (CMS)</li>
        </ul>

        <h2>Why Your Institution Needs a Professional Front Website</h2>
        <p>
          A well-designed front website serves as the digital face of your institution, offering an essential communication channel for prospective students, parents, and the local community. With CapoBrain’s solution, you can build a professional online presence that reflects your school or college’s values and achievements.
        </p>
      </div>

      {/* Call to Action Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Create an Engaging Front Website for Your School Today
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Contact Us for More Info!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
