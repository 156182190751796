import React from 'react'
import { Helmet } from 'react-helmet';

export default function Summerwork() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Summer Home Work - CapoBrain" />
        <meta property="og:description" content="Enter summer homework details for any subject easily. Select the branch, class, and subject, then add the homework information and grades for student access." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Summer-Homework.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/summer-home-work" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Summer Home Work - CapoBrain" />
        <meta name="twitter:description" content="Easily enter summer homework details for any subject. Select the branch, class, and subject to add homework information and grades." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Summer-Homework.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/summer-home-work",
            "name": "CapoBrain",
            "description": "Easily enter summer homework details for any subject by selecting the branch, class, and subject, then adding the homework information and grades for student access.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/summer-home-work" />
        <meta name="description" content="Enter summer homework details for any subject easily. Select the branch, class, and subject, then add the homework information and grades for student access." />
      </Helmet>

      <h2 className='manual-h1'>Summer Home Work</h2>
      <p>This module allows school users to enter summer homework details for any subject. Select the branch, class, and subject, then input the homework information and grades, making it accessible for students on their portal.</p>
      <img src="/img/Add-Summer-Homework.avif" className='img-fluid' alt="Add Summer Homework" />
    </div>
  )
}
