import React from 'react'
import { Helmet } from 'react-helmet';

export default function Commitmentli() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Fee Commitment List" />
        <meta property="og:description" content="Search for students by ID in the fee commitment list to view records, payment amounts, and select commitment dates for fees." />
        <meta property="og:image" content="https://capobrain.com/img/Fee-Comitment-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/fee-comitment-list" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Fee Commitment List" />
        <meta name="twitter:description" content="Search for students by ID in the fee commitment list to view records, payment amounts, and select commitment dates for fees." />
        <meta name="twitter:image" content="https://capobrain.com/img/Fee-Comitment-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/fee-comitment-list",
            "name": "Capobrain",
            "description": "Search for students by ID in the fee commitment list to view records, payment amounts, and select commitment dates for fees.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/fee-comitment-list" />
        <meta name="description" content="Search for students by ID in the fee commitment list to view records, payment amounts, and select commitment dates for fees." />
      </Helmet>

      <h2 className='manual-h1'>Fee Commitment List</h2>
      <p>Search for students by <strong>student ID</strong> in the <strong>fee commitment list</strong>. This will display the student’s record, including payment amounts and the ability to select <strong>commitment dates for fees</strong>. You also have the option to display the complete fee commitment list.</p>
      <img src="/img/Fee-Comitment-List.avif" className='img-fluid' alt="Fee Commitment List" />
    </div>
  )
}

