import React from 'react'
import { Helmet } from 'react-helmet';

export default function StaffAttendance() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Staff Attendance Management - CapoBrain" />
        <meta property="og:description" content="Mark staff attendance in CapoBrain. Teachers and school users can easily record attendance by searching staff through their ID and entering clock-in or clock-out times." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Attendance.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-attendance" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Attendance Management - CapoBrain" />
        <meta name="twitter:description" content="Mark staff attendance in CapoBrain. Teachers and school users can easily record attendance by searching staff through their ID and entering clock-in or clock-out times." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Attendance.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-attendance",
            "name": "CapoBrain",
            "description": "Mark staff attendance in CapoBrain. Teachers and school users can easily record attendance by searching staff through their ID and entering clock-in or clock-out times.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-attendance" />
        <meta name="description" content="Mark staff attendance in CapoBrain. Teachers and school users can easily record attendance by searching staff through their ID and entering clock-in or clock-out times." />
      </Helmet>

      <h2 className='manual-h1'>Staff Attendance Management</h2>
      <p>This module allows you to mark staff attendance in CapoBrain. Both teachers and school users can record attendance by searching for staff through their ID. You will enter the clock-in or clock-out time and set the attendance status accordingly.</p>
      <img src="/img/Staff-Attendance.avif" className='img-fluid' alt="Staff Attendance" />
    </div>
  )
}

