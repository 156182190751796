import React from 'react'
import { Helmet } from 'react-helmet';

export default function Updatefeeli() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Update Fee List" />
        <meta property="og:description" content="View the updated fee list of all students. Clicking the view button provides detailed information about the fee, including the updated amount, date, class, and section of the student." />
        <meta property="og:image" content="https://capobrain.com/img/Update-Fee-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/update-fee-list" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Update Fee List" />
        <meta name="twitter:description" content="View the updated fee list of all students. Clicking the view button provides detailed information about the fee, including the updated amount, date, class, and section of the student." />
        <meta name="twitter:image" content="https://capobrain.com/img/Update-Fee-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/update-fee-list",
            "name": "Capobrain",
            "description": "View the updated fee list of all students. Clicking the view button provides detailed information about the fee, including the updated amount, date, class, and section of the student.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/update-fee-list" />
        <meta name="description" content="View the updated fee list of all students. Clicking the view button provides detailed information about the fee, including the updated amount, date, class, and section of the student." />
      </Helmet>

      <h2 className='manual-h1'>Update Fee List</h2>
      <p>This module displays the updated fee list for all students. By clicking the view button, you can see detailed information about the fee, such as the updated amount, date, class, and section of the student.</p>
      <img src="/img/Update-Fee-List.avif" className='img-fluid' alt="Update Fee List" />
    </div>
  )
}
