import React from 'react'
import { Helmet } from 'react-helmet';

export default function Transportfeerep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Transport Fee Report - School Management System" />
        <meta property="og:description" content="The transport fee report provides details on fees charged for school transportation services like buses, including student information and payment status." />
        <meta property="og:image" content="https://capobrain.com/img/Transport-Fee-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/transport-fee-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Transport Fee Report - CapoBrain School Management System" />
        <meta name="twitter:description" content="Detailed transport fee reports for managing school transportation services, including fee details and payment records." />
        <meta name="twitter:image" content="https://capobrain.com/img/Transport-Fee-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/transport-fee-report",
            "name": "CapoBrain",
            "description": "The transport fee report provides details on fees charged for school transportation services, displaying student information and payment status." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/transport-fee-report" />
        <meta name="description" content="The transport fee report provides details on fees charged for school transportation services, displaying student information, fee amounts, and payment records." />
      </Helmet>

      <h2 className='manual-h1'>Transport Fee Report - School Management System (SMS)</h2>
      <p>
        The <strong>Transport Fee Report</strong> within CapoBrain’s <strong>School Management System (SMS)</strong> provides a detailed summary of the fees charged to students or parents for using the school's transportation services, such as school buses. 
      </p>
      <p>
        This report includes important student information like the student’s name, ID, father's name, class, section, monthly transport fee, and payment status along with the date. The report allows for easy search and filtering by date or name, making it an efficient tool for managing transportation-related fees.
      </p>
      <ul>
        <li>Integrated into the <strong>School Fee Management System</strong> of CapoBrain</li>
        <li>Supports <strong>Online School Payment Gateway</strong> functionality</li>
        <li>Part of <strong>School ERP Software Solutions</strong> for small and large schools</li>
      </ul>
      <img src="/img/Transport-Fee-Report.avif" className='img-fluid' alt="Transport Fee Report - CapoBrain School Software" />
    </div>
  );
}

