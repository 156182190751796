import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet"; 
import headerImg from "../img/header-img.webp"; 

export default function ComprehensiveReporting() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Comprehensive Reporting | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain's Comprehensive Reporting feature provides in-depth analytics and customizable reports, offering valuable insights to improve school performance and decision-making."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Comprehensive Reporting" />
        <meta
          property="og:description"
          content="Unlock valuable insights with CapoBrain's advanced reporting tools. Customize reports for financial, academic, and performance evaluation to enhance school management."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/comprehensive-reporting"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Comprehensive Reporting | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="CapoBrain's customizable reporting feature offers data analytics for schools, improving decision-making and enhancing overall performance."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/comprehensive-reporting"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive School Reporting for Data-Driven Decisions
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Comprehensive Reporting"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Comprehensive Reporting</h1>
        <p>
          CapoBrain’s comprehensive reporting tools empower schools to generate detailed reports on academic performance, financial activities, and overall school operations. These customizable reports are designed to help administrators make informed decisions.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Customizable report templates for various needs</li>
          <li>Detailed student performance and attendance reports</li>
          <li>Financial reporting with real-time data insights</li>
          <li>Data visualization for better understanding</li>
          <li>Automated report generation for timely reviews</li>
        </ul>

        <h2>Why Choose CapoBrain for Reporting?</h2>
        <p>
          CapoBrain offers advanced analytics and reporting features that provide valuable insights into your school's performance. From tracking student grades to analyzing financial trends, our reporting tools are designed to support better decision-making and improve efficiency.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Discover the Power of Data-Driven Insights with CapoBrain
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
