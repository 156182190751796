import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import elevateImg from "../img/elevate-education.webp"
import greeningImg from "../img/greening-education.webp"

export default function Pricing() {

  useEffect(() => {
    AOS.init();
  }, []);



  return (
    <div className="elevateGreeing">
      <div className="elevateGreeing-overlay">
        <div className="mt-5 mb-4">
          <div className="container py-4">
            <div className="row justify-content-between">
              <div className="col-md-7">
                <h2>Smart Solutions for Modern Education Management</h2>
                <p style={{ textAlign: "justify" }}>
                Transform your educational institution with Capobrain's best school and college management software. Our advanced school management system streamlines administrative tasks, enhances communication, and improves resource management for educational institutions. As a comprehensive college management system, Capobrain ensures seamless operations across campuses, making it the perfect campus management software for schools, colleges, and universities striving for excellence.
                </p>
              </div>
              <div className="col-md-4">
                <img src={elevateImg} alt="Smart education management" width="360" height="300" className="img-fluid" loading="lazy"></img>
              </div>
            </div>
            <div className="row justify-content-between reverse-order">
              <div className="col-md-4">
                <img src={greeningImg} alt="Sustainable education practices" width="360" height="300" className="img-fluid" loading="lazy"></img>
              </div>
              <div className="col-md-7">
                <h2>Embrace Sustainability with Capobrain's School Management Software</h2>
                <p style={{ textAlign: "justify" }}>
                Experience a paperless revolution with Capobrain's school management system. By automating all administrative tasks, our software eliminates the need for manual paperwork, significantly reducing paper waste. This not only enhances operational efficiency but also promotes an eco-friendly campus environment. Embrace sustainability while streamlining processes at your school or college. With Capobrain, you support a greener planet and modernize your institution's operations seamlessly, paving the way for a sustainable future in education.
                </p>
              </div>

            </div>
          </div>
          {/* <!-- Pricing End --> */}
        </div>
      </div>
    </div>
  );
}
