import React from 'react'
import { Helmet } from 'react-helmet';

export default function Homeworkmarli() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Homework Marking List - CapoBrain" />
        <meta property="og:description" content="Easily view and manage homework marks for any subject. Generate result cards and edit marks with just a click." />
        <meta property="og:image" content="https://capobrain.com/img/Summer-Vacation-Homework-Marking-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/home-work-marking-list" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Homework Marking List - CapoBrain" />
        <meta name="twitter:description" content="View and manage homework marks for any subject. Generate result cards and edit marks easily." />
        <meta name="twitter:image" content="https://capobrain.com/img/Summer-Vacation-Homework-Marking-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/home-work-marking-list",
            "name": "CapoBrain",
            "description": "Manage and view homework marking easily. Generate result cards and edit marks for any subject.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/home-work-marking-list" />
        <meta name="description" content="Easily view and manage homework marks for any subject. Generate result cards and edit marks with just a click." />
      </Helmet>

      <h2 className='manual-h1'>Homework Marking List</h2>
      <p>In the homework marking list, you can easily view the marks for any subject and generate result cards effortlessly. By clicking the view button, you will also have access to an edit button, allowing you to modify marks for any subject. Students and parents can view their result cards on their respective portals, enhancing transparency and engagement.</p>
      <img src="/img/Summer-Vacation-Homework-Marking-Detail.avif" className='img-fluid' alt="Homework Marking List" />
    </div>
  )
}

