import React from 'react'
import { Helmet } from 'react-helmet';

export default function Coursecat() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Course Category - E-Learning Management" />
        <meta property="og:description" content="Before adding a course in our e-learning platform, you need to create a course category. Simply input the category name to manage your courses effectively." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Course-Category.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/course-category" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Course Category - Best E-Learning Management Software" />
        <meta name="twitter:description" content="Create and manage course categories before adding courses in our e-learning management system. Input the category name for better organization." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Course-Category.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/course-category",
            "name": "Capobrain",
            "description": "In our e-learning management system, you must add a course category before adding courses. Input the category name to view, edit, and delete existing categories." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/course-category" />
        <meta name="description" content="To add a course, first create a course category in our e-learning management software. Simply input the category name to manage your courses effectively." />
      </Helmet>

      <h2 className='manual-h1'>Course Category</h2>
      <p>Before adding a course in our e-learning platform, you must first add a course category. Simply input the category name, and it will display a list of all your categories, allowing you to see, edit, and delete them for better course management.</p>
      <img src="/img/Add-Course-Category.avif" className='img-fluid' alt="Add Course Category" />
    </div>
  )
}
