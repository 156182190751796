import React from 'react'
import { Helmet } from 'react-helmet';

export default function Events() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Events" />
        <meta property="og:description" content="This module allows you to create and manage events for your school’s website. Add event details to display in the event submodule." />
        <meta property="og:image" content="https://capobrain.com/img/Add-School-Event-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/events" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Events" />
        <meta name="twitter:description" content="Manage school events by adding details that will be displayed on your website's event page." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-School-Event-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/events",
            "name": "Capobrain",
            "description": "This module allows you to create and manage events for your school’s website. Add event details to display in the event submodule.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/events" />
        <meta name="description" content="This module allows you to create and manage events for your school’s website. Add event details to display in the event submodule." />
      </Helmet>
      
      <h2 className='manual-h1'>Events</h2>
      <p>This module allows you to create and manage events for your school’s website. You will add event details such as the event name, location, start and end dates, an image, and a description. These details will be displayed on the corresponding event page when you visit the website. You can also view, modify, or delete any event, and changes will be reflected on the website immediately.</p>
      <img src="/img/Add-School-Event-Detail.avif" className='img-fluid' alt="Event Details" />
    </div>
  );
}

