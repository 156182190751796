import React from "react"; 
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Ensure you have this import for the Link component
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function CloudBased() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Cloud-Based School Management System | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain's cloud-based school management system ensures seamless access to your school data from anywhere. Manage your school with a secure, reliable, and mobile-friendly platform."
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Cloud-Based School Management System"
        />
        <meta
          property="og:description"
          content="Access your school management system from anywhere with CapoBrain's secure cloud-based platform. Manage data and administration effortlessly with mobile and web compatibility."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/cloud-based-school-management-system"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Cloud-Based School Management System"
        />
        <meta
          name="twitter:description"
          content="CapoBrain's cloud-based school management system ensures seamless access to your school data from anywhere."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/cloud-based-school-management-system"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <div>
                  <h1 className="animate__animated animate__zoomIn">
                    Comprehensive Features of Cloud-Based School Management System
                  </h1>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Cloud-Based School Management System</h1>
        <p>
          CapoBrain’s cloud-based school management system allows schools to
          manage operations from any location, providing secure, real-time data
          access. This flexible, mobile-friendly solution eliminates the need
          for local servers and reduces IT infrastructure costs.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Secure, remote access from anywhere</li>
          <li>Real-time data updates and backups</li>
          <li>Mobile and web-friendly interface</li>
          <li>Reduced IT costs</li>
          <li>Scalable for educational institutions of all sizes</li>
        </ul>

        <h2>Why Choose Cloud-Based?</h2>
        <p>
          A cloud-based system offers flexibility for administrators and staff,
          ensuring the school data is always accessible and secure. With
          real-time updates, schools can efficiently manage information, reduce
          downtime, and stay on top of educational demands.
        </p>
       
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Learn more about our Advanced School Management Software for
                    Enhanced Education Management!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

