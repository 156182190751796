import React from 'react'
import { Helmet } from 'react-helmet';
export default function List() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Result List - View and Edit Student Results" />
        <meta property="og:description" content="Access a comprehensive list of student results. You can view result details, review each student's result card, and modify subject marks as necessary." />
        <meta property="og:image" content="https://capobrain.com/img/Result-List.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/result-list" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Result List - View and Edit Student Results" />
        <meta name="twitter:description" content="Access a comprehensive list of student results. You can view result details, review each student's result card, and modify subject marks as necessary." />
        <meta name="twitter:image" content="https://capobrain.com/img/Result-List.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/result-list",
            "name": "Capobrain",
            "description": "Access a comprehensive list of student results. View details, examine result cards, and update subject marks as needed." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/result-list" />
        <meta name="description" content="Access the result list to view student results, examine detailed result cards, and make adjustments to subject marks." />
      </Helmet>

      <h2 className='manual-h1'>Result List - View and Manage Student Performance</h2>
      <p>The Result List provides you with a comprehensive overview of student performance. You can view detailed result cards for each student, examine subject marks, and make changes if necessary. Students can view their result cards through their portal, and parents have access to their child's results as well. This ensures transparency and ease of tracking student progress.</p>
      <img src="/img/Result-List.avif" className='img-fluid' alt="Result List - View and Edit Student Results" />
    </div>
  )
}

