import React from 'react'
import { Helmet } from 'react-helmet';
export default function Withdraw() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Staff Withdrawal Management - School Management System" />
        <meta property="og:description" content="Easily withdraw staff from your school by searching staff by ID and entering the withdrawal reason. This feature allows for effective staff management." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Feedback.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-withdraw" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Withdrawal Management" />
        <meta name="twitter:description" content="Withdraw staff from your school by searching their ID and providing a reason. Manage staff efficiently with this feature." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Feedback.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/staff-withdraw",
            "name": "Staff Withdrawal Management",
            "description": "Easily withdraw staff from your school by searching staff by ID and entering the reason for withdrawal. This feature allows for effective staff management.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-withdraw" />
        <meta name="description" content="Easily withdraw staff from your school by searching staff by ID and entering the withdrawal reason. Manage staff efficiently with this feature." />
      </Helmet>

      <h2 className='manual-h1'>Staff Withdrawal Management</h2>
      <p>
        If you want to withdraw any staff from school, you can easily do so by searching for the staff member by their ID. Enter the reason for withdrawal, and the staff member will be removed from the staff list. You will also have access to a list of all withdrawn staff along with their reasons, enhancing your staff management capabilities.
      </p>
      <img src="/img/feedback.avif" className='img-fluid' alt="Add-Feedback" />
    </div>
  )
}


