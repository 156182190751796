import React, { useEffect, useState } from 'react'

export default function ChooseUs() {
    const [screen, setScreen] = useState("")
    useEffect(() => {
        if (window.innerWidth > 600) {
            setScreen(4)
        } else {
            setScreen(5)
        }
    }, [])
    return (
        <div>
            <div className="container mt-4 mb-5">
                <h2 className='text-center mb-5'>Why Choose Capobrain's School Management Software?</h2>
                <div className={`row g-${screen} chooseSection text-center`}>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <h3 className='mt-3 seo-heading-h3'>Proven Track Record</h3>
                                <p>Our school and college management software has been trusted by over 50 institutions worldwide, helping them streamline operations and enhance educational outcomes with our all-in-one school management platform.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <h3 className='mt-3 seo-heading-h3'>User Satisfaction</h3>
                                <p>100% of our users report high satisfaction with our software, citing its user-friendly interface, comprehensive features like student information systems (SIS), and reliability as key reasons for their satisfaction.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <h3 className='mt-3 seo-heading-h3'>Efficiency Boost</h3>
                                <p>Institutions using Capobrain's school ERP software solutions experience an average 100% increase in administrative efficiency, allowing staff to focus more on student success and less on paperwork.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mt-lg-3">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <h3 className='mt-3 seo-heading-h3'>Cost Savings</h3>
                                <p>By automating routine tasks and reducing administrative overhead, our school software has helped institutions save up to 100% on operational costs, making it an affordable school management software solution for any budget.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mt-lg-3">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <p className='mt-3 seo-heading-h3'>Comprehensive Support</p>
                                <p>We offer 24/7 support and dedicated account managers to ensure that our clients receive prompt assistance and personalized attention whenever they need it, enhancing the overall user experience.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mt-lg-3">
                            <div className="card-body">
                                <i className='fas fa-arrow-right'></i>
                                <p className='mt-3 seo-heading-h3'>Customization</p>
                                <p>We understand that every institution is unique, which is why our customizable school ERP software offers flexible configurations to meet the specific requirements and workflows of each client.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
