import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function AIDrivenSchoolManagement() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>AI-Driven School Management | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain's AI-driven school management system enhances decision-making with intelligent insights and automates routine tasks, offering personalized experiences for effective school administration."
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="AI-Driven School Management"
        />
        <meta
          property="og:description"
          content="Automate school administration with CapoBrain’s AI-driven features. Gain intelligent insights, streamline tasks, and improve decision-making for a more effective school management system."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/ai-driven-school-management"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="AI-Driven School Management"
        />
        <meta
          name="twitter:description"
          content="CapoBrain’s AI-driven features provide intelligent insights and automation for routine tasks, transforming school administration and decision-making."
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/ai-driven-school-management"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                Comprehensive Features of Artificial intelligence School Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
      <h1 className="feature-title">AI-Driven School Management Software</h1>

        <p>
          CapoBrain’s AI-driven school management system brings the power of artificial intelligence to educational administration. With intelligent insights and task automation, schools can streamline processes, enhance decision-making, and deliver personalized experiences to students and staff.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Automated routine administrative tasks</li>
          <li>Data-driven decision-making with intelligent insights</li>
          <li>Personalized experiences for students and staff</li>
          <li>Improved operational efficiency</li>
          <li>Seamless integration with other school management tools</li>
        </ul>

        <h2>Why Choose AI-Driven School Management?</h2>
        <p>
          AI-driven management systems can predict patterns, optimize operations, and reduce administrative workload. This helps schools stay competitive while improving outcomes for students and teachers.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Discover the Best School Management Software Solutions for 
                    Enhanced Educational Administration!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
