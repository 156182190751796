import React from 'react'
import { Helmet } from 'react-helmet';

export default function Gallery() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Gallery" />
        <meta property="og:description" content="Manage your school's gallery by adding images, titles, and descriptions. The images will be displayed in the Gallery section on the website's front page." />
        <meta property="og:image" content="https://capobrain.com/img/Add-School-Galery-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/gallery" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Gallery" />
        <meta name="twitter:description" content="Manage your school's gallery by adding images, titles, and descriptions. The images will be displayed in the Gallery section on the website's front page." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-School-Galery-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/gallery",
            "name": "Capobrain",
            "description": "Manage your school's gallery by adding images, titles, and descriptions. The images will be displayed in the Gallery section on the website's front page.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/gallery" />
        <meta name="description" content="Manage your school's gallery by adding images, titles, and descriptions. The images will be displayed in the Gallery section on the website's front page." />
      </Helmet>

      <h2 className='manual-h1'>Gallery</h2>
      <p>In this gallery submodule, you can add images along with titles and descriptions. These images will be displayed in the Gallery section on the front page of your website. You also have the flexibility to change, update, or delete images as needed.</p>
      <img src="/img/Add-School-Galery-Detail.avif" className='img-fluid' alt="Gallery Detail" />
    </div>
  );
}

