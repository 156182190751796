import React from 'react'
import { Helmet } from 'react-helmet';
export default function Scholarship() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Scholarship" />
        <meta property="og:description" content="In the scholarship module, you can apply scholarships to students by first configuring the scholarship name in the configuration module and then applying it in this module by selecting the scholarship name or amount." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Scholarship.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/scholarship" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Scholarship" />
        <meta name="twitter:description" content="In the scholarship module, you can apply scholarships to students by first configuring the scholarship name in the configuration module and then applying it in this module by selecting the scholarship name or amount." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Scholarship.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/scholarship",
            "name": "Capobrain",
            "description": "In the scholarship module, you can apply scholarships to students by first configuring the scholarship name in the configuration module and then applying it in this module by selecting the scholarship name or amount.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/scholarship" />
        <meta name="description" content="In the scholarship module, you can apply scholarships to students by first configuring the scholarship name in the configuration module and then applying it in this module by selecting the scholarship name or amount." />
      </Helmet>

      <h2 className='manual-h1'>Scholarship</h2>
      <p>In the scholarship module, you can apply scholarships to students by first configuring the scholarship name in the configuration module. You have the option to select the scholarship name or enter a specific amount. There are two types of scholarships: <strong>permanent</strong> and <strong>temporary</strong>. Each scholarship transaction will be recorded in the student's account.</p>
      <img src="/img/Add-Scholarship.avif" className='img-fluid' alt="Add Scholarship" />
    </div>
  )
}
