import React from 'react'

export default function Benefits() {
    return (
        <div className='benefits my-3'>
            <div className='benfits-overlay py-4'>
                <div className="container py-4">
                    <h2 className='text-center mb-4'>Benefits of Using Capobrain's Educational Management Software</h2>
                    <div className="row">
                        <div className="col-md-6 benefitsCol1">
                            <li>
                                <p className='seo-heading-h3'>Automated Attendance Tracking</p>
                                <p>Simplifies attendance management through our school management system (SMS) by automatically recording student attendance, reducing manual entry errors, and saving time for teachers.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Parent-Teacher Communication</p>
                                <p>Provides a seamless channel for communication between parents and teachers through messaging and notifications, enhancing collaboration in the student information system (SIS).</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Grade and Report Card Generation</p>
                                <p>Automatically calculates and generates grades and report cards using our school ERP software solutions, ensuring accuracy and timely delivery.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Fee Management System</p>
                                <p>Simplifies fee collection with detailed financial reports, tracks payments, and manages student accounts effectively.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Data Security</p>
                                <p>Protects sensitive student and staff data with robust security measures and regular backups, ensuring compliance with educational data regulations.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Detailed Student Profiles</p>
                                <p>Maintains comprehensive student profiles, including academic records, attendance, and personal information, all in one place.</p>
                            </li>
                        </div>
                        <div className="col-md-6">
                            <li>
                                <p className='seo-heading-h3'>Intuitive Design</p>
                                <p>Ensures ease of use with a user-friendly interface that requires minimal training for staff and students, enhancing user experience.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Library Management System</p>
                                <p>Simplifies the management of library resources, including book issuance, returns, and cataloging, tailored for educational institutions.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Detailed Reports</p>
                                <p>Generates comprehensive reports on various aspects such as academic performance and attendance.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Customizable Modules</p>
                                <p>Tailors the software to meet the specific needs of your institution with customizable modules and features for different educational environments.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Reduced Operational Costs</p>
                                <p>Lowers administrative costs by automating routine tasks and reducing paper-based processes, making it an affordable school management software.</p>
                            </li>
                            <li>
                                <p className='seo-heading-h3'>Enhanced Communication</p>
                                <p>Facilitates seamless communication between stakeholders, including parents, teachers, and administrators, through instant messaging and notifications.</p>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
