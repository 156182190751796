import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffrevrep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Staff Review Report - CapoBrain" />
        <meta property="og:description" content="The Staff Review Report provides an overview of teacher performance within a specific branch or educational institution, enabling effective monitoring and evaluation." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Review-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-review-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Review Report - CapoBrain" />
        <meta name="twitter:description" content="The Staff Review Report provides an overview of teacher performance within a specific branch or educational institution, enabling effective monitoring and evaluation." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Review-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-review-report",
            "name": "CapoBrain",
            "description": "The Staff Review Report provides an overview of teacher performance within a specific branch or educational institution, enabling effective monitoring and evaluation.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-review-report" />
        <meta name="description" content="The Staff Review Report provides an overview of teacher performance within a specific branch or educational institution, enabling effective monitoring and evaluation." />
      </Helmet>

      <h2 className='manual-h1'>Staff Review Report</h2>
      <p>The <strong>Staff Review Report</strong> serves as a comprehensive document that assesses teacher performance within a specific branch or educational institution. It typically includes essential information such as the branch name, teacher details (including their name, class, section, and identification number), performance ratings (often represented in star format), and evaluation status. This report is an invaluable tool for assessing educator effectiveness, facilitating data-driven decision-making, and promoting continuous improvement within the educational system.</p>
      <img src="/img/Staff-Review-Report.avif" className='img-fluid' alt="Staff Review Report" />
    </div>
  );
}

