import React from 'react'
import { Helmet } from 'react-helmet';

export default function Staffreview() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Staff Review - School Management System" />
        <meta property="og:description" content="In our School Management System (SMS), school users can post reviews about staff members by searching their ID. Users can rate staff performance and save their feedback, which will be displayed for future reference." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Staff-Review.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-review" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Review - Best School Management Software" />
        <meta name="twitter:description" content="Post reviews about staff in our School ERP Software. Search by ID, rate performance, and manage feedback efficiently." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Staff-Review.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/staff-review",
            "name": "Staff Review",
            "description": "In our School Management System (SMS), school users can post reviews about staff by searching their ID and providing ratings based on performance.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-review" />
        <meta name="description" content="Post reviews about staff members using our School Management System. Search by ID, rate performance, and manage comments effectively." />
      </Helmet>

      <h2 className='manual-h1'>Staff Review</h2>
      <p>In our School Management System (SMS), users can post reviews about staff by searching for their ID. You can provide a rating based on their performance using the rating box, and this feedback will be saved and displayed as a list of all staff comments. This feature enhances the performance management process in our School ERP Software.</p>
      <img src="/img/Add-Staff-Review.avif" className='img-fluid' alt="Add-Staff-Review" />
    </div>
  );
}

