import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function ExamsResultsManagement() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Exams & Results Management | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain's Exams & Results Management feature streamlines the exam process and allows for efficient results tracking and reporting, ensuring smooth administration and academic success."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Exams & Results Management" />
        <meta
          property="og:description"
          content="Streamline exams and results management with CapoBrain's advanced module for smooth administration and academic performance tracking."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/exams-results-management"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Exams & Results Management | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Manage exams and results efficiently with CapoBrain's exams and results management system for academic success."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/exams-results-management"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Exams & Results Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Exams Results Management"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Exams & Results Management</h1>
        <p>
          CapoBrain's Exams & Results Management module simplifies exam administration, making it easy to schedule exams, manage student results, and generate detailed academic reports.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Automated exam scheduling and planning</li>
          <li>Efficient result calculation and analysis</li>
          <li>Digital storage of exam records and results</li>
          <li>Customizable result formats and reporting</li>
          <li>Integration with other academic tools</li>
        </ul>

        <h2>Why Use CapoBrain for Exams & Results Management?</h2>
        <p>
          With our comprehensive tools, managing exams and results has never been easier. Our software ensures academic efficiency, reduces administrative workload, and provides valuable insights into student performance.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Streamline Your Exams & Results with CapoBrain's Management System
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
