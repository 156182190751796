import React from 'react'
import { Helmet } from 'react-helmet';

export default function Studentcard() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Student ID Card Management in CapoBrain" />
        <meta property="og:description" content="When a student is admitted to school, their Student ID Card is generated in the certificate module. This feature displays a comprehensive list of all students with ID cards, allowing for easy viewing and printing." />
        <meta property="og:image" content="https://capobrain.com/img/Student-Id-Card.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/student-id-card" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student ID Card Management in CapoBrain" />
        <meta name="twitter:description" content="When a student is admitted to school, their Student ID Card is generated in the certificate module. This feature displays a comprehensive list of all students with ID cards, allowing for easy viewing and printing." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Id-Card.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/student-id-card",
            "name": "CapoBrain",
            "description": "When a student is admitted to school, their Student ID Card is generated in the certificate module. This feature displays a comprehensive list of all students with ID cards, allowing for easy viewing and printing.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/student-id-card" />
        <meta name="description" content="When a student is admitted to school, their Student ID Card is generated in the certificate module. This feature displays a comprehensive list of all students with ID cards, allowing for easy viewing and printing." />
      </Helmet>

      <h2 className='manual-h1'>Student ID Card</h2>
      <p>When a student is admitted to school, their Student ID Card is generated in the certificate module. This feature displays a comprehensive list of all students with ID cards, allowing you to view and print each card. The ID card includes essential details such as Student ID, Name, Father's Name, Class, Section, and Branch Name.</p>
      <img src="/img/Student-ID-Card.avif" className='img-fluid' alt="Student ID Card" />
    </div>
  )
}

