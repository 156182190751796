import React from 'react'
import { Helmet } from 'react-helmet';
export default function SalaryCycle() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Salary Cycle - CapoBrain" />
        <meta property="og:description" content="Select a month to activate the salary cycle. The active month will dictate salary transactions for the current cycle." />
        <meta property="og:image" content="https://capobrain.com/img/Salary-Cycle.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/salary-cycle" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Salary Cycle - CapoBrain" />
        <meta name="twitter:description" content="Select a month to activate the salary cycle. The active month will dictate salary transactions for the current cycle." />
        <meta name="twitter:image" content="https://capobrain.com/img/Salary-Cycle.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/salary-cycle",
            "name": "CapoBrain",
            "description": "Select a month to activate the salary cycle. The active month will dictate salary transactions for the current cycle.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/salary-cycle" />
        <meta name="description" content="Select a month to activate the salary cycle. The active month will dictate salary transactions for the current cycle." />
      </Helmet>

      <h2 className='manual-h1'>Salary Cycle</h2>
      <p>In the salary cycle, you will choose a month to activate the cycle. This selected month will be marked as active, and all salary transactions will proceed according to this month. The current active month will be displayed with the status "Active," while all remaining months will show as "Non-Active."</p>
      <img src="/img/Salary-Cycle.avif" className='img-fluid' alt="Salary Cycle" />
    </div>
  )
}

