import React from 'react'
import { Helmet } from 'react-helmet';

export default function Homeworkmark() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Homework Marking Management - CapoBrain" />
        <meta property="og:description" content="Efficiently enter and manage summer homework marking for students. Select branch, class, section, and subject to load student lists for marking." />
        <meta property="og:image" content="https://capobrain.com/img/Summer-Vacation-Homework-Marking.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/home-work-marking" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Homework Marking Management - CapoBrain" />
        <meta name="twitter:description" content="Easily enter summer homework marking by selecting branch, class, section, and subject to manage student evaluations." />
        <meta name="twitter:image" content="https://capobrain.com/img/Summer-Vacation-Homework-Marking.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/home-work-marking",
            "name": "CapoBrain",
            "description": "Manage summer homework marking with ease. Select branch, class, section, and subject to view and evaluate student performance.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/home-work-marking" />
        <meta name="description" content="Efficiently enter and manage summer homework marking for students. Select branch, class, section, and subject to load student lists for marking." />
      </Helmet>

      <h2 className='manual-h1'>Homework Marking Management</h2>
      <p>This module allows school administrators to efficiently manage summer homework marking. Start by selecting the branch, class, section, and subject from the summer homework marking list. After clicking the load button, a list of students based on your filters will be displayed. You can then enter the homework marks, which students and parents can view on their portal, while teachers can manage the marking from their own portal.</p>
      <img src="/img/Summer-Vacation-Homework-Marking.avif" className='img-fluid' alt="Summer Homework Marking" />
    </div>
  )
}

