import React from 'react'
import { Helmet } from 'react-helmet';
export default function Frecords() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Student Family Records - View Family Details" />
        <meta property="og:description" content="Access the family records of students, which provides insights into how many students share the same family. Click on the view button to see detailed information." />
        <meta property="og:image" content="https://capobrain.com/img/Family-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/family-record" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Family Records" />
        <meta name="twitter:description" content="Explore the family records of students, revealing how many students belong to the same family. Click to view detailed student information." />
        <meta name="twitter:image" content="https://capobrain.com/img/Family-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/family-record",
            "name": "Family Record",
            "description": "View family records of students to see how many share the same family. Click the view button to access detailed student information.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/family-record" />
        <meta name="description" content="Explore the family records of students, revealing how many students belong to the same family. Click to view detailed student information." />
      </Helmet>

      <h2 className='manual-h1'>Family Record</h2>
      <p>
        Access a comprehensive list of student family records. This feature allows you to see how many students belong to the same family. Clicking on the view button will display detailed information including the student's name, ID, class, and section.
      </p>
      <img src="/img/Family-Detail.avif" className='img-fluid' alt="Family-Detail" />
    </div>
  );
}
