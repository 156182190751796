import React from 'react'
import { Helmet } from 'react-helmet';

export default function Salarypaid() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Salary Paid Report - CapoBrain" />
        <meta property="og:description" content="Explore our Salary Paid report, a vital tool for school management, summarizing employee wages paid over a specified time period. Perfect for financial management and tracking salary disbursement." />
        <meta property="og:image" content="https://capobrain.com/img/Salary-Paid.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/salary-paid" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Salary Paid Report - CapoBrain" />
        <meta name="twitter:description" content="Explore our Salary Paid report, a vital tool for school management, summarizing employee wages paid over a specified time period. Perfect for financial management and tracking salary disbursement." />
        <meta name="twitter:image" content="https://capobrain.com/img/Salary-Paid.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/salary-paid",
            "name": "CapoBrain",
            "description": "Salary Paid report for schools, summarizing employee wages paid and essential for effective school management and financial tracking.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/salary-paid" />
        <meta name="description" content="Explore our Salary Paid report, a vital tool for school management, summarizing employee wages paid over a specified time period. Perfect for financial management and tracking salary disbursement." />
      </Helmet>

      <h2 className='manual-h1'>Salary Paid Report</h2>
      <p>The <strong>Salary Paid report</strong> is an essential document for effective <strong>school management</strong>. It provides a comprehensive summary of the salaries or wages disbursed to employees during a specified time frame, typically on a monthly basis. This report is crucial for payroll and financial management, as it details your monthly salary, withdrawal amount, and remaining balance. It is a key feature of our <strong>School Management System (SMS)</strong> and <strong>Student Information System (SIS)</strong>, designed to streamline your financial tracking.</p>
      <img src="/img/Salary-Paid.avif" className='img-fluid' alt="Salary Paid" />
    </div>
  );
}

