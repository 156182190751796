import React from "react";
import { Link } from "react-router-dom";

export default function Service() {
  return (
    <div>
      {/* <!-- Services Start --> */}
      <h2 className="text-center mb-4 responsive-h2 fadeInRight animated animated">
        Key Features of Capobrain's School Management System{" "}
      </h2>
      <h4
        className="section-subtitle wow fadeInLeft animated animated text-center mb-4"
        style={{ visibility: "visible" }}
      >
        Over 20+ Modules you <i className="fa fa-heart heart heartcolor"></i>{" "}
        to use CapoBrain
      </h4>
      <div>
        <div>
          <div className="container-fluid services">
            <div>
              <div className="container">
                <div
                  className="text-center"
                  style={{ maxWidth: "600px" }}
                ></div>
                <div className="row g-3 services-inner">
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/cloud-based-school-managment-system"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fa-solid fa-cloud fa-3x mb-4 "></i>
                            <h3 className="mb-3 seo-heading-h3">
                              Cloud-Based School Management System
                            </h3>
                            <p className="mb-4">
                              Access Capobrain, your trusted school management
                              software, from anywhere with our secure and
                              reliable cloud-based system. Ensure your data is
                              always up-to-date and accessible for seamless
                              educational administration.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/cutting-edge-technologies"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fa-solid fa-memory fa-3x mb-4 "></i>
                            <h3 className="mb-3 seo-heading-h3">
                              Cutting-Edge Educational Technologies
                            </h3>
                            <p className="mb-4">
                              Experience unparalleled performance with
                              Capobrain, utilizing the latest cutting-edge
                              technologies for school management. Our solutions
                              deliver a seamless and efficient experience for
                              students and educators alike.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/computer-based-exam-solutions"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fa-solid fa-computer fa-3x mb-4 "></i>
                            <h3 className="mb-3 seo-heading-h3">
                              Computer-Based Exam Management
                            </h3>
                            <p className="mb-4">
                              Streamline your assessment process with
                              Capobrain's computer-based exam solutions. Our
                              software offers automated grading and instant
                              results, enhancing efficiency in school
                              assessments and reporting.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/ai-driven-school-management"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fas fa-robot fa-3x mb-4 "></i>
                            <p className="mb-3 seo-heading-h3">
                              AI-Driven School Management
                            </p>
                            <p className="mb-4">
                              Enhance decision-making and automate routine tasks
                              with Capobrain's AI-driven features. Gain
                              intelligent insights and personalized experiences
                              for effective school administration.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/data-security"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fa-solid fa-file-shield fa-3x mb-4 "></i>
                            <p className="mb-3 seo-heading-h3">Data Security</p>
                            <p className="mb-4">
                              Safeguard sensitive information with Capobrain's
                              robust protection measures. Ensure comprehensive
                              protection and privacy for all users within the
                              school management system.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/students-and-parent-portals"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fas fa-users fa-3x mb-4 "></i>
                            <h3 className="mb-3 seo-heading-h3">
                              Students & Parent Portals
                            </h3>
                            <p className="mb-4">
                              Empower students and parents with Capobrain's
                              dedicated portals, providing easy access to
                              academic records, schedules, and essential
                              communication tools for enhanced engagement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/online-daily-diary"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="far fa-calendar-alt fa-3x mb-4 "></i>
                            <p className="mb-3 seo-heading-h3">
                              Online Daily Diary Feature
                            </p>
                            <p className="mb-4">
                              Stay organized and informed with Capobrain's
                              online daily diary feature, allowing students and
                              teachers to record tasks, deadlines, and progress
                              updates effortlessly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <Link
                      to="/features/school-management-mobile-app"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-2 text-center services-content">
                          <div className="services-content-icon3">
                            <i className="fa-solid fa-mobile-screen-button fa-3x mb-4 "></i>
                            <p className="mb-3 seo-heading-h3">
                              School Management Mobile App
                            </p>
                            <p className="mb-4">
                              Stay connected on the go with Capobrain's mobile
                              app support. Enable access to key features and
                              updates anytime, anywhere, ensuring convenience
                              for students and educators.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Link type="button" className="btnFill" to="/features">
                <i className="fa-solid fa-arrow-right me-2 moveIcon text-white"></i>
                Explore More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services End --> */}
    </div>
  );
}
