import React from 'react'
import { Helmet } from 'react-helmet';

export default function Users() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Manage Users" />
        <meta property="og:description" content="Before adding users, you must first create their roles. This module allows you to manage user information including username, father's name, CNIC, phone number, email address, and assigned role." />
        <meta property="og:image" content="https://capobrain.com/img/Add-User.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/users" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Manage Users" />
        <meta name="twitter:description" content="Create and manage user profiles including role assignment, username, father's name, CNIC, phone number, email, and password." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-User.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/users",
            "name": "Capobrain",
            "description": "Before adding users, you must first create their roles. This module allows you to manage user information including username, father's name, CNIC, phone number, email address, and assigned role." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/users" />
        <meta name="description" content="Before adding users, you must first create their roles. This module allows you to manage user information including username, father's name, CNIC, phone number, email address, and assigned role." />
      </Helmet>

      <h2 className='manual-h1'>Manage Users</h2>
      <p>Before adding users, you need to create their roles. This module allows you to manage user profiles, including username, father's name, CNIC, phone number, email address, and role. For instance, if you want to add a branch user, you must first create the role of 'branch user.' Each user will need an email address and password for login, enabling you to clearly identify which user holds which role.</p>
      <img src="/img/Add-User.avif" className='img-fluid' alt="Manage Users" />
    </div>
  );
}
