import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Importing Link for navigation
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function DataSecurity() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Data Security in School Management | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain provides robust data security measures to safeguard sensitive information within the school management system, ensuring privacy and protection for all users."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Data Security in School Management" />
        <meta
          property="og:description"
          content="Ensure comprehensive data security with CapoBrain's robust protection measures for school management systems."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/data-security"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Data Security in School Management"
        />
        <meta
          name="twitter:description"
          content="Safeguard sensitive information with CapoBrain's robust data security measures."
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/data-security"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Data Security for Your School Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Data Security Header"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Data Security in School Management Software</h1>
        <p>
          CapoBrain's data security measures are designed to safeguard sensitive information within the school management system. Our robust protection ensures comprehensive security and privacy for all users, allowing schools to operate with confidence.
        </p>

        <h2>Key Benefits of Data Security</h2>
        <ul>
          <li>Robust encryption to protect sensitive data</li>
          <li>Regular security audits and updates</li>
          <li>User access controls to limit data exposure</li>
          <li>Compliance with data protection regulations</li>
          <li>24/7 monitoring for suspicious activities</li>
        </ul>

        <h2>Why Choose CapoBrain for Data Security?</h2>
        <p>
          With CapoBrain, you can rest assured that your school's data is safe. Our commitment to data security includes advanced measures that keep sensitive information protected against unauthorized access and breaches, ensuring peace of mind for administrators, teachers, and students alike.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Discover the Best Data Security Solutions for Your School Management!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
