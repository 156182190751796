import React from 'react'
import { Helmet } from 'react-helmet';

export default function Discounts() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Discounts" />
        <meta property="og:description" content="Learn how to apply discounts for specific students in the school management system. Search for the student, enter the discount amount, and save it to update the account." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Discount.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/discounts" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Discounts" />
        <meta name="twitter:description" content="Learn how to apply discounts for specific students in the school management system. Search for the student, enter the discount amount, and save it to update the account." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Discount.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/discounts",
            "name": "Capobrain",
            "description": "Learn how to apply discounts for specific students in the school management system. Search for the student, enter the discount amount, and save it to update the account.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/discounts" />
        <meta name="description" content="Learn how to apply discounts for specific students in the school management system. Search for the student, enter the discount amount, and save it to update the account." />
      </Helmet>

      <h2 className='manual-h1'>Discounts</h2>
      <p>In the school management system, applying a discount to a specific student is simple. First, search for the student using their ID or name. Next, enter the <strong>discount amount</strong> or <strong>fine amount</strong> and save it. The discounted amount will be reflected in the student’s account, helping you manage student finances efficiently.</p>
      <img src="/img/Add-Discount.avif" className='img-fluid' alt="Add Discount" />
    </div>
  )
}

