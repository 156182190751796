import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function CuttingEdgeTechnologies() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Cutting-Edge Educational Technologies | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain leverages cutting-edge educational technologies to deliver innovative school management solutions. Experience seamless and efficient school operations with our advanced tools."
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Cutting-Edge Educational Technologies"
        />
        <meta
          property="og:description"
          content="CapoBrain uses the latest cutting-edge educational technologies to provide an efficient and seamless school management experience. Stay ahead with innovative solutions."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/cutting-edge-educational-technologies"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Cutting-Edge Educational Technologies"
        />
        <meta
          name="twitter:description"
          content="Experience unparalleled performance with CapoBrain’s cutting-edge educational technologies, providing seamless school management solutions."
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/cutting-edge-educational-technologies"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                Comprehensive Features of Cutting-Edge Educational Technologies
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
      <h1 className="feature-title">Cutting-Edge Educational Technologies</h1>

        <p>
          CapoBrain is at the forefront of innovation, using the latest cutting-edge technologies to provide efficient and user-friendly school management solutions. Our platform ensures seamless integration of data and operations, making it easier for administrators, teachers, and students to collaborate and succeed.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Advanced tools for seamless management</li>
          <li>Efficient, reliable, and secure technology</li>
          <li>Enhanced user experience for administrators and educators</li>
          <li>Scalable solutions for educational institutions of all sizes</li>
          <li>Mobile-friendly and cloud-based technology</li>
        </ul>

        <h2>Why Choose Cutting-Edge Technologies?</h2>
        <p>
          CapoBrain’s adoption of the latest educational technologies allows schools to stay ahead of the curve. With cutting-edge tools, schools can streamline operations, reduce downtime, and improve overall efficiency, providing a better experience for both staff and students.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Learn more about our Advanced School Management Software for
                    Enhanced Education Management!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
