import React from 'react'
import { Helmet } from 'react-helmet';

export default function Assets() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Business Assets Management in CapoBrain" />
        <meta property="og:description" content="Manage your business assets effectively with CapoBrain. Discover the economic value of assets that promote productivity, efficiency, and revenue." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Asset.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/assets" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Business Assets Management in CapoBrain" />
        <meta name="twitter:description" content="Manage your business assets effectively with CapoBrain. Discover the economic value of assets that promote productivity, efficiency, and revenue." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Asset.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/assets",
            "name": "Business Assets Management in CapoBrain",
            "description": "Manage your business assets effectively with CapoBrain. Discover the economic value of assets that promote productivity, efficiency, and revenue.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/assets" />
        <meta name="description" content="Manage your business assets effectively with CapoBrain. Discover the economic value of assets that promote productivity, efficiency, and revenue." />
      </Helmet>

      <h2 className='manual-h1'>Business Assets Management in CapoBrain</h2>
      <p>A business asset is anything of value that has current or future economic worth to the company. Assets are critical for promoting productivity, efficiency, and revenue generation. This module allows you to manage your assets by selecting the branch name, asset type, asset name, and amount. You can also view and modify a list of all asset types, allowing for easy browsing, editing, and deletion.</p>
      <img src="/img/Add-Asset.avif" className='img-fluid' alt="Add-Asset" />
    </div>
  )
}
