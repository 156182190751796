import React from 'react'
import { Helmet } from 'react-helmet';

export default function configchap() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Configure Chapter in CapoBrain" />
        <meta property="og:description" content="Easily configure chapters in the paper generator module of CapoBrain. Set chapter names and numbers for effective organization." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Chapter-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-chapter" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Configure Chapter in CapoBrain" />
        <meta name="twitter:description" content="Easily configure chapters in the paper generator module of CapoBrain. Set chapter names and numbers for effective organization." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Chapter-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/configure-chapter",
            "name": "Configure Chapter in CapoBrain",
            "description": "Learn how to configure chapters in the paper generator module of CapoBrain. Set chapter names and numbers efficiently.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-chapter" />
        <meta name="description" content="Learn how to configure chapters in the paper generator module of CapoBrain. Set chapter names and numbers efficiently." />
      </Helmet>

      <h2 className='manual-h1'>Configure Chapter in CapoBrain</h2>
      <p>This section covers how to configure chapters in the paper generator module. You can easily set the chapter name and number, allowing for better organization and retrieval of information within your papers. The goal of configuring chapters is to streamline your workflow and enhance the overall efficiency of your documentation process.</p>
      <img src="/img/Add-Chapter-Detail.avif" className='img-fluid' alt="Add-Chapter-Detail" />
    </div>
  )
}

