import React from 'react'
import { Helmet } from 'react-helmet';

export default function Todos() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Staff Todos - Best School Management Software" />
        <meta property="og:description" content="In our School Management System (SMS), staff Todos allow school users to assign tasks to staff members by searching them via staff ID and entering task details." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Todo.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-todos" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Todos - School Management System" />
        <meta name="twitter:description" content="In our School Management System, school users can assign tasks to staff members through the Todos feature by searching via staff ID." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Todo.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/staff-todos",
            "name": "Staff Todos",
            "description": "In our School Management System, users can assign tasks to staff members by searching them via staff ID and entering task details.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-todos" />
        <meta name="description" content="In our School Management System, users can assign tasks to staff members by searching via staff ID and entering task details like description and dates." />
      </Helmet>

      <h2 className='manual-h1'>Staff Todos</h2>
      <p>
        In our School Management System (SMS), if a school user wants to assign a task to any staff member, they can do so through the staff Todos screen. The user will search for the staff member by their staff ID and enter the task description along with the start or finish date. This feature allows users to view and manage tasks assigned to staff effectively.
      </p>
      <img src="/img/Add-Todo.avif" className='img-fluid' alt="Add-Todo" /> 
    </div>
  )
}

