import React from 'react'
import { Helmet } from 'react-helmet';

export default function Recieve() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Receive Fee" />
        <meta property="og:description" content="Search for a student by ID and choose between total payment or payment per category, such as transportation or inventory." />
        <meta property="og:image" content="https://capobrain.com/img/Recieve-Fee.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/recieve-fee" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Receive Fee" />
        <meta name="twitter:description" content="Search for a student by ID and choose between total payment or payment per category, such as transportation or inventory." />
        <meta name="twitter:image" content="https://capobrain.com/img/Recieve-Fee.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/recieve-fee",
            "name": "Capobrain",
            "description": "Search for a student by ID and choose between total payment or payment per category, such as transportation or inventory.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/recieve-fee" />
        <meta name="description" content="Search for a student by ID and choose between total payment or payment per category, such as transportation or inventory." />
      </Helmet>

      <h2 className='manual-h1'>Receive Fee</h2>
      <p>To <strong>receive fees</strong>, search for a student by their ID. You can opt for either a total payment or a payment per category, such as <strong>transportation</strong> or <strong>inventory</strong>. If you enter the transport payment, it will reflect in the student’s transport column. Conversely, entering the payment in the inventory column will update the inventory field. During payment, you can choose a payment method, including <strong>cash</strong>, <strong>bank transfer</strong>, or <strong>EasyPaisa</strong>. If you enter the total amount in the payable amount area, it will appear in the paid amount in the student's account.</p>
      <img src="/img/Recieve-Fee.avif" className='img-fluid' alt="Receive Fee" />
    </div>
  )
}

