import React, { useEffect } from "react";
import headerImg from "../img/header-img.webp";
import AOS from "aos";
import "aos/dist/aos.css";
export default function FAQ() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <div>
                  <div className="">
                    <div>
                      <h1 className="animate__animated animate__zoomIn">
                        Frequently Asked Questions
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="faq" className="faq section-bg py-4">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="section-title">
            <h2 className="text-center">F.A.Q</h2>
            <p
              className="text-center"
              style={{ color: "initial", fontSize: "18px", fontWeight: "bold" }}
            >
              {" "}
              Frequently Asked <span>Questions</span>{" "}
            </p>
            <p
              className="text-center"
              style={{ color: "initial", fontSize: "18px", fontWeight: "bold" }}
            >
              Our curated list of the most frequently asked questions about our{" "}
              <strong>School & College Management Software</strong>.{" "}
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq19"
                    className="collapsed question text-start"
                  >
                    What is CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq19"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain is a comprehensive{" "}
                      <strong>College & School Management System</strong>{" "}
                      designed to streamline administrative tasks, enhance
                      reporting, and provide future predictions using Artificial
                      Intelligence features. It is an all-in-one solution for
                      both schools and colleges.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq20"
                    className="collapsed question text-start"
                  >
                    Which programming languages are used to develop CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq20"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain is developed using modern programming languages
                      to ensure efficiency, scalability, and reliability, making
                      it a top choice for educational institutions.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq21"
                    className="collapsed question text-start"
                  >
                    Is CapoBrain suitable for both schools and colleges?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq21"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain is designed to cater to the needs of both{" "}
                      <strong>schools</strong> and <strong>colleges</strong>,
                      offering tailored modules to fit each educational setting.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq22"
                    className="collapsed question text-start"
                  >
                    How does the Postal Dispatch feature work?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq22"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Postal Dispatch feature allows you to manage outgoing
                      mail, track deliveries, and maintain a record of
                      dispatched items, enhancing your{" "}
                      <strong>administrative efficiency</strong>.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq23"
                    className="collapsed question text-start"
                  >
                    Can I log phone calls using CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq23"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain's Phone Call Log feature enables you to
                      keep track of all incoming and outgoing phone calls
                      efficiently, further streamlining your{" "}
                      <strong>school management</strong> processes.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq24"
                    className="collapsed question text-start"
                  >
                    How can I add teaching and non-teaching staff to CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq24"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      You can easily add teaching and non-teaching staff
                      profiles using the Staff module and assign relevant roles
                      and responsibilities within the{" "}
                      <strong>CapoBrain software</strong>.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq25"
                    className="collapsed question text-start"
                  >
                    What is the Staff ToDo's feature used for?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq25"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Staff ToDo's feature helps staff members manage their
                      tasks, set priorities, and track progress on various
                      assignments, making it an essential tool in our{" "}
                      <strong>school ERP software</strong>.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq8"
                    className="collapsed question text-start"
                  >
                    How can I manage student admissions with CapoBrain's Student
                    Information System (SIS)?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq8"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain's Student Admission module allows you to
                      efficiently manage student admissions, maintain admission
                      records, and track application statuses, providing a
                      seamless experience for both schools and colleges.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq9"
                    className="collapsed question text-start"
                  >
                    Can I review and manage student feedback in CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq9"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain provides a dedicated module for reviewing
                      student feedback, helping you understand student
                      satisfaction and areas for improvement, crucial for
                      enhancing your school's overall performance.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq10"
                    className="collapsed question text-start"
                  >
                    How does the Leave Request feature work for staff and
                    students in CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq10"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Leave Request feature enables staff and students to
                      request leaves, which can be reviewed and approved by
                      administrators, making it easy to manage absences within
                      the school management system.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq11"
                    className="collapsed question text-start"
                  >
                    Can I manage fee payments and scholarships in CapoBrain's
                    School Fee Management System?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq11"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain offers comprehensive fee management
                      capabilities, including fee collection, scholarship
                      management, and fine adjustments, ensuring accurate
                      financial tracking for your institution.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq12"
                    className="collapsed question text-start"
                  >
                    How can I manage staff and student accounts using CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq12"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain's Accounts module allows you to manage staff and
                      student accounts, process salary payments, and maintain
                      financial records efficiently, promoting organized
                      financial management in educational institutions.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq13"
                    className="collapsed question text-start"
                  >
                    How does the Inventory List feature help in managing school
                    supplies?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq13"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Inventory List feature enables you to track school
                      supplies, manage inventory levels, and generate purchase
                      orders as needed, ensuring your school operates
                      efficiently without supply shortages.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq14"
                    className="collapsed question text-start"
                  >
                    What types of reports can I generate with CapoBrain's
                    reporting features?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq14"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain offers a wide range of reports, including
                      student attendance, staff accounts, fee reports, inventory
                      reports, and more, to help you make informed decisions for
                      your educational institution.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq15"
                    className="collapsed question text-start"
                  >
                    How can I manage user roles and permissions in CapoBrain's
                    User Management module?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq15"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain's User Management module allows you to create
                      user profiles, define roles, and assign specific
                      permissions to ensure secure and controlled access to the
                      system, enhancing data security.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq16"
                    className="collapsed question text-start"
                  >
                    Can I customize class schedules, subjects, and school
                    timings in CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq16"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain's Configurations module allows you to
                      customize class schedules, assign subjects, and define
                      school timings to suit your institution's needs, making it
                      an adaptable solution.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq17"
                    className="collapsed question text-start"
                  >
                    What is CapoBrain and how can it benefit schools and
                    colleges?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq17"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain is a comprehensive school/college management
                      system designed to streamline administrative tasks,
                      enhance communication between stakeholders, and improve
                      overall efficiency. It offers features such as student
                      enrollment, attendance tracking, exam management, fee
                      collection, and parent-teacher communication, catering to
                      diverse educational needs.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq18"
                    className="collapsed question text-start"
                  >
                    How can I learn more about CapoBrain and its features?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq18"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      You can visit the website capobrain.com for detailed
                      information about CapoBrain's features, benefits, and
                      pricing. Additionally, you can contact our team for a
                      personalized demo and consultation on our school
                      management software solutions.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq19"
                    className="collapsed question text-start"
                  >
                    Is there customer support available for CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq19"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, we provide dedicated customer support to assist you
                      with any queries, technical issues, or assistance you may
                      need with CapoBrain's school management software.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq20"
                    className="collapsed question text-start"
                  >
                    How can I get updates and new features for CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq20"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      We regularly update CapoBrain with new features and
                      improvements. You will be notified of updates, and our
                      support team will assist you with the upgrade process to
                      ensure you have the latest school ERP software.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq21"
                    className="collapsed question text-start"
                  >
                    What is the pricing model for CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq21"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Please contact our sales team or visit our pricing page
                      for detailed information on CapoBrain's pricing plans and
                      licensing options for our school management platform.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq22"
                    className="collapsed question text-start"
                  >
                    Are there any hidden charges or additional fees for using
                    CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq22"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain's pricing is transparent, with no hidden
                      charges. All fees and costs associated with using the
                      software will be clearly communicated to you, ensuring an
                      affordable school management solution.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
