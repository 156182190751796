import React from "react";

export default function Privacy() {
  return (
    <div>
      <div className="home-container">
        <div
          className="background-image1"
          style={{
            backgroundImage: `url(${"img/bg01.jpg"})`,
          }}
        >
          <div className="color-overlay1 d-flex align-items-center justify-content-center">
            <div>
              <h1 className="head text-white animate__animated animate__zoomIn">
                Privacy
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="text-center mt-5">CapoBrain Privacy Policy</h1>
        <p style={{ textAlign: "justify" }}>
          Welcome to CapoBrain's Privacy Policy. We understand the importance of
          protecting your privacy and are committed to safeguarding your
          personal information. This policy explains how we collect, use, and
          protect the data you provide when using our school management software
          platform, CapoBrain, and related services.
        </p>
        <h3>1. Information We Collect</h3>
        <p style={{ textAlign: "justify" }}>
          Personal Information: When you register for CapoBrain, sign up for
          demos, or use our services, we collect personal information such as
          your name, email address, and contact details.{" "}
          <strong>School Management Software Usage Data</strong>: We also
          collect data on how you interact with CapoBrain, including the
          features you use and pages you access.
        </p>

        <h3>2. How We Use Your Information</h3>
        <p style={{ textAlign: "justify" }}>
          We use the data collected to:
          <ul>
            <li>
              Provide and enhance our <strong>school management system</strong>{" "}
              and software solutions.
            </li>
            <li>
              Communicate with you about updates, offers, and information
              related to CapoBrain’s services.
            </li>
            <li>
              Analyze usage patterns to improve{" "}
              <strong>school management software features</strong> and user
              experience.
            </li>
          </ul>
        </p>

        <h3>3. Data Security</h3>
        <p style={{ textAlign: "justify" }}>
        We implement industry-standard security protocols to protect your personal data and ensure that our <strong>education software platform</strong> remains secure.

        </p>

        <h3> 4. Sharing Your Information</h3>
        <p style={{ textAlign: "justify" }}>
        We do not sell or share your information with third parties unless required by law or necessary to provide our services.

        </p>
        <h3>5. Your Choices</h3>
        <p style={{ textAlign: "justify" }}>
        You can manage communication preferences and update your personal information through your CapoBrain account settings. Users also have the right to request access, correction, or deletion of their data.

        </p>
        <h3>6. Cookies and Tracking Technologies</h3>
        <p style={{ textAlign: "justify" }}>
        CapoBrain uses cookies and other tracking technologies to improve your experience. You can manage cookie preferences through your browser settings.

        </p>
        <h3>7. Third-Party Links</h3>
        <p style={{ textAlign: "justify" }}>
        Our website may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their privacy policies.

        </p>
        <h3>8. Changes to this Privacy Policy</h3>
        <p style={{ textAlign: "justify" }}>
        We may update this Privacy Policy to reflect changes in our data handling practices. All updates will be posted here, and we will notify users if required by law.

        </p>
        <h3>9. Contact Us</h3>
        <p style={{ textAlign: "justify" }}>
        If you have any questions regarding this policy or your data, please contact us at [ <span style={{color:"rgb(19, 61, 120)"}}>info@capobrain.com</span> ]. By using CapoBrain's software, you agree to this Privacy Policy. We recommend reviewing this policy periodically.

        </p>
      </div>
    </div>
  );
}
