import React from 'react'
import { Helmet } from 'react-helmet';

export default function Withdrawpay() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Withdraw Amount - CapoBrain" />
        <meta property="og:description" content="The Withdraw Amount option allows employees to withdraw their salaries. Select a staff member by ID to load their details, including payable salary." />
        <meta property="og:image" content="https://capobrain.com/img/Withdraw-Amount.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/withdraw-amount" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Withdraw Amount - CapoBrain" />
        <meta name="twitter:description" content="The Withdraw Amount option allows employees to withdraw their salaries. Select a staff member by ID to load their details, including payable salary." />
        <meta name="twitter:image" content="https://capobrain.com/img/Withdraw-Amount.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/withdraw-amount",
            "name": "CapoBrain",
            "description": "The Withdraw Amount option allows employees to withdraw their salaries. Select a staff member by ID to load their details, including payable salary.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/withdraw-amount" />
        <meta name="description" content="The Withdraw Amount option allows employees to withdraw their salaries. Select a staff member by ID to load their details, including payable salary." />
      </Helmet>

      <h2 className='manual-h1'>Withdraw Amount</h2>
      <p>The Withdraw Amount option is designed for employees to withdraw their salaries. To initiate the process, first select the staff member by ID. This action will load their information, including the payable salary. Next, specify the withdraw amount, choose the payment method, and enter the name of the person processing the payment. Once completed, the transaction amount will be deducted from the staff member's account.</p>
      <img src="/img/Withdraw-Amount.avif" className='img-fluid' alt="Withdraw Amount" />
    </div>
  )
}

