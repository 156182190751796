import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet"; 
import biometricHeaderImg from "../img/header-img.webp"; 

export default function BiometricAttendance() {
  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Biometric Attendance | CapoBrain School Management Software</title>
        <meta
          name="description"
          content="Track attendance accurately with CapoBrain's Biometric Attendance feature. Ensure secure, real-time monitoring for students and staff to enhance school accountability."
        />
        <meta property="og:title" content="Biometric Attendance System" />
        <meta
          property="og:description"
          content="Ensure secure and accurate attendance tracking with CapoBrain's Biometric Attendance solution for schools, improving accountability for students and staff."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/biometric-attendance"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Biometric Attendance System for Schools"
        />
        <meta
          name="twitter:description"
          content="Efficiently monitor attendance with CapoBrain's Biometric Attendance feature."
        />
        <link
          rel="canonical"
          href="https://capobrain.com/features/biometric-attendance"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  CapoBrain Biometric Attendance
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={biometricHeaderImg}
                  alt="Biometric Attendance for School"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services mb-5">
        <h1 className="feature-title">
          Enhance Accountability with Biometric Attendance
        </h1>
        <p>
          CapoBrain’s Biometric Attendance feature provides schools with a
          secure and accurate method of tracking attendance for both students
          and staff. Say goodbye to manual errors and increase accountability
          with this automated solution.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Accurate and real-time attendance tracking</li>
          <li>Enhanced security and fraud prevention</li>
          <li>Integration with school records for instant updates</li>
          <li>Improved accountability for students and staff</li>
          <li>Detailed attendance reports for better monitoring</li>
        </ul>

        <h2>How It Works</h2>
        <p>
          Our Biometric Attendance system integrates seamlessly with your
          school's database, allowing real-time monitoring of student and staff
          presence. Using secure biometric authentication, such as fingerprint
          scanning, the system ensures precise tracking while reducing the risk
          of manipulation.
        </p>
      </div>

      {/* Call-to-Action Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Ready to Securely Track Attendance with Biometrics?
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">Get Started Now!</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
