import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Importing Link for navigation
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function OnlineDailyDiaryFeature() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Online Daily Diary Feature | CapoBrain</title>
        <meta
          name="description"
          content="Stay organized and informed with CapoBrain's online daily diary feature, allowing students and teachers to record tasks, deadlines, and progress updates effortlessly."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Online Daily Diary Feature" />
        <meta
          property="og:description"
          content="CapoBrain's online daily diary feature enhances organization by allowing students and teachers to easily record tasks and deadlines."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/online-daily-diary"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Online Daily Diary Feature"
        />
        <meta
          name="twitter:description"
          content="Stay organized with CapoBrain's online daily diary feature for students and teachers."
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/online-daily-diary"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Online Daily Diary Feature
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Online Daily Diary Header"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Online Daily Diary Feature</h1>
        <p>
          CapoBrain's online daily diary feature helps students and teachers stay organized and informed. Effortlessly record tasks, deadlines, and progress updates to enhance productivity and accountability.
        </p>

        <h2>Key Benefits of the Online Daily Diary Feature</h2>
        <ul>
          <li>Streamlined task management for students and teachers</li>
          <li>Easy recording of deadlines and important events</li>
          <li>Facilitates communication between students and teachers</li>
          <li>Promotes accountability and responsibility in task completion</li>
          <li>Accessible from any device for convenience</li>
        </ul>

        <h2>Why Choose CapoBrain's Online Daily Diary?</h2>
        <p>
          With our online daily diary feature, you can enhance your educational experience by staying organized and on top of your tasks. It's designed to foster better communication and engagement within the school community.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Discover the Best Solutions for Student Organization and Engagement!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
