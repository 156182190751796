import React from 'react'
import { Helmet } from 'react-helmet';
export default function Feereport() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="School Fee Report" />
        <meta property="og:description" content="A comprehensive fee report detailing monthly fees and student financial accounts, ensuring transparency and effective fee management." />
        <meta property="og:image" content="https://capobrain.com/img/Fee-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/fee-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="School Fee Report" />
        <meta name="twitter:description" content="A comprehensive fee report detailing monthly fees and student financial accounts, ensuring transparency and effective fee management." />
        <meta name="twitter:image" content="https://capobrain.com/img/Fee-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/fee-report",
            "name": "Capobrain",
            "description": "A comprehensive fee report detailing monthly fees and student financial accounts, ensuring transparency and effective fee management." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/fee-report" />
        <meta name="description" content="A comprehensive fee report detailing monthly fees and student financial accounts, ensuring transparency and effective fee management." />
      </Helmet>

      <h2 className='manual-h1'>School Fee Report</h2>
      <p>A school fee report is a detailed document that contains information regarding monthly fees for students. It displays an overview of the student's financial account with the school, including details such as student name, student ID, father's name, class, section, monthly fee, and the amount paid, along with the date. You can easily search for records using any date or student name, making fee management efficient and transparent.</p>
      <img src="/img/Fee-Report.avif" className='img-fluid' alt="School Fee Report" />
    </div>
  );
}

