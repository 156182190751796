import React from 'react'
import { Helmet } from 'react-helmet';

export default function Witdraw() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Withdraw Students" />
        <meta property="og:description" content="In the School Management System (SMS), if you want to withdraw any student, you can do so by searching for the student by ID and entering the reason for withdrawal. Once the student is withdrawn, they will be removed from the active student list and added to the list of withdrawn students with reasons." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Withdraw-Student.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/withdraw-students" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Withdraw Students" />
        <meta name="twitter:description" content="In the School Management System (SMS), you can withdraw students by searching their ID and providing a reason. This updates the student's status and keeps a record of withdrawal." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Withdraw-Student.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/withdraw-students",
            "name": "Capobrain",
            "description": "In the School Management System (SMS), if you want to withdraw any student, you can do so by searching for the student by ID and entering the reason for withdrawal." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/withdraw-students" />
        <meta name="description" content="In the School Management System (SMS), if you want to withdraw any student, you can do so by searching for the student by ID and entering the reason for withdrawal." />
      </Helmet>

      <h2 className='manual-h1'>Withdraw Students</h2>
      <p>If you want to withdraw any student from the school, you can do so by searching for the student by ID and entering the reason for withdrawal. After the student is withdrawn, they will be removed from the active student list and will be added to the list of withdrawn students along with the provided reasons. This feature ensures that all withdrawal records are maintained efficiently in the School Management System (SMS).</p>
      <img src="/img/Add-Withdraw-Student.avif" className='img-fluid' alt="Add-Withdraw-Student" />
    </div>
  )
}
