import React from 'react'
import { Helmet } from 'react-helmet';

export default function Transportreport() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Transport Report - School Management System" />
        <meta property="og:description" content="A transport report provides a concise summary of student transportation information, including assigned vehicle details, pickup/drop-off times, and more." />
        <meta property="og:image" content="https://capobrain.com/img/Transport-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/transport-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Transport Report - School Management System" />
        <meta name="twitter:description" content="A transport report provides a concise summary of student transportation details including vehicle information, driver details, and pickup/drop-off times." />
        <meta name="twitter:image" content="https://capobrain.com/img/Transport-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/transport-report",
            "name": "Capobrain",
            "description": "A transport report provides a concise summary of student transportation details including vehicle information, driver details, and pickup/drop-off times." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/transport-report" />
        <meta name="description" content="A transport report provides a summary of student transportation information including vehicle details, driver information, and pickup/drop-off times." />
      </Helmet>

      <h2 className='manual-h1'>Transport Report - School Management System (SMS)</h2>
      <p>
        A <strong>Transport Report</strong> within CapoBrain’s <strong>School Management System (SMS)</strong> provides a concise summary of student transportation information within an educational institution. It includes crucial details such as student information, assigned vehicle information (e.g., bus number or driver details), and specific pickup and drop-off times.
      </p>
      <p>
        This report serves as a vital tool for both school administrators and parents, ensuring the safe and efficient transportation of students to and from school. The report also supports filtering by student name, date, or vehicle, providing flexibility in managing transportation logistics.
      </p>     
      <img src="/img/Transport-Report.avif" className='img-fluid' alt="Transport Report - CapoBrain School Software" />
    </div>
  );
}
