import React from 'react'
import { Helmet } from 'react-helmet';
export default function Roles() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Manage User Roles" />
        <meta property="og:description" content="Before adding users, you must create their roles. In this module, you can declare, view, edit, and remove user roles for your application." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Role.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/roles" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Manage User Roles" />
        <meta name="twitter:description" content="Create, view, edit, and remove user roles before adding any users to your application." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Role.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/roles",
            "name": "Capobrain",
            "description": "Before adding users, you must create their roles. In this module, you can declare, view, edit, and remove user roles for your application." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/roles" />
        <meta name="description" content="Before adding users, you must create their roles. In this module, you can declare, view, edit, and remove user roles for your application." />
      </Helmet>

      <h2 className='manual-h1'>Manage User Roles</h2>
      <p>Before adding any users, you need to create their roles. This module allows you to declare, view, edit, and remove user roles, ensuring proper access control in your application.</p>
      <img src="/img/Add-Role.avif" className='img-fluid' alt="Manage User Roles" />
    </div>
  );
}

