import React from 'react'
import { Helmet } from 'react-helmet';

export default function Stureview() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Students Review" />
        <meta property="og:description" content="In the School Management System (SMS), if a school user wants to post a review about any student, they can search by ID. There are questions and a rating box where you can give a rating according to their performance and then save it. This feature is part of the comprehensive Student Information System (SIS) that helps in managing student evaluations." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Student-Review.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/students-review" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Students Review" />
        <meta name="twitter:description" content="In the School Management System (SMS), school users can post reviews about students by searching their ID and rating their performance through a rating box." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Student-Review.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/students-review",
            "name": "Capobrain",
            "description": "In the School Management System (SMS), school users can post reviews about students by searching their ID and rating their performance through a rating box." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/students-review" />
        <meta name="description" content="In the School Management System (SMS), school users can post reviews about students by searching their ID and rating their performance through a rating box." />
      </Helmet>

      <h2 className='manual-h1'>Student Review</h2>
      <p>If a school user wants to post a review about any student in the School Management System (SMS), they may search by ID. There are questions and a rating box where you can provide a rating based on their performance. Once saved, it will display a list of all student reviews, allowing for easy tracking and management of student evaluations.</p>
      <img src="/img/Add-Student-Review.avif" className='img-fluid' alt="Add-Student-Review" />
    </div>
  )
}

