import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import headerImg from "../img/header-img.webp";

export default function FeaturePage() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <div>
                  <div className="">
                    <div>
                      <h1 className="animate__animated animate__zoomIn">
                        Comprehensive School Management Software Features
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services Start --> */}
      <div className="container-fluid services mt-5 py-5 mb-2">
        <div className="container">
          <div
            className="text-center mx-auto mb-4"
            style={{ maxWidth: "550px" }}
          >
            <h2>
              {/* Our<span style={{ color: "#00B6C7" }}> Features</span>{" "} */}
              Standout Features of Our Comprehensive School Management System
            </h2>
          </div>
          <p className="mb-4" style={{ textAlign: "justify" }}>
            {" "}
            Discover the innovative features that establish CapoBrain as a
            leading School Management System (SMS) and Education Management
            System (EMS). Our platform includes streamlined student enrollment,
            automated attendance tracking, and comprehensive grade management.
            Real-time communication tools enhance collaboration among educators,
            students, and parents, while advanced analytics provide valuable
            insights for data-driven decision-making. Experience a more
            efficient and transparent approach to school management with
            CapoBrain's all-in-one solution.
          </p>
          <div className="row g-3 services-inner">
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/cloud-based-school-managment-system"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-cloud fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Cloud-Based School Management System
                      </h3>
                      <p className="mb-4">
                        Access Capobrain, your trusted school management
                        software, from anywhere with our secure and reliable
                        cloud-based system. Ensure your data is always
                        up-to-date and accessible for seamless educational
                        administration.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/cutting-edge-technologies"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-memory fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Cutting-Edge Educational Technologies
                      </h3>
                      <p className="mb-4">
                        Experience unparalleled performance with Capobrain,
                        utilizing the latest cutting-edge technologies for
                        school management. Our solutions deliver a seamless and
                        efficient experience for students and educators alike.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/computer-based-exam-solutions"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-computer fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Computer-Based Exam Management
                      </h3>
                      <p className="mb-4">
                        Streamline your assessment process with Capobrain's
                        computer-based exam solutions. Our software offers
                        automated grading and instant results, enhancing
                        efficiency in school assessments and reporting.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/ai-driven-school-management"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-robot fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        AI-Driven School Management
                      </h3>
                      <p className="mb-4">
                        Enhance decision-making and automate routine tasks with
                        Capobrain's AI-driven features. Gain intelligent
                        insights and personalized experiences for effective
                        school administration.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/data-security"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-file-shield fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Data Security
                      </h3>
                      <p className="mb-4">
                        Safeguard sensitive information with Capobrain's robust
                        protection measures. Ensure comprehensive protection and
                        privacy for all users within the school management
                        system.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/students-and-parent-portals"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-users fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Students & Parent Portals
                      </h3>
                      <p className="mb-4">
                        Empower students and parents with Capobrain's dedicated
                        portals, providing easy access to academic records,
                        schedules, and essential communication tools for
                        enhanced engagement.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/online-daily-diary"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="far fa-calendar-alt fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Online Daily Diary Feature
                      </h3>
                      <p className="mb-4">
                        Stay organized and informed with Capobrain's online
                        daily diary feature, allowing students and teachers to
                        record tasks, deadlines, and progress updates
                        effortlessly.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/school-management-mobile-app"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-mobile-screen-button fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        School Management Mobile App
                      </h3>
                      <p className="mb-4">
                        Stay connected on the go with Capobrain's mobile app
                        support. Enable access to key features and updates
                        anytime, anywhere, ensuring convenience for students and
                        educators.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/whatsapp-messaging"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-brands fa-whatsapp fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        WhatsApp Messaging
                      </h3>
                      <p className="mb-4">
                        Effortlessly communicate with students, parents, and
                        staff through CapoBrain's integrated WhatsApp messaging
                        feature, facilitating instant updates and announcements
                        for enhanced school communication.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/biometric-attendance"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fa-solid fa-clipboard-user fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Biometric Attendance
                      </h3>
                      <p className="mb-4">
                        Ensure accurate attendance tracking with CapoBrain's
                        biometric attendance feature, providing a secure and
                        efficient method for monitoring student and staff
                        presence, enhancing accountability in your school.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/salary-and-fee-management"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-money-check-alt fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Salary & Fee Management
                      </h3>
                      <p className="mb-4">
                        Efficiently manage salaries and fees with CapoBrain's
                        integrated salary and fee management system, simplifying
                        payroll processing and financial transactions for staff
                        and students, streamlining financial operations in your
                        institution.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/inventory-management"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-box-open fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Inventory Management
                      </h3>
                      <p className="mb-4">
                        Keep track of resources seamlessly with CapoBrain's
                        inventory management feature, enabling efficient
                        monitoring and replenishment of school supplies and
                        equipment, ensuring your institution is always
                        well-stocked.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/student-and-staff-ledgers"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-file-invoice-dollar fa-3x mb-4 "></i>
                      <p
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Student & Staff Ledgers
                      </p>
                      <p className="mb-4">
                        Maintain comprehensive financial records for students
                        and staff with CapoBrain's ledger management feature,
                        ensuring transparency and accuracy in accounting
                        processes for effective financial oversight.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/remainders-for-fee-collection"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="far fa-bell fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Reminders For Fee Collection
                      </h3>
                      <p className="mb-4">
                        Never miss a payment deadline with Capobrain's fee
                        collection reminder feature, ensuring timely
                        notifications for students and parents to stay on top of
                        financial obligations.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/online-paper-generation"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-chart-pie fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Expense & Assets Management
                      </h3>
                      <p className="mb-4">
                        Efficiently track expenses and manage assets with
                        CapoBrain's comprehensive management tools, providing
                        insights and control over financial resources for
                        optimized school operations and budgeting.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/online-paper-generation"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="far fa-file-alt fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Online Paper Generation
                      </h3>
                      <p className="mb-4">
                        Generate exams and assignments effortlessly with
                        CapoBrain's online paper generation feature, enabling
                        teachers to create, customize, and distribute digital
                        assessments with ease for enhanced academic efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/exams-and-results-management"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-clipboard-list fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Exams & Results Management
                      </h3>
                      <p className="mb-4">
                        Streamline the examination process and manage results
                        effectively with CapoBrain's comprehensive exams and
                        results management module, ensuring smooth
                        administration and timely reporting for academic
                        success.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/reporting"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-chart-bar fa-3x mb-4 "></i>
                      <h3
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Comprehensive Reporting
                      </h3>
                      <p className="mb-4">
                        Gain valuable insights with CapoBrain's comprehensive
                        reporting features, offering analytics and customizable
                        reports for informed decision-making and enhanced school
                        performance evaluation.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/multi-campuses-management"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-sitemap fa-3x mb-4 "></i>
                      <p
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        Multi Campuses Management
                      </p>
                      <p className="mb-4">
                        Effortlessly manage multiple branches or campuses with
                        CapoBrain's integrated system, enabling centralized
                        administration and coordination across diverse locations
                        for seamless educational governance.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <Link
                to="/features/school-front-website"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="services-item3 bg-light">
                  <div className="p-2 text-center services-content">
                    <div className="services-content-icon3">
                      <i className="fas fa-globe fa-3x mb-4 "></i>
                      <p
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="mb-3 seo-heading-h3"
                      >
                        School/ College Front Website
                      </p>
                      <p className="mb-4">
                        Showcase your school or college with CapoBrain's
                        customizable front website feature, highlighting
                        important information, events, and achievements for a
                        professional online presence that engages your
                        community.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services End --> */}

      {/* contact start */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Learn more about our Advanced School Management Software for
                    Enhanced Education Management!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
