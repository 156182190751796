import React from 'react'
import { Helmet } from 'react-helmet';

export default function Studentreport() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Students Report - CapoBrain" />
        <meta property="og:description" content="The Students Report module enables efficient tracking and retrieval of relevant student records, allowing users to generate and print reports for enhanced administrative capabilities." />
        <meta property="og:image" content="https://capobrain.com/img/Students-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/students-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Students Report - CapoBrain" />
        <meta name="twitter:description" content="The Students Report module enables efficient tracking and retrieval of relevant student records, allowing users to generate and print reports for enhanced administrative capabilities." />
        <meta name="twitter:image" content="https://capobrain.com/img/Students-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/students-report",
            "name": "CapoBrain",
            "description": "The Students Report module enables efficient tracking and retrieval of relevant student records, allowing users to generate and print reports for enhanced administrative capabilities.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/students-report" />
        <meta name="description" content="The Students Report module enables efficient tracking and retrieval of relevant student records, allowing users to generate and print reports for enhanced administrative capabilities." />
      </Helmet>

      <h2 className='manual-h1'>Students Report</h2>
      <p>The <strong>Students Report</strong> module provides comprehensive data accessibility, enabling users to filter and view specific student information by branch and class. This functionality enhances the efficiency of tracking and retrieving relevant student records, and allows users to generate and print detailed reports, thereby improving the school's administrative and record-keeping capabilities.</p>
      <img src="/img/Students-Report.avif" className='img-fluid' alt="Students Report" />
    </div>
  );
}

