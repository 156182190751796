import React from 'react'
import { Helmet } from 'react-helmet';
export default function Studentatt2() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Student Attendance - CapoBrain" />
        <meta property="og:description" content="Access a comprehensive view of student attendance records, filtered by specific dates, classes, sections, and individual student names." />
        <meta property="og:image" content="https://capobrain.com/img/Student-Attendance.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/student-attendnce" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Attendance - CapoBrain" />
        <meta name="twitter:description" content="Access a comprehensive view of student attendance records, filtered by specific dates, classes, sections, and individual student names." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Attendance.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/student-attendnce",
            "name": "CapoBrain",
            "description": "Access a comprehensive view of student attendance records, filtered by specific dates, classes, sections, and individual student names.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/student-attendnce" />
        <meta name="description" content="Access a comprehensive view of student attendance records, filtered by specific dates, classes, sections, and individual student names." />
      </Helmet>

      <h2 className='manual-h1'>Student Attendance</h2>
      <p>The <strong>Student Attendance</strong> reporting module allows users to access a comprehensive view of student attendance records. Users can filter data by specific dates, classes, sections, and individual student names. This module provides a convenient way to track and monitor attendance, enabling the generation of printable reports for further analysis and record-keeping. It displays essential information such as branch name, student ID, name, class details, attendance status, and date.</p>
      <img src="/img/Student-Attendance.avif" className='img-fluid' alt="Student Attendance" />
    </div>
  );
}

