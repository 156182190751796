import React from 'react'
import { Helmet } from 'react-helmet';

export default function Config() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Configure Certificates in CapoBrain" />
        <meta property="og:description" content="Effortlessly configure certificates for students and staff in CapoBrain. Add titles like 'Student of the Month' or 'Teacher of the Month' to recognize achievements." />
        <meta property="og:image" content="https://capobrain.com/img/Configure-Certificate.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-certificate" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Configure Certificates in CapoBrain" />
        <meta name="twitter:description" content="Effortlessly configure certificates for students and staff in CapoBrain. Add titles like 'Student of the Month' or 'Teacher of the Month' to recognize achievements." />
        <meta name="twitter:image" content="https://capobrain.com/img/Configure-Certificate.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/configure-certificate",
            "name": "CapoBrain",
            "description": "Effortlessly configure certificates for students and staff in CapoBrain. Add titles like 'Student of the Month' or 'Teacher of the Month' to recognize achievements.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-certificate" />
        <meta name="description" content="Effortlessly configure certificates for students and staff in CapoBrain. Add titles like 'Student of the Month' or 'Teacher of the Month' to recognize achievements." />
      </Helmet>

      <h2 className='manual-h1'>Configure Certificate</h2>
      <p>This module enables you to configure certificates for both students and staff in CapoBrain. You can select the type (student or staff) and enter a title, such as "Student of the Month" or "Teacher of the Month," along with the reason for the certificate. Recognizing achievements with customized certificates enhances motivation and school spirit.</p>
      <img src="/img/Configure-Certificate.avif" className='img-fluid' alt="Configure Certificate" />
    </div>
  )
}

