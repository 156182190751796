import React from 'react'
import { Helmet } from 'react-helmet';

export default function Supplieracc() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Supplier Account - CapoBrain" />
        <meta property="og:description" content="Supplier accounts provide a comprehensive overview of all inventory purchases, including payable amounts, paid amounts, and remaining balances." />
        <meta property="og:image" content="https://capobrain.com/img/Supplier-Account-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/supplier-account" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Supplier Account - CapoBrain" />
        <meta name="twitter:description" content="Supplier accounts provide a comprehensive overview of all inventory purchases, including payable amounts, paid amounts, and remaining balances." />
        <meta name="twitter:image" content="https://capobrain.com/img/Supplier-Account-Detail.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/supplier-account",
            "name": "CapoBrain",
            "description": "Supplier accounts provide a comprehensive overview of all inventory purchases, including payable amounts, paid amounts, and remaining balances.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/supplier-account" />
        <meta name="description" content="Supplier accounts provide a comprehensive overview of all inventory purchases, including payable amounts, paid amounts, and remaining balances." />
      </Helmet>

      <h2 className='manual-h1'>Supplier Account</h2>
      <p>Supplier accounts are essential for tracking inventory purchases. They display all transactions, including payable amounts, paid amounts, and remaining balances, along with relevant supplier information. Suppliers can also add payments at any time, ensuring the account reflects real-time data.</p>
      <img src="/img/Supplier-Account-Detail.avif" className='img-fluid' alt="Supplier Account Detail" />
      <img src="/img/Add-Supplire-Payment.png" className='img-fluid mt-3' alt="Add Supplier Payment" />
    </div>
  )
}

