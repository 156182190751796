import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function SchoolManagementMobileApp() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>School Management Mobile App | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain’s School Management Mobile App allows students, teachers, and administrators to access essential features, updates, and information from anywhere with a mobile-friendly interface."
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="School Management Mobile App"
        />
        <meta
          property="og:description"
          content="Stay connected with CapoBrain's mobile app. Access key school management features on the go, ensuring a seamless experience for students and educators alike."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/school-management-mobile-app"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="School Management Mobile App"
        />
        <meta
          name="twitter:description"
          content="Access essential school management features and updates with CapoBrain’s mobile app. Convenient for students, teachers, and administrators on the go."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/school-management-mobile-app"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  CapoBrain School Management Mobile App
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Mobile App for School Management"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">
          Manage Your School On the Go with Our Mobile App
        </h1>
        <p>
          The CapoBrain School Management Mobile App provides students, teachers, and administrators with seamless access to essential features and updates, anytime, anywhere. Whether you're managing attendance, communicating with parents, or checking class schedules, everything is available at your fingertips.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Instant access to important school updates and notifications</li>
          <li>Real-time communication between teachers, students, and parents</li>
          <li>View and manage schedules, attendance, and grades</li>
          <li>Mobile-friendly design for easy navigation</li>
          <li>Seamless integration with CapoBrain’s school management system</li>
        </ul>

        <h2>Why Choose Our School Management Mobile App?</h2>
        <p>
          CapoBrain’s mobile app offers unmatched convenience for modern schools. It allows staff and students to stay connected and informed even when they're not on campus. With secure access and easy-to-use features, managing school operations has never been simpler.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Ready to Take School Management Mobile?
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
