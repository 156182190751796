import React from "react";
import { Helmet } from 'react-helmet';

export default function Visitors() {
  return (
    <div className="container">
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Visitors Meeting Management" />
        <meta property="og:description" content="Manage visitors meetings with staff or students. Ensure all mandatory fields are filled for a successful meeting entry." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Visitors-Meeting.jpg" />
        <meta property="og:url" content="https://capobrain.com/user-manual/visitors-meeting" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Visitors Meeting Management" />
        <meta name="twitter:description" content="Manage visitors meetings with staff or students. Ensure all mandatory fields are filled for a successful meeting entry." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Visitors-Meeting.jpg" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/visitors-meeting",
            "name": "Capobrain",
            "description": "In the visitors meeting module, manage meetings with staff or students efficiently, ensuring all mandatory fields are filled.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/visitors-meeting" />
        <meta name="description" content="Manage visitors meetings with staff or students effectively. All mandatory fields must be completed for successful entry." />
      </Helmet>

      <h2 className='manual-h1'>Visitors Meeting</h2>
      <p>
        In the Visitors Meeting module, you have the option to schedule meetings with either staff or students. To successfully add a meeting, you must first ensure that the respective staff or student has been added through their respective modules (Teaching Staff for staff, and New Admission for students). When you add a teacher or student, you will receive an ID that you can use to search for them in this module.
      </p>
      <p>
        Before you add a meeting, you need to configure the meeting purpose in the Configure Front Office submodule. Fill out all mandatory fields, which are indicated by a red star next to the field names. Non-mandatory fields are optional and can be skipped. If any mandatory fields are left empty, an alert will notify you of the issue. For instance, both the student name and meeting purpose fields are required for meetings involving students.
      </p>

      <ul>
        <li>Add a Teacher or Student First</li>
        <li>Configure the meeting purpose from the Configure Front Office submodule</li>
        <li>Complete all mandatory fields</li>
        <li>Non-mandatory fields are optional</li>
      </ul>

      <img src="/img/Add-Visitors-Meeting.avif" className="img-fluid" alt="Adding a Visitors Meeting" />
    </div>
  );
}

