import React from 'react'
import { Helmet } from 'react-helmet';

export default function PrintVoucher() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Print Voucher - CapoBrain" />
        <meta property="og:description" content="Easily print vouchers by selecting the session, year, branch, class, section, and student. Customize your selections for precise voucher printing." />
        <meta property="og:image" content="https://capobrain.com/img/Print-Vouchers.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/print-vouchers" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Print Voucher - CapoBrain" />
        <meta name="twitter:description" content="Easily print vouchers by selecting the session, year, branch, class, section, and student. Customize your selections for precise voucher printing." />
        <meta name="twitter:image" content="https://capobrain.com/img/Print-Vouchers.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/print-vouchers",
            "name": "CapoBrain",
            "description": "Easily print vouchers by selecting the session, year, branch, class, section, and student. Customize your selections for precise voucher printing.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/print-vouchers" />
        <meta name="description" content="Easily print vouchers by selecting the session, year, branch, class, section, and student. Customize your selections for precise voucher printing." />
      </Helmet>

      <h2 className='manual-h1'>Print Voucher</h2>
      <p>To print vouchers, you need to select specific options. Start by choosing the academic session, which will then display the corresponding year. After selecting the year, you will see a list of months. Next, select the branch, class, section, and student. Finally, click the print button to generate the vouchers based on your selections.</p>
      <img src="/img/Print-Vouchers.avif" className='img-fluid' alt="Print Voucher" />
    </div>
  )
}
