import React from 'react'
import { Helmet } from 'react-helmet';

export default function Dispatch() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Postal Dispatch Management - E-Learning Platform" />
        <meta property="og:description" content="Manage all postal dispatch details, including title, reference number, address, and date. All fields are mandatory for efficient tracking." />
        <meta property="og:image" content="https://capobrain.com/img/dash.jpg" />
        <meta property="og:url" content="https://capobrain.com/user-manual/postal-dispatch" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Postal Dispatch Management - Efficient Tracking" />
        <meta name="twitter:description" content="In the postal dispatch module, enter mandatory details such as title, reference number, address, and date for effective dispatch management." />
        <meta name="twitter:image" content="https://capobrain.com/img/dash.jpg" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/postal-dispatch",
            "name": "Capobrain",
            "description": "Manage postal dispatches efficiently by entering all mandatory details including title, reference number, address, and date." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/postal-dispatch" />
        <meta name="description" content="Efficiently manage postal dispatch details such as title, reference number, address, and date. All fields are mandatory." />
      </Helmet>

      <h2 className='manual-h1'>Postal Dispatch Management</h2>
      <p>In the postal dispatch module, you can efficiently enter all necessary postal dispatch details, including the title, reference number, address, and date. All fields are mandatory to ensure accurate tracking. This module allows you to add new postal dispatches and view the complete list, making it easy to update any existing details as needed.</p>
      <img src="/img/Add-Postal-Dispatch.avif" className='img-fluid' alt="Add Postal Dispatch" />
    </div>
  );
}

