import React from 'react'
import { Helmet } from 'react-helmet';

export default function Configpapers() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Paper Configuration Module" />
        <meta property="og:description" content="Add exam papers for classes with existing examinations. Ensure proper setup of exams and subjects before proceeding." />
        <meta property="og:image" content="https://capobrain.com/img/Configure-Paper.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-papers" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Paper Configuration Module" />
        <meta name="twitter:description" content="Add exam papers for classes with existing examinations. Ensure proper setup of exams and subjects before proceeding." />
        <meta name="twitter:image" content="https://capobrain.com/img/Configure-Paper.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/configure-papers",
            "name": "CapoBrain",
            "description": "Add exam papers for classes with existing examinations. Ensure proper setup of exams and subjects before proceeding.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-papers" />
        <meta name="description" content="Add exam papers for classes with existing examinations. Ensure proper setup of exams and subjects before proceeding." />
      </Helmet>

      <h2 className='manual-h1'>Configure Papers</h2>
      <p>In this module, you will add papers for the classes associated with existing examinations. Before you can add any papers, ensure that you have first set up the exams and configured the subjects for the classes. When configuring papers, you will select the branch, exam type, class, section, subject, and the grades associated with that paper.</p>
      <img src="/img/Configure-Paper.avif" className='img-fluid' alt="Configure Papers" />
    </div>
  )
}
