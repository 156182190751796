import React from 'react'
import { Helmet } from 'react-helmet';

export default function Postalreport() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Postal Report" />
        <meta property="og:description" content="A postal report provides essential information about a specific postal item or package, including its reference number, description, dispatch date, and current status." />
        <meta property="og:image" content="https://capobrain.com/img/Postal-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/postal-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Postal Report" />
        <meta name="twitter:description" content="A postal report provides essential information about a specific postal item or package, including its reference number, description, dispatch date, and current status." />
        <meta name="twitter:image" content="https://capobrain.com/img/Postal-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/postal-report",
            "name": "Capobrain",
            "description": "A postal report provides essential information about a specific postal item or package, including its reference number, description, dispatch date, and current status.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/postal-report" />
        <meta name="description" content="A postal report provides essential information about a specific postal item or package, including its reference number, description, dispatch date, and current status." />
      </Helmet>

      <h2 className='manual-h1'>Postal Report</h2>
      <p>A postal report provides essential information about a specific postal item or package. This report typically includes the reference number, title or description of the contents, dispatch date, and current postal status. The status may indicate whether the item has been delivered, is in transit, or facing any issues within the postal system. By summarizing the item's postal journey and status, this report enables both recipients and senders to efficiently track and manage their mail or packages.</p>
      <img src="/img/Postal-Report.avif" className='img-fluid' alt="Postal Report" />
    </div>
  );
}

