import React from 'react'
import { Helmet } from 'react-helmet';

export default function Updatefee() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Update Fee" />
        <meta property="og:description" content="In the Update Fee module, you can update a student's fee by searching for them using their ID. Enter the new monthly fee amount and select the effective date." />
        <meta property="og:image" content="https://capobrain.com/img/Update-Fee.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/update-fee" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Update Fee" />
        <meta name="twitter:description" content="In the Update Fee module, you can update a student's fee by searching for them using their ID. Enter the new monthly fee amount and select the effective date." />
        <meta name="twitter:image" content="https://capobrain.com/img/Update-Fee.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/update-fee",
            "name": "Capobrain",
            "description": "In the Update Fee module, you can update a student's fee by searching for them using their ID. Enter the new monthly fee amount and select the effective date.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/update-fee" />
        <meta name="description" content="In the Update Fee module, you can update a student's fee by searching for them using their ID. Enter the new monthly fee amount and select the effective date." />
      </Helmet>

      <h2 className='manual-h1'>Update Fee</h2>
      <p>In the Update Fee module, you can update a student's fee by searching for them using their ID. Enter the new monthly fee amount and select the effective date. This will ensure that the transaction is processed according to the specified date.</p>
      <img src="/img/Update-Fee.avif" className='img-fluid' alt="Update Fee" />
    </div>
  )
}
