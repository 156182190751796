import React from 'react'
import { Helmet } from 'react-helmet';

export default function Innventory() {
  return (
    <div className='container'>
<Helmet>
        {/* open grapgh tag */}
        <meta property="og:title" content="Inventory Management - CapoBrain"  />
        <meta property="og:description" content="Learn how to manage your inventory with CapoBrain. Enter the name, cost, and sale price of inventory items and view the complete inventory list." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Inventory.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/inventory" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Inventory Management - CapoBrain" />
        <meta name="twitter:description" content="Learn how to manage your inventory with CapoBrain. Enter the name, cost, and sale price of inventory items and view the complete inventory list." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Inventory.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/inventory",
            "name": "Capobrain",
            "description": "Learn how to manage your inventory with CapoBrain. Enter the name, cost, and sale price of inventory items and view the complete inventory list." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/inventory" />
        <meta name="description" content="CapoBrain's inventory management module allows you to enter inventory names, costs, sale prices, and access a complete list of all inventory items." />
      </Helmet>

      <h2 className='manual-h1'>Inventory</h2>
      <p> In this module of CapoBrain, you can efficiently manage your inventory by entering the name, cost, and sale price of each item. This feature allows you to maintain an organized inventory record and easily access a complete list of all inventory items provided. 
      Streamline your inventory management and enhance your educational resources with CapoBrain's intuitive interface.</p>
      <img src="/img/Add-Inventory.avif" className='img-fluid' alt="Add-Inventory" />
    </div>
  )
}
