import React, { useContext } from "react";
import { Link } from "react-router-dom";
import MyContext from "../ContextApi/MyContext";

export default function Blog() {
  const { filterPosts, posts, uniqueCategory, setCategory } =
    useContext(MyContext);

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center px-5">
              <div className="col-md-9">
                <div>
                  <div className="py-6">
                    <div>
                      <h1 className="text-center animate__animated animate__zoomIn">
                        CapoBrain Blog: Expert Insights on School Management
                        Software
                      </h1>
                      <p>
                        {" "}
                        Welcome to the CapoBrain blog! Explore a rich collection
                        of articles, tips, and in-depth stories about the latest
                        trends and innovations in School Management Software,
                        Student Information Systems (SIS), and Education ERP
                        Solutions. Whether you're looking for expert advice on
                        school software implementation, industry insights on
                        education technology in Pakistan, or best practices for
                        managing school operations, our blog has it all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-md-3 py-3">
          <div>
            <div className="outlet">
              {posts &&
                [...uniqueCategory].map((category) => {
                  return (
                    <div className="mt-2" style={{paddingRight: "15px" ,paddingLeft: "15px" }}>
                      <button
                        className="round-and-back w-100"
                        // style={{ border: "1px solid #133D78" }}
                        onClick={() => setCategory(category)}
                      >
                        <svg
                          width="400px"
                          height="60px"
                          viewBox="0 0 180 60"
                          className="border w-100"
                        >
                          <polyline
                            points="179,1 179,59 1,59 1,1 179,1"
                            className="bg-line w-100"
                          />
                          <polyline
                            points="179,1 179,59 1,59 1,1 179,1"
                            className="hl-line w-100"
                          />
                        </svg>
                        <span className="ms-2 text-start">{category}</span>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-md-9 py-3">
          <div className="container">
            <div className="row">
              <p
                style={{
                  backgroundColor: "rgb(227, 234, 247)",
                  padding: "7px",
                  display: "inline-block",
                }}
              >
                {" "}
                <strong> Note </strong>: Get insights about the tech world in
                English and Urdu languages at{" "}
                <a
                  href="https://urdustem.com/"
                  style={{ textDecoration: "underline" }}
                  target="blank"
                >
                  {" "}
                  Urdu Stem
                </a>
                .
              </p>
              {filterPosts.map((post) => {
                return (
                  <div className="col-md-4 col-12 mt-3">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/blog/${post.slug}`}
                    >
                      <div className="card">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ fontWeight: "normal", color: "initial" }}
                          >
                            {post.title.length > 40
                              ? post.title.slice(0, 40) + "..."
                              : post.title}
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
