import React from "react";
import { Helmet } from 'react-helmet';

export default function Teaching() {
  return (
    <div className="container">
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Teaching Staff - Best School Management Software" />
        <meta property="og:description" content="In our School Management System (SMS), there is a form for teachers to fill out, allowing the addition of personal details, academic information, work experience, and talents." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Teacher.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/teaching-staff" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Teaching Staff - School Management System" />
        <meta name="twitter:description" content="In our School Management System, teachers can fill out a form to add personal details, academic information, work experience, and talents." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Teacher.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://capobrain.com/user-manual/teaching-staff",
            "name": "Teaching Staff",
            "description": "In our School Management System, teachers can fill out a form for personal details, academic qualifications, work experience, and talents.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/teaching-staff" />
        <meta name="description" content="In our School Management System, teachers can add personal details, academic qualifications, work experience, and talents through a user-friendly form." />
      </Helmet>

      <h2 className='manual-h1'>Teaching Staff</h2>
      <p>
        In our School Management System (SMS), there is a form for teachers to fill out, allowing them to add personal details, academic qualifications, work experience, and talents. You must first add Nationality, Religion, and Designation to the configuration module. You can enter all of the teacher's information step by step, and in the final step, you will select which class the teacher has applied for. Upon submitting, the system will generate a unique Teacher ID for each teacher, which they can use to access their portal.
      </p>
      <ul>
        <li>
          First, add Nationality, Religion, and Designation to the configuration module.
        </li>
        <li>Image size should be less than or equal to 100 KB.</li>
        <li>
          The file size required in the instructor document area is less than or equal to 300 KB.
        </li>
      </ul>
      <img src="/img/Add-Teacher.avif" className="img-fluid" alt="Add-Teacher" />
    </div>
  );
}

