import React from 'react'
import { Helmet } from 'react-helmet';

export default function Configtopic() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Configure Topic - School Management System" />
        <meta property="og:description" content="The Configure Topic module allows users to modify topic names related to chapters in the School Management System's paper generator. Filter and utilize data effectively for academic purposes." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Topic-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/configure-topic" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Configure Topic - Best School Management Software" />
        <meta name="twitter:description" content="Modify topic names in the School Management System's paper generator. This module helps streamline your academic data management." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Topic-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/configure-topic",
            "name": "Capobrain",
            "description": "The Configure Topic module in the School Management System allows for modifying topic names from chapters to enhance academic data management." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/configure-topic" />
        <meta name="description" content="The Configure Topic module in the School Management System allows users to modify topic names from chapters for better academic data management." />
      </Helmet>

      <h2 className='manual-h1'>Configure Topic</h2>
      <p>The Configure Topic module is a key part of the School Management System's paper generator. Here, you can change the topic name associated with each chapter. This helps streamline data utilization in academic papers generated from the system.</p>
      <img src="/img/Add-Topic-Detail.avif" className='img-fluid' alt="Add-Topic-Detail" />
    </div>
  )
}

