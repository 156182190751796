import React from 'react'
import { Helmet } from 'react-helmet';

export default function Lecture() {
  return (
    <div className='container'>
      <Helmet>
        {/* open graph tag */}
        <meta property="og:title" content="Lecture Management - E-Learning Platform" />
        <meta property="og:description" content="Manage lectures for your subjects in our LMS. Add lecture details to ensure students can access the content via their portal." />
        <meta property="og:image" content="https://capobrain.com/img/Add-Lecture-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/lecture" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Lecture Management - Best E-Learning Solution" />
        <meta name="twitter:description" content="Easily manage lectures for your subjects in our LMS. Add lecture details for student access through their portal." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-Lecture-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/lecture",
            "name": "Capobrain",
            "description": "Manage lectures effectively in our e-learning platform. Include lecture details for any subject to facilitate student access." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/lecture" />
        <meta name="description" content="Manage lectures in our LMS. Add detailed lecture information for subjects, making it easy for students to access through their portal." />
      </Helmet>

      <h2 className='manual-h1'>Lecture Management</h2>
      <p>Lectures are the first module of our Learning Management System (LMS). In this section, you will include detailed lecture information for any subject, allowing students to access them via their portal. You will select a branch, class, section, and subject before adding the lecture description. After that, you will be able to view, edit, and remove this lecture as needed.</p>
      <img src="/img/Add-Lecture-Detail.avif" className='img-fluid' alt="Add Lecture Detail" />
    </div>
  )
}
