import React from "react";
import { Helmet } from 'react-helmet';

export default function Onlineadmissions() {
  return (
    <div className="container">
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Online Admissions" />
        <meta property="og:description" content="This module displays a list of all students who applied online in the Online Admission submodule, part of our comprehensive School Management System (SMS). The online admission module receives all applications submitted through the website." />
        <meta property="og:image" content="https://capobrain.com/img/Online-Admission.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/online-admissions" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Online Admissions" />
        <meta name="twitter:description" content="This module displays a list of all students who applied online in the Online Admission submodule, part of our School ERP Software Solutions." />
        <meta name="twitter:image" content="https://capobrain.com/img/Online-Admission.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/online-admissions",
            "name": "Capobrain",
            "description": "This module displays a list of all students who applied online in the Online Admission submodule, part of our School Management System (SMS). The online admission module receives all applications submitted through the website." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/online-admissions" />
        <meta name="description" content="This module displays a list of all students who applied online in the Online Admission submodule, part of our School Management System (SMS)." />
      </Helmet>

      <h2 className='manual-h1'>Online Admissions</h2>
      <p>
        This module displays a list of all students who applied online in the
        Online Admission submodule of our School Management System (SMS). The online admission submodule can receive all applications submitted through the website, making it an essential part of our School ERP Software Solutions. Each school can develop their own website by adding content such as About, Gallery, Events, and more. When you navigate to the admissions section, prospective students can apply for admission, which will be recorded in the online complaints list submodule. Users can easily enter some basic information on the website, and upon viewing the list, there is an edit option available. By clicking on it, the appropriate admission form is displayed, allowing for seamless integration of students into the student list.
      </p>
      <img src="/img/Online-Admission.avif" className="img-fluid" alt="Online-Admission" />
    </div>
  );
}
