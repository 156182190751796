import React from 'react'
import { Helmet } from 'react-helmet';

export default function Passout() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tag */}
        <meta property="og:title" content="Passout Students" />
        <meta property="og:description" content="If you want to enter the data of passout students, you may do so by searching the student by ID and then entering the reason for passout. After you withdraw that student, their data will be recorded in the School Management System (SMS) and displayed in the list of passout students." />
        <meta property="og:image" content="https://capobrain.com/img/passout-students" />
        <meta property="og:url" content="https://capobrain.com/user-manual/passout-students" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Passout Students" />
        <meta name="twitter:description" content="If you want to enter the data of passout students, you may do so by searching the student by ID and then entering the reason for passout. After you withdraw that student, their data will be recorded in the School Management System (SMS)." />
        <meta name="twitter:image" content="https://capobrain.com/img/passout-students" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/passout-students",
            "name": "Capobrain",
            "description": "If you want to enter the data of passout students, you may do so by searching the student by ID and then entering the reason for passout. After you withdraw that student, their data will be recorded in the School Management System (SMS)." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/passout-students" />
        <meta name="description" content="If you want to enter the data of passout students, you may do so by searching the student by ID and then entering the reason for passout. After you withdraw that student, their data will be recorded in the School Management System (SMS)." />
      </Helmet>

      <h2 className='manual-h1'>Passout Students</h2>
      <p>
        If you want to enter the data of passout students in the School Management System (SMS), you may do so by searching the student by ID and then entering the reason for passout. After you withdraw that student, their data will be removed from the main student list and displayed in the passout students list, helping to maintain an accurate record of student status.
      </p>
      <img src="/img/Add-passout-Student.avif" className='img-fluid' alt="Add-Passout-Student" />
    </div>
  );
}

