import { Link } from "react-router-dom"; // Ensure you have this import for the Link component
import { Helmet } from "react-helmet"; // Ensure you have this import for Helmet
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function ComputerBasedExam() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Computer-Based Exam Management | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain’s computer-based exam management system streamlines school assessments with automated grading and instant results, ensuring efficiency and accuracy in school exams."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Computer-Based Exam Management" />
        <meta
          property="og:description"
          content="Enhance the efficiency of school assessments with CapoBrain’s computer-based exam management. Automated grading and instant results ensure a seamless experience for teachers and students."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/computer-based-exam-management"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Computer-Based Exam Management" />
        <meta
          name="twitter:description"
          content="CapoBrain’s computer-based exam solutions provide automated grading and instant results, streamlining school assessments and reporting."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/computer-based-exam-management"
        />
      </Helmet>

      {/* Top Section */}
        <div className="home-container">
          <div className="home-container-overlay">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between px-5">
                <div className="col-md-6">
                  <div>
                    <div className="">
                      <div>
                        <h1 className="animate__animated animate__zoomIn">
                        Comprehensive Features of Computer-Based Exam Management Software
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <img
                    src={headerImg}
                    alt="header-img"
                    className="img-fluid"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="container services sm:mt-5 py-5 mb-2">

        <h1 className="feature-title">Computer-Based Exam Management</h1>
        <p>
          CapoBrain's computer-based exam management software offers schools an
          efficient, accurate, and user-friendly way to conduct assessments.
          With automated grading and instant results, educators can focus on
          delivering quality education rather than managing paper-based exams.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Automated grading for faster result generation</li>
          <li>Instant access to exam results and feedback</li>
          <li>Improved accuracy and fairness in assessment</li>
          <li>Reduces administrative workload for teachers</li>
          <li>Seamless integration with other school management systems</li>
        </ul>

        <h2>Why Choose Computer-Based Exam Management?</h2>
        <p>
          Computer-based exams simplify the assessment process, providing
          instant feedback and reducing the chance for errors. Schools can
          ensure more efficient administration of exams while students benefit
          from quicker result processing.
        </p>
      </div>
      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Learn more about our Advanced School Management Software for
                    Enhanced Education Management!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
