import React from "react";
import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import whatsappHeaderImg from "../img/header-img.webp"; 

export default function WhatsAppMessaging() {
  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>WhatsApp Messaging | CapoBrain School Management</title>
        <meta
          name="description"
          content="Enhance school communication with CapoBrain's WhatsApp Messaging feature. Send instant updates to students, parents, and staff for real-time engagement."
        />
        <meta property="og:title" content="WhatsApp Messaging for Schools" />
        <meta
          property="og:description"
          content="Effortlessly communicate with students, parents, and staff through CapoBrain's WhatsApp Messaging integration for enhanced school communication."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/whatsapp-messaging"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="WhatsApp Messaging for School Communication"
        />
        <meta
          name="twitter:description"
          content="Instant messaging to students, parents, and staff through CapoBrain's WhatsApp integration."
        />
        <link
          rel="canonical"
          href="https://capobrain.com/features/whatsapp-messaging"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  CapoBrain WhatsApp Messaging
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={whatsappHeaderImg}
                  alt="WhatsApp Messaging for School Communication"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services mb-5">
        <h1 className="feature-title">
          Effortless School Communication with WhatsApp Messaging
        </h1>
        <p>
          With CapoBrain’s WhatsApp Messaging feature, schools can instantly
          connect with students, parents, and staff. This feature streamlines
          communication, allowing quick updates, notifications, and essential
          information to be shared in real-time.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Instant communication for announcements and updates</li>
          <li>Group messaging for specific classes or teams</li>
          <li>Automated alerts for attendance, fees, and events</li>
          <li>Personalized messages to parents and staff</li>
          <li>Improved engagement with real-time responses</li>
        </ul>

        <h2>How It Works</h2>
        <p>
          CapoBrain’s WhatsApp Messaging feature integrates seamlessly with your
          school’s communication needs. Whether you're sending updates about
          upcoming events, attendance alerts, or personalized messages, this
          feature ensures that everyone stays informed and connected.
        </p>
      </div>

      {/* Call-to-Action Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Ready to Improve School Communication with WhatsApp?
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">Get in Touch Today!</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
