import React from 'react'
import { Helmet } from 'react-helmet';

export default function Studentrecieve() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Student Receivables - CapoBrain" />
        <meta property="og:description" content="The Student Accounts Receivable department is responsible for managing student fees and collecting payments, ensuring timely recovery of overdue amounts." />
        <meta property="og:image" content="https://capobrain.com/img/Student-Recieveable.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/student-recieveables" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Student Receivables - CapoBrain" />
        <meta name="twitter:description" content="The Student Accounts Receivable department is responsible for managing student fees and collecting payments, ensuring timely recovery of overdue amounts." />
        <meta name="twitter:image" content="https://capobrain.com/img/Student-Recieveable.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/student-recieveables",
            "name": "CapoBrain",
            "description": "The Student Accounts Receivable department is responsible for managing student fees and collecting payments, ensuring timely recovery of overdue amounts.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/student-recieveables" />
        <meta name="description" content="The Student Accounts Receivable department is responsible for managing student fees and collecting payments, ensuring timely recovery of overdue amounts." />
      </Helmet>

      <h2 className='manual-h1'>Student Receivables</h2>
      <p>The <strong>Student Accounts Receivable</strong> department plays a pivotal role in managing student fees and expenses. Their responsibilities include assessing and collecting payments accurately and promptly, as well as recovering overdue amounts. This report provides a comprehensive overview that includes details such as student names, receivable payments, class information, student particulars, fee breakdowns, inventory charges, transportation fees, arrears, and balances. This structured information streamlines financial tracking and helps maintain accurate and efficient financial records for the institution.</p>
      <img src="/img/Student-Recieveable.avif" className='img-fluid' alt="Student Receivables" />
    </div>
  );
}

