import React from 'react'
import { Helmet } from 'react-helmet';

export default function Leaverep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Leave Report" />
        <meta property="og:description" content="The leave report summarizes an employee's use of leave or vacation time. This report is essential for administrators to track time off for various reasons including vacations, sick days, and personal leave." />
        <meta property="og:image" content="https://capobrain.com/img/Leave-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/leave-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Leave Report" />
        <meta name="twitter:description" content="The leave report summarizes an employee's use of leave or vacation time. This report is essential for administrators to track time off for various reasons including vacations, sick days, and personal leave." />
        <meta name="twitter:image" content="https://capobrain.com/img/Leave-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/leave-report",
            "name": "Capobrain",
            "description": "The leave report summarizes an employee's use of leave or vacation time. This report is essential for administrators to track time off for various reasons including vacations, sick days, and personal leave.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/leave-report" />
        <meta name="description" content="The leave report summarizes an employee's use of leave or vacation time. This report is essential for administrators to track time off for various reasons including vacations, sick days, and personal leave." />
      </Helmet>

      <h2 className='manual-h1'>Leave Report</h2>
      <p>The "leave report" is a crucial document that summarizes an employee's use of leave or vacation time. This report assists administrators in tracking time off for various reasons such as vacations, sick days, personal leave, or other leave types provided by the organization. The report displays leave records for both students and employees, including important details like the date, reason for leave, and status (approved or rejected). Student records will be organized in designated student columns, while staff records will be in staff columns.</p>
      <img src="/img/Leave-Report.avif" className='img-fluid' alt="Leave Report" />
    </div>
  );
}

