import React from 'react'
import { Helmet } from 'react-helmet';

export default function Chat() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="School Communication Chat Module" />
        <meta property="og:description" content="The Chat module facilitates seamless communication between school users and parents, providing a dedicated space for messages and inquiries." />
        <meta property="og:image" content="https://capobrain.com/img/Chats.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/chat" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="School Communication Chat Module" />
        <meta name="twitter:description" content="The Chat module facilitates seamless communication between school users and parents, providing a dedicated space for messages and inquiries." />
        <meta name="twitter:image" content="https://capobrain.com/img/Chats.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/chat",
            "name": "CapoBrain",
            "description": "The Chat module facilitates seamless communication between school users and parents, providing a dedicated space for messages and inquiries.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/chat" />
        <meta name="description" content="The Chat module facilitates seamless communication between school users and parents, providing a dedicated space for messages and inquiries." />
      </Helmet>

      <h2 className='manual-h1'>Chat</h2>
      <p>The Chat module provides a dedicated platform for communication between school users and parents. This feature, accessible via the parent portal, allows parents to send messages directly to the school. Each message includes the parent's name, ensuring that the school can respond appropriately and maintain effective communication.</p>
      <img src="/img/Chats.avif" className='img-fluid' alt="Chat Interface" />
    </div>
  )
}

