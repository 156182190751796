import React, { useEffect } from "react";
import Testimonial from "./Testimonial";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import webDevelopment from "../img/about-img.webp";
import headerImg from "../img/header-img.webp";
import { Link } from "react-router-dom";

export default function About() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <div>
                  <div className="">
                    <div>
                      <h1 className="animate__animated animate__zoomIn">
                        Transforming Education Through The Leading School
                        Management System
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="container">
            <div className="mb-3" style={{ border: "none" }}>
              <div className="row justify-content-center g-0">
                <div className="col-md-10">
                  <div className="card-padding mt-3">
                    <p style={{ textAlign: "justify" }}>
                      {" "}
                      Introducing Capobrain, a leading school management system
                      (SMS) designed to streamline operations and enhance
                      efficiency for educational institutions. Whether you are
                      managing a bustling school or a sprawling university, our
                      school management system integrates all aspects of campus
                      life. From student admissions and attendance tracking to
                      staff management and curriculum planning, Capobrain
                      simplifies complex administrative tasks, making it the
                      ideal solution for modern educational environments.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      {" "}
                      Capobrain stands out as a comprehensive college management
                      system, offering unparalleled features tailored for higher
                      education. Our campus management system ensures seamless
                      coordination between departments, providing tools for
                      financial management, resource allocation, and academic
                      scheduling. By leveraging Capobrain's student information
                      system (SIS) and school ERP software solutions,
                      educational institutions can foster a more organized,
                      productive, and connected community, revolutionizing the
                      way schools and colleges operate.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3" style={{ border: "none" }}>
              <div className="row g-0 reverse-order">
                <div className="col-md-4">
                  <img
                    src={webDevelopment}
                    className="img-fluid rounded-start"
                    alt="school management software"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-padding">
                    <h2 className="">
                      Why Capobrain Excels in School Management Software?
                    </h2>
                    <p className="card-text" style={{ textAlign: "justify" }}>
                      Capobrain’s School Management System (SMS) is designed to
                      streamline every aspect of educational administration,
                      making it the top choice for schools and colleges. From
                      student enrollment to daily attendance tracking, academic
                      performance tracking to parent-teacher communication,
                      Capobrain handles it all with precision and efficiency.
                      Its user-friendly interface and advanced features, such as
                      School Fee Management System and Student Attendance
                      Software, simplify complex tasks, allowing educators to
                      focus more on teaching and less on administration. Whether
                      managing a small school or a large college, Capobrain
                      ensures that every process is seamlessly integrated,
                      leading to a more organized and productive educational
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row webFeatures d-flex justify-content-between">
              <div className="col-md-3 col-6 d-flex mt-3">
                <i className="fa-solid fa-school fa-2x me-2"></i>
                <p
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                  className="seo-heading-h3"
                >
                  Academic <br /> Excellence
                </p>
              </div>
              <div className="col-md-3 col-6 d-flex mt-3">
                <i className="fas fa-bullseye fa-2x me-2"></i>
                {/* <i className=""></i> */}
                <p
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                  className="seo-heading-h3"
                >
                  Al-powered <br /> Features
                </p>
              </div>
              <div className="col-md-3 col-6 d-flex mt-3">
                <i className="fa-regular fa-clock fa-2x me-2"></i>
                <p
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                  className="seo-heading-h3"
                >
                  24/7 <br /> Support
                </p>
              </div>
              <div className="col-md-2 col-6 d-flex mt-3">
                <i className="fa-solid fa-pencil fa-2x me-2"></i>
                <p
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                  className="seo-heading-h3"
                >
                  Regular <br /> Updates
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about2 elevateGreeing mt-5">
          <div className="elevateGreeing-overlay">
            <div className="container pt-4 pb-3">
              <h2 className="text-center">
                Technic Mentors IT Consulting Services
              </h2>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <p style={{ textAlign: "justify" }}>
                    Technic Mentors is the leading IT consulting company in
                    Gujranwala, providing exceptional software and technology
                    solutions. We specialize in delivering value-added IT
                    services and high-quality products. Our experienced team
                    offers a top-tier experience for clients, delivering IT
                    solutions for commercial enterprises, government agencies,
                    educational institutions, and schools. Our goal is to
                    enhance the learning and development ecosystem through
                    innovative technology.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="services">
                <div className="container">
                  <div
                    className="text-center"
                    style={{ maxWidth: "600px" }}
                  ></div>
                  <div className="row g-5 services-inner">
                    <div
                      className="col-md-6 col-lg-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Web Design & Development
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our web development services include both web
                              design and web development, ensuring your website
                              is not only visually appealing but also functional
                              and user-friendly.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/web-development-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Custom Software Development
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our software development services include
                              comprehensive business solutions tailored to meet
                              specific needs through effective design,
                              development, and testing processes.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/software-development-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Mobile App Development
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our mobile app development services are designed
                              to elevate your business, delivering essential
                              benefits in today's digital landscape where mobile
                              applications are vital.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/mobile-app-development-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4 mb-3"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Search Engine Optimization (SEO)
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our SEO services optimize your website to improve
                              its ranking on search engine result pages,
                              increasing visibility for products and services
                              relevant to your business.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/seo-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4 mb-3"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Digital Marketing Services
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our digital marketing services promote brands
                              through internet-based platforms and various
                              communication channels, effectively engaging
                              potential clients and driving business growth.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/digital-marketing-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4 mb-3"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <div className="services-item3 bg-light">
                        <div className="p-4 text-center services-content">
                          <div className="services-content-icon3">
                            <h3
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="seo-heading-h3 card-title mb-2"
                            >
                              Graphics Designing Services
                            </h3>
                            <p
                              className="card-text"
                              style={{ textAlign: "justify" }}
                            >
                              Our graphics designing services focus on creating
                              visual content that effectively communicates
                              messages through typography and graphics, tailored
                              to meet user needs.
                            </p>
                            <i className="fa-solid fa-arrow-right me-2"></i>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="https://technicmentors.com/graphics-designing-services"
                              target="blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ start */}
      <section id="faq" className="faq section-bg py-4">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="section-title">
            <h2 className="subtitle text-center">F.A.Q</h2>
            <p
              className="text-center"
              style={{ color: "initial", fontWeight: "bold" }}
            >
              {" "}
              Frequently Asked <span>Questions</span>{" "}
            </p>
            <p
              className="text-center"
              style={{ color: "initial", fontWeight: "bold" }}
            >
              Our curated list of the most frequently asked questions about our{" "}
              <strong>School & College Management Software</strong>.
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq19"
                    className="collapsed question text-start"
                  >
                    What is CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq19"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain is a comprehensive{" "}
                      <strong>College & School Management System</strong>{" "}
                      designed to streamline administrative tasks, enhance
                      reporting, and provide future predictions using Artificial
                      Intelligence features. It is an all-in-one solution for
                      both schools and colleges.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq20"
                    className="collapsed question text-start"
                  >
                    Which programming languages are used to develop CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq20"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      CapoBrain is developed using modern programming languages
                      to ensure efficiency, scalability, and reliability, making
                      it a top choice for educational institutions.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq21"
                    className="collapsed question text-start"
                  >
                    Is CapoBrain suitable for both schools and colleges?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq21"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain is designed to cater to the needs of both{" "}
                      <strong>schools</strong> and <strong>colleges</strong>,
                      offering tailored modules to fit each educational setting.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq22"
                    className="collapsed question text-start"
                  >
                    How does the Postal Dispatch feature work?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq22"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Postal Dispatch feature allows you to manage outgoing
                      mail, track deliveries, and maintain a record of
                      dispatched items, enhancing your{" "}
                      <strong>administrative efficiency</strong>.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq23"
                    className="collapsed question text-start"
                  >
                    Can I log phone calls using CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq23"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, CapoBrain's Phone Call Log feature enables you to
                      keep track of all incoming and outgoing phone calls
                      efficiently, further streamlining your{" "}
                      <strong>school management</strong> processes.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq24"
                    className="collapsed question text-start"
                  >
                    How can I add teaching and non-teaching staff to CapoBrain?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq24"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      You can easily add teaching and non-teaching staff
                      profiles using the Staff module and assign relevant roles
                      and responsibilities within the{" "}
                      <strong>CapoBrain software</strong>.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq25"
                    className="collapsed question text-start"
                  >
                    What is the Staff ToDo's feature used for?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq25"
                    className="collapse text-start"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The Staff ToDo's feature helps staff members manage their
                      tasks, set priorities, and track progress on various
                      assignments, making it an essential tool in our{" "}
                      <strong>school ERP software</strong>.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Link to="/faqs">
            <button className="btn btnFill" type="button">
              <i className="fa-solid fa-arrow-right me-2 moveIcon text-white"></i>{" "}
              View More
            </button>
          </Link>
        </div>
      </section>
      {/* FAQ end */}
      <Testimonial />
    </div>
  );
}
