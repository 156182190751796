import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function FeeCollectionReminders() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Reminders for Fee Collection | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain’s fee collection reminder feature ensures timely notifications for students and parents, helping to manage financial obligations and deadlines efficiently."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Reminders for Fee Collection" />
        <meta
          property="og:description"
          content="Never miss a payment deadline with CapoBrain's fee collection reminder feature, ensuring timely notifications for students and parents."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/reminders-for-fee-collection"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Reminders for Fee Collection | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Stay on top of financial obligations with timely notifications from CapoBrain’s fee collection reminder feature."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/reminders-for-fee-collection"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Fee Collection Management
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Reminders for Fee Collection</h1>
        <p>
          CapoBrain's fee collection reminder feature helps schools ensure timely notifications for students and parents, allowing them to stay on top of their financial obligations and avoid missed payment deadlines.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Automated payment reminders for students and parents</li>
          <li>Customizable notification settings for different payment deadlines</li>
          <li>Improved cash flow management with timely alerts</li>
          <li>Enhanced communication with parents regarding fee collection</li>
          <li>Integration with other financial management tools for streamlined processes</li>
        </ul>

        <h2>Why Choose CapoBrain's Fee Collection Reminders?</h2>
        <p>
          Timely payment notifications can significantly improve fee collection rates. CapoBrain’s reminder feature offers schools an efficient way to manage financial obligations, reducing delays and ensuring that parents and students are well-informed.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Streamline Fee Collection with CapoBrain's Efficient Reminder System
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
