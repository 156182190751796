import React from 'react'
import { Helmet } from 'react-helmet';

export default function Inventoryfeerep() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Inventory Fee Report" />
        <meta property="og:description" content="The inventory fee report is essential for tracking and managing expenses related to maintaining a stock of goods or products. It displays detailed student information such as name, student ID, father's name, class, section, and their associated fees." />
        <meta property="og:image" content="https://capobrain.com/img/Inventory-Fee-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/inventory-fee-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Inventory Fee Report" />
        <meta name="twitter:description" content="The inventory fee report is essential for tracking and managing expenses related to maintaining a stock of goods or products. It displays detailed student information such as name, student ID, father's name, class, section, and their associated fees." />
        <meta name="twitter:image" content="https://capobrain.com/img/Inventory-Fee-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/inventory-fee-report",
            "name": "Capobrain",
            "description": "The inventory fee report is essential for tracking and managing expenses related to maintaining a stock of goods or products. It displays detailed student information such as name, student ID, father's name, class, section, and their associated fees.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/inventory-fee-report" />
        <meta name="description" content="The inventory fee report is essential for tracking and managing expenses related to maintaining a stock of goods or products. It displays detailed student information such as name, student ID, father's name, class, section, and their associated fees." />
      </Helmet>

      <h2 className='manual-h1'>Inventory Fee Report</h2>
      <p>The "inventory fee report" provides essential insights into the fees associated with inventory management, detailing the costs of maintaining stock within an organization. This report is invaluable for tracking and managing expenses related to maintaining a stock of goods or products. The inventory fee report includes crucial student information such as name, student ID, father's name, class, section, along with monthly inventory fees and the paid amounts, enabling easy tracking of payments. You can search for records using any date or by name.</p>
      <img src="/img/Inventory-Fee-Report.avif" className='img-fluid' alt="Inventory Fee Report" />
    </div>
  );
}

