import React from 'react'
import { Helmet } from 'react-helmet';

export default function Issue() {
  return (
    <div className='container'>
<Helmet>
        {/* open grapgh tag */}
        <meta property="og:title" content="Issue Inventory - CapoBrain" />
        <meta property="og:description" content="Easily manage and issue inventory to students or staff with CapoBrain's School Management System. Track transactions and manage accounts seamlessly." />
        <meta property="og:image" content="https://capobrain.com/img/Issue-Inventory.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/issue-inventory" />
        <meta property="og:type" content="website" />

        {/* twitter card  */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Issue Inventory - CapoBrain" />
        <meta name="twitter:description" content="Learn how to efficiently issue inventory to students or staff within CapoBrain's education management platform. Ensure proper tracking and accounting for all issued items." />
        <meta name="twitter:image" content="https://capobrain.com/img/Issue-Inventory.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/issue-inventory",
            "name": "Capobrain",
            "description": "Learn how to manage and issue inventory to students and staff within CapoBrain’s School Management System." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/issue-inventory" />
        <meta name="description" content="Efficiently issue and manage inventory within CapoBrain's School Management System. Seamlessly track inventory for students and staff, ensuring accurate record-keeping." />
      </Helmet>

      <h2 className='manual-h1'>Issue Inventory</h2>
      <p>
        Within CapoBrain's <strong>School Management System</strong>, you can issue inventory either to students or staff. To issue inventory to a student, simply search for the student by ID. Once selected, the student's information will be auto-populated, and you can choose the inventory item to be provided. 
        The transaction is automatically added to the student's account, with invoices appearing in the invoice list. 
      </p>
      <p>
        For staff, you can define whether the inventory is <strong>chargeable</strong> or <strong>non-chargeable</strong>. Non-chargeable items won’t be recorded in the account, but charged items will be reflected with the appropriate cost. 
        CapoBrain ensures accurate inventory management and streamlined <strong>school inventory tracking</strong> for both students and teachers.
      </p>
      <img src="/img/Issue-Inventory.avif" className='img-fluid mt-5' alt="Issue-Inventory" />
    </div>
  )
}
