import React from 'react'
import { Helmet } from 'react-helmet';

export default function Teachersrepor() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="Staff Report - School Management System (SMS)" />
        <meta property="og:description" content="The Staff Report module in CapoBrain's School Management System enables schools to track, retrieve, and print staff records, enhancing administration and data management." />
        <meta property="og:image" content="https://capobrain.com/img/Staff-Report.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/staff-report" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Staff Report - School ERP Software Solutions" />
        <meta name="twitter:description" content="Use CapoBrain's School ERP to efficiently manage staff records and generate reports, helping schools streamline administration." />
        <meta name="twitter:image" content="https://capobrain.com/img/Staff-Report.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/staff-report",
            "name": "CapoBrain",
            "description": "The Staff Report module in CapoBrain's School Management System enables schools to track, retrieve, and print staff records, improving school administration and record-keeping." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/staff-report" />
        <meta name="description" content="CapoBrain's Staff Report functionality helps schools manage staff records efficiently, allowing for easy report generation and enhanced administrative capabilities." />
      </Helmet>

      <h2 className='manual-h1'>Staff Report - School Management Software (SMS)</h2>
      <p>
        The <strong>Staff Report</strong> module in CapoBrain’s <strong>School Management System (SMS)</strong> provides schools with comprehensive access to staff data. Users can filter staff information by email, contact, and class to efficiently track and retrieve relevant staff records.
      </p>
      <p>
        This feature enhances school administration by offering seamless record-keeping and the ability to generate and print detailed reports. It is part of CapoBrain’s <strong>School ERP Software Solutions</strong> and integrates with other modules for smooth operation.
      </p>
      <ul>
        <li>Part of CapoBrain’s <strong>School ERP System</strong></li>
        <li>Supports <strong>Customizable School ERP Software</strong> needs</li>
        <li>Key feature in <strong>School Software Solutions</strong> for small and large schools</li>
      </ul>
      <img src="/img/Staff-Report.avif" className='img-fluid' alt="Staff Report - CapoBrain School Software" />
    </div>
  );
}
