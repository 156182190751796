import React from 'react'
import { Helmet } from 'react-helmet';

export default function Uploadmaterial() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content="Upload Material - CapoBrain" />
        <meta property="og:description" content="Easily upload educational materials for any subject. Choose to send it to everyone or target specific classes, subjects, or teachers." />
        <meta property="og:image" content="https://capobrain.com/img/Upload-Material.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/upload-material" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="Upload Material - CapoBrain" />
        <meta name="twitter:description" content="Upload educational materials for any subject and choose whether to send them to everyone or specific classes, subjects, or teachers." />
        <meta name="twitter:image" content="https://capobrain.com/img/Upload-Material.png" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/upload-material",
            "name": "CapoBrain",
            "description": "Upload educational materials for any subject. Easily send them to everyone or specific classes, subjects, or teachers.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/upload-material" />
        <meta name="description" content="Easily upload educational materials for any subject and choose to send them to everyone or target specific classes, subjects, or teachers." />
      </Helmet>

      <h2 className='manual-h1'>Upload Material</h2>
      <p>This module allows school users to easily upload educational materials for any subject. Users can choose to send the materials to everyone or target specific classes, subjects, or teachers. Simply select a title, upload a file, set a date, and specify the recipients. As a result, everyone will have access to the content through their individual portals.</p>
      <img src="/img/Upload-Material.avif" className='img-fluid' alt="Upload Educational Material" />
    </div>
  )
}
