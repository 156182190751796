import React from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import { Helmet } from "react-helmet"; // Importing Helmet for SEO
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function StudentStaffLedgers() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Student & Staff Ledgers | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain’s ledger management system ensures accurate financial record keeping for students and staff, promoting transparency and control over school finances."
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Student & Staff Ledgers" />
        <meta
          property="og:description"
          content="CapoBrain's ledger management feature helps schools maintain comprehensive financial records for students and staff, ensuring financial transparency and accuracy."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/student-staff-ledgers"
        />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Student & Staff Ledgers | CapoBrain"
        />
        <meta
          name="twitter:description"
          content="Accurately manage financial records for both students and staff with CapoBrain’s comprehensive ledger management system."
        />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/student-staff-ledgers"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Financial Record Keeping for Students and Staff
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="header-img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Student & Staff Ledgers</h1>
        <p>
          CapoBrain's ledger management feature helps schools maintain comprehensive and accurate financial records for both students and staff. This system ensures transparency, accuracy, and control over school finances, facilitating efficient financial oversight.
        </p>

        <h2>Key Benefits</h2>
        <ul>
          <li>Accurate tracking of student fees and payments</li>
          <li>Staff salary management and record-keeping</li>
          <li>Transparent financial reporting for school administration</li>
          <li>Easy access to financial data for audits and reviews</li>
          <li>Seamless integration with other financial management tools</li>
        </ul>

        <h2>Why Choose CapoBrain's Ledger Management?</h2>
        <p>
          Managing school finances requires accuracy and transparency. CapoBrain’s ledger management system offers an efficient solution to track and maintain financial records for both students and staff, ensuring a clear view of the school’s financial health at all times.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Ensure Financial Transparency with CapoBrain’s Comprehensive Ledger Management System
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
