import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Importing Link for navigation
import headerImg from "../img/header-img.webp"; // Update this path to your header image

export default function StudentsAndParentPortals() {
  return (
    <div>
      <Helmet>
        {/* Meta Tags for SEO Optimization */}
        <title>Students & Parent Portals | CapoBrain</title>
        <meta
          name="description"
          content="CapoBrain offers dedicated portals for students and parents, providing easy access to academic records, schedules, and essential communication tools to enhance engagement."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Students & Parent Portals" />
        <meta
          property="og:description"
          content="Empower students and parents with CapoBrain's dedicated portals for enhanced engagement in academic activities."
        />
        <meta
          property="og:url"
          content="https://capobrain.com/features/students-parent-portals"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Students & Parent Portals"
        />
        <meta
          name="twitter:description"
          content="CapoBrain provides dedicated portals for students and parents, enhancing engagement through easy access to academic information."
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://capobrain.com/features/students-parent-portals"
        />
      </Helmet>

      {/* Top Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between px-5">
              <div className="col-md-6">
                <h1 className="animate__animated animate__zoomIn">
                  Comprehensive Features of Students & Parent Portals
                </h1>
              </div>
              <div className="col-md-5">
                <img
                  src={headerImg}
                  alt="Students and Parent Portals Header"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container services sm:mt-5 py-5 mb-2">
        <h1 className="feature-title">Students & Parent Portals</h1>
        <p>
          CapoBrain's dedicated portals empower students and parents by providing easy access to academic records, schedules, and essential communication tools. This enhances engagement and fosters a collaborative educational environment.
        </p>

        <h2>Key Benefits of Students & Parent Portals</h2>
        <ul>
          <li>Easy access to academic records and performance data</li>
          <li>Real-time notifications and updates</li>
          <li>Streamlined communication between parents, students, and teachers</li>
          <li>Convenient access to schedules and event information</li>
          <li>Encourages parental involvement in student education</li>
        </ul>

        <h2>Why Choose CapoBrain's Portals?</h2>
        <p>
          Our portals are designed to facilitate seamless communication and engagement among students, parents, and educators. By providing a centralized platform for information sharing, CapoBrain enhances the overall educational experience.
        </p>
      </div>

      {/* Bottom Section */}
      <div className="home-container">
        <div className="home-container-overlay">
          <div className="d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-between pt-5 pb-5 mt-3">
                <div className="col-md-8">
                  <h4
                    style={{
                      color: "initial",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    Discover the Best Solutions for Student and Parent Engagement!
                  </h4>
                </div>
                <div className="col-md-3 text-center">
                  <Link to="/contact">
                    <button className="btn btnFill">
                      Get In Touch With Us!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
