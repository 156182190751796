import React from 'react'
import { Helmet } from 'react-helmet';

export default function Aboutus() {
  return (
    <div className='container'>
      <Helmet>
        {/* Open Graph tags */}
        <meta property="og:title" content="About Us" />
        <meta property="og:description" content="This module allows you to manage the About Us page, including the title, content image, banner, and description. Add and save your desired details to update the page." />
        <meta property="og:image" content="https://capobrain.com/img/Add-School-About-Detail.png" />
        <meta property="og:url" content="https://capobrain.com/user-manual/about-us" />
        <meta property="og:type" content="website" />

        {/* Twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@BrainCapo" />
        <meta name="twitter:title" content="About Us" />
        <meta name="twitter:description" content="Manage the About Us page, including title, content image, banner, and description. Update and save your changes easily." />
        <meta name="twitter:image" content="https://capobrain.com/img/Add-School-About-Detail.png" />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://capobrain.com/user-manual/about-us",
            "name": "Capobrain",
            "description": "This module allows you to manage the About Us page, including the title, content image, banner, and description. Add and save your desired details to update the page." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://capobrain.com/user-manual/about-us" />
        <meta name="description" content="This module allows you to manage the About Us page, including the title, content image, banner, and description. Add and save your desired details to update the page." />
      </Helmet>

      <h2 className='manual-h1'>About Us</h2>
      <p>This module allows you to manage the information on the About Us page. You can add a title, content image, banner, and description, then save it to update the website's About Us page. You also have the option to view, edit, or delete this information as needed.</p>
      <img src="/img/Add-School-About-Detail.avif" className='img-fluid' alt="About Us Detail" />
    </div>
  );
}
